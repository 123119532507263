<template>
    <b-container>
        <iframe v-bind:src="pdfUrl" width="1130" height="700"></iframe>
    </b-container>
</template>
<script>
export default {
    props :['id'],
    computed:{
        pdfUrl : function(){
            console.log('id', this.id)
            if(this.id != null){
                let pdfs = this.$store.state.pdfMenu;
                let pdf = {};
                for(let i in pdfs){
                    for(let n in pdfs[i]){
                        if(pdfs[i][n].id == this.id){
                            pdf = pdfs[i][n];
                        }
                    }
                }                
                //return 'http://localhost:8000/' + pdf.path + '/' + pdf.naziv;
                return 'https://api.soca.rs/' + pdf.path + '/' + pdf.naziv;
            }
            return '';
        }
    }
}
</script>