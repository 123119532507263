<template>
    <b-container>
        <parts-list :items="delovi" :type="'basketAll'"></parts-list>
    </b-container>
</template>
<script>
import PartsList from '../delovi/PartsList';
export default {
    components:{PartsList},
    name : 'basket-all',
    data() {
      return {
        delovi:[],
        loading:false,
      }
    },
    methods:{
      getBasket:function(){
          this.loading = true;
          this.$http.get('/korpa/sve').then((response) => {                
              if(response.data.success == true){
                this.loading = false;
                this.delovi = response.data.data;
              }
          }).catch((error) => {
            this.loading = false;
            console.log(error)
          })
        },
    },
    created(){
      this.getBasket();
    }
}
</script>