<template>
    <div>
        <b-row>
            <b-col cols="4" class="mb-2" v-if="admin">
                <search-select :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem"></search-select>
                <!-- <b-form-input v-model.lazy="filter.porucio" placeholder="porucio"></b-form-input> -->
            </b-col>            
            <b-col cols="2" class="mb-2">
                <b-form-input v-model.lazy="filter.sifradela" placeholder="sifra dela"></b-form-input>                      
            </b-col>
            <b-col cols="3" class="mb-2">
                <b-form-input v-model.lazy="filter.napomena" placeholder="napomena"></b-form-input>                      
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-input v-model.lazy="filter.rbnaloga" placeholder="broj naloga"></b-form-input>                      
            </b-col>    
            <b-col cols="2" class="mb-2">
                <b-form-datepicker locale="de" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }" placeholder="datum od" id="example-datepicker1" v-model.lazy="filter.datumOd" class="mb-2"></b-form-datepicker>            
            </b-col>
            <b-col cols="2" class="mb-2">            
                <b-form-datepicker locale="de" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }" placeholder="datum do" id="example-datepicker2" v-model.lazy="filter.datumDo" class="mb-2"></b-form-datepicker>                                 
            </b-col>
            <b-col cols="1" class="mb-2">       
                <i class="fas fa-search blue s-icons" v-on:click="search"></i>     
                <i class="fas fa-trash-alt red s-icons"  v-on:click="deleteFilter"></i>
            </b-col>            
        </b-row>        
    </div>
</template>
<script>
import SearchSelect from '../razno/SearchSelect.vue';
export default {
    props:['admin'],
    name:'orders-filter',
    components:{SearchSelect},
    data(){
        return{
            filter :{
                porucio:'',
                sifradela:'',
                rbnaloga:'',
                datumOd:'',
                datumDo:'',
                napomena:''
            },
            reset:false,
            services : []
        }
    },
    methods:{
        deleteFilter:function(){
            this.filter.sifradela = '';
            this.filter.porucio = '';
            this.filter.rbnaloga = '';
            this.filter.datumOd = '';
            this.filter.datumDo = '';
            this.filter.napomena = '';
            this.reset = true;
            setTimeout(()=>{
                this.reset = false;
                this.search();
            },300);
        },
        search:function(){
            this.$emit('search', this.filter);
        },
        getServices:function(){
            this.$http.get('/korisnici/servis').then((response) => {                
                if(response.data.success == true){
                    this.services = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        searchItem:function(item){        
            this.filter.porucio = item.value;
            //this.inputServis = item.text;
        }
    },
    created(){
        this.getServices();
    }
}
</script>
<style>
    .blue{
        color:blue;
    }
    .red{
        color:red;
    }
    .space-right{
        padding: 10px;
    }
    .s-icons{
        font-size:20px;
        padding-top:10px;
        padding-right: 12px;
    }
</style>