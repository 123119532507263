<template>
  <div>
    <b-table hover :items="items" :fields="fields">
      <template #cell(checkbox)="row">
        <div v-if="status == 1">         
          <b-form-checkbox
            :id="row.item.id"            
            name="verifikuj"
            value="1"
            unchecked-value="0"    
            v-model="row.item.checkbox"                    
          >            
          </b-form-checkbox>     
        </div>                 
      </template>
      <template #cell(rb)="row">
        <a href="#" v-on:click="textModal(row)">
          {{row.item.nalog_id}}
        </a>        
      </template>
      <template #cell(actions)="row">
        <div v-if="status == 0">         
            <b-dropdown variant="link" toggle-class="text-decoration-none" class="soca-settings-wheel" no-caret>
                <template #button-content>
                <i class="fas fa-cog settings"></i>
                </template>      
                <b-dropdown-item href="#">
                    <a href="#" v-on:click="textModal(row)">
                    Sadrzaj naloga
                    </a>
                </b-dropdown-item>                                   
                <b-dropdown-item href="#">
                    <a href="#" v-on:click="verifyWO(row)">
                    Verifikuj nalog
                    </a>
                </b-dropdown-item>
                <b-dropdown-item href="#">
                    <a href="#" v-on:click="showAddReworkModal(row)">
                    Potrebna dorada 
                    </a>
                </b-dropdown-item>
                <b-dropdown-item href="#">
                    <a href="#" v-on:click="regulus(row)">
                    Unet u regulus
                    </a>                
                </b-dropdown-item>                      
            </b-dropdown>       
        </div>         
        <div v-else>
            <b-button size="sm" variant="primary" v-on:click="showAddReworkModal(row)">Potrebna dorada</b-button>
        </div>
      </template>
      
    </b-table>      
    
    <add-rework-modal 
        :row="row"
        @saveContent="saveContent" > 
    </add-rework-modal> 

    <working-order-text-modal :row="row"> </working-order-text-modal>
  </div>
</template>

<script>

import Vue from 'vue';
import AddReworkModal from '../modals/AddReworkModal.vue';
import WorkingOrderTextModal from '../modals/WorkingOrderTextModal.vue';

  export default {
    props:['status', 'items'],
    components: { AddReworkModal , WorkingOrderTextModal},
    name : 'working-order-statuse-table',
    emits:['refreshList'],
    data() {
      return {     
            checkedFields:[],
            row : {},         
            obracunaj :[],
            fields: [
            {
                key: 'checkbox',
                label: ''
            },
            {
                key: 'rb',
                label: ''
            },
            {
                key: 'rbnaloga',
                label: 'Br. naloga',
                sortable: true
            },
            {
                key: 'imeiprez',
                label: 'Ime i prezime',
                sortable: true
            },
            {
                key: 'adresa',                
                label: 'Adresa',
                sortable: true
            },
            {
                key: 'aparat',
                label: 'Aparat',
                sortable: true
            },
            {
                key: 'model',
                label: 'Model',
                sortable: false
            },
            {
                key: 'brend',
                label: 'Brend',
                sortable: true
            },
            {
                key: 'servis',
                label: 'Servis',
                sortable: true
            },           
            {
                key: 'actions',
                label :''
            }
            ]
      }
    },    
    
    methods:{      
      showAddReworkModal:function(row){
        this.row = row;
        this.$bvModal.show('reworkModal')
      },
      textModal:function(row){
        this.row = row;
        this.$bvModal.show('woTextModal')
      },
      verifyWO:function(row){    
        console.log('row', row)
        this.$http.post('/radninalog/verifikuj-nalog', { id: row.item.nalog_id }).then((response) => {                
            if(response.data.success == true){          
                    this.$emit('refreshList');                              
                    this.$emit('showMessage', {message:'Nalog je verifikovan', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message: 'Nalog nije verifikovan', color:'warning'})
            });
        //this.changeStatus(row, 9, 'Nalog je uspesno verifikovan');    
        //this.$emit('showMessage', {message : 'Nalog je uspesno verifikovan',  color:'success'} )
      },
      regulus:function(row){
        let items = this.items;
        Vue.set(items[row.index], '_rowVariant', 'success' );        
        this.items = items;
        console.log('items', this.items)
        this.$http.patch('/radninalog/regulus', { id:row.item.nalog_id }).then((response) => {                
          if(response.data.success == true){          
            console.log(response.data)
            //this.$emit('refreshList'); 
            this.$emit('showMessage', {message : 'Nalog je uspesno unet u regulus',  color:'success'} )
          }
          }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message:'Nalog nije  unet u regulus', color:'warning'})
        }); 
        
      },
      saveContent:function(data){
          console.log('save', data)
          this.$http.patch('/radninalog/rework', { id: this.row.item.nalog_id, message : data.data }).then((response) => {                
            
          if(response.data.success == true){                      
            console.log('emit refresh list', response.data)
            this.$emit('refreshList'); 
            this.$emit('showMessage', {message:'Izmena snimljena', color:'warning'})
          }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message:'Izmena nije snimljena', color:'warning'})
        }); 
      },
      changeStatus(row, status, message){
            this.$http.post('/radninalog', { id: row.item.nalog_id, params:{status:status} }).then((response) => {                
                if(response.data.success == true){          
                    //this.$emit('refreshList');                              
                    this.$emit('showMessage', {message:message, color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message: 'status nije promenjen', color:'warning'})
            });
        },
    },
    computed:{
      admin:function(){
        if(this.$store.getters.getUser.type == 'admin'){
          return true
        }
        
        return false;
      }
    }
  }
</script>
<style scoped>
    .settings{
      font-size: 25px;
    }
    .link{
      color: blue;
    }
    .soca-settings-wheel .btn{
      padding: 0px !important;
    }
</style>