<template>
  <div>
    <b-table striped hover :items="items" :fields="fields" style="font-size:13px;">
       <template #cell(actions)="row">
        <div v-on:click="stornoPorudzbineDela(row)" v-if="admin">
          <b-button size="sm" variant="primary">Storno</b-button>     
        </div>
        <div v-on:click="cenaDela(row)" v-if="admin">
          <b-button size="sm" variant="primary">Promeni cenu</b-button>     
        </div>
        <div v-on:click="changeStatus(row)" v-if="statusChange">
          <b-button size="sm" variant="primary">isporuceno</b-button>     
        </div>
      </template>
    </b-table>
    <b-modal id="storno-dela" title="Storno">
      <p class="my-4">Da li sigurno zelite da stornirate porudzbinu ovo dela!</p>
      <template #modal-footer="{}">      
        <b-button size="sm"  @click="cancel">
          Otkazi
        </b-button>
        <b-button size="sm" variant="primary" @click="stornoConfirmed">
          Storno
        </b-button>
      </template>
    </b-modal>
    <b-modal id="cena-dela" title="Promeni cenu dela">    
        promeni cenu   
      <template #modal-footer="{}">      
        <b-button size="sm"  @click="cancelPart">
          Otkazi
        </b-button>
        <b-button size="sm" variant="primary" @click="stornoConfirmed">
          Storno
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
    
  export default {
    props : ['items', 'admin', 'statusChange'],
    name : 'order-part-list-table',
    data() {
      return {
        row : {} ,
        fields: [
            {
                key: 'sifradela',
                label: 'Sifra dela',
                sortable: false
            },
            {
                key: 'naziv',
                label: 'Naziv',
                sortable: false
            },
            {
                key: 'kolicina',
                label: 'Kolicina',
                sortable: false
            },
            {
                key: 'cena',
                label: 'Cena',
                sortable: false
            },
            {
                key: 'napomena',
                label: 'Napomena',
                sortable: false
            },
            {
                key: 'status',
                label: 'Status',
                sortable: false
            },
            {
                key: 'kreirao',
                label: 'Kreirao',
                sortable: false
            },
            {
                key: 'kreiran',
                label: 'Kreiran',
                sortable: false
            },
            {
                key: 'statusmenjan',
                label: 'Statusmenjan',
                sortable: false
            },
            {
                key: 'dokument',
                label: 'Dokument',
                sortable: false
            },
            { 	key: 'actions',
                label: '' 
            }
        ]       
      }
    },
    computed:{
      
      
    },
    methods:{
      details:function(row){
        console.log(row)
      },
      cancel:function(){
        this.$bvModal.hide('storno-dela');
      },
      stornoPorudzbineDela:function(row){        
        this.row = row;
        this.$bvModal.show('storno-dela');
      },
      stornoConfirmed:function(){
        console.log(this.row)
        this.$emit('stornoPorudzbine', this.row);
        this.$bvModal.hide('storno-dela');
      },
      cancelPart:function(){
        this.$bvModal.hide('cena-dela');
      },
      partPrice:function(row){        
        this.row = row;
        this.$bvModal.show('cena-dela');
      },
      priceConfirmed:function(){
        console.log(this.row)
        this.$emit('stornoPorudzbine', this.row);
        this.$bvModal.hide('cena-dela');
      },
      changeStatus:function(row){
        console.log(row.item.id)
        this.$http.post('/porudzbine/promena-statusa',{ data: row.item.id}).then((response) => {                
            if(response.data.success == true){                                           
                this.showMessage({message: 'Promenjen status', color:'success'});
            }
          }).catch((error) => {
              console.log(error);
              this.showMessage({message: 'Nije promenjen status', color:'success'});
          });
        this.$emit('refreshStavke', row);
      }
    }
  }
</script>
<style scoped>
  .blue{
    color:blue
  }
</style>