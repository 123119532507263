<template>
  <div class="dropdown">
    <b-input
      v-model.trim="inputValue"
      class="dropdown-input"
      type="text"
      :placeholder="placeholder"
      v-on:keyup="filter"
    />
    <div v-if="inputValue != '' && !itemFound" class="dropdown-list">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="dropdown-item"
        v-on:click="chosen(item)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["itemList", "placeholder", "reset"],
  name: "search-select",
  data() {
    return {
      inputValue: "",
      itemFound: false,
      items: [],
    };
  },
  watch: {
    reset: function (newVal) {
      if (newVal === true) {
        this.inputValue = "";
      }
    },
  },
  methods: {
    filter: function () {
      this.itemFound = false;
      this.$emit("searchItem", "");
      this.items = this.itemList.filter((item) => {
        return item.text.toLowerCase().search(this.inputValue.toLowerCase()) > -1;
      });
    },
    chosen: function (item) {
      this.inputValue = item.text;
      this.itemFound = true;
      this.$emit("searchItem", item);
    },
  },
};
</script>
<style scoped>
.dropdown {
  position: relative;
}
.dropdown-list {
  position: absolute;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  z-index: 1000;
}
</style>