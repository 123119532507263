<template>
    <b-container>  
        <pulse-loader :loading="loading"></pulse-loader>      
        <working-order-data v-if="!loading" @showMessage="showMessage" @setImageId="setImageId"
            :id.sync="id"
            :vrsta_naloga="vrsta_naloga"
            :group="group"
            :grupa="grupa"
            :grupabrend="grupabrend"
            :rbnaloga.sync="rbnaloga"
            :imeiprez.sync="imeiprez"
            :adresa.sync="adresa"
            :mesto.sync="mesto"
            :ptt.sync="ptt"      
            :telefon.sync="telefon"
            :email.sync="email"
            :work.sync="work"
            :status.sync="status"
            :ftdate.sync="ftdate"
            :ptdate.sync="ptdate"
            :itdate.sync="itdate"
            :pnc.sync="pnc"
            :vrsta.sync="vrsta"
            :model.sync="model"
            :serijski.sync="serijski"
            :garantni.sync="garantni"
            :prodavac.sync="prodavac"
            :brgar.sync="brgar"
            :opis.sync="opis"
            :misljenje.sync="misljenje"
            :napomena.sync="napomena"
            :napservisu.sync="napservisu"
            :lokacija_aparata.sync="lokacija_aparata"
            :predjeno.sync="predjeno"
            :prihvata.sync="prihvata"
            :brizlazaka.sync="brizlazaka"
            :nesaobraznost.sync="nesaobraznost"
            :nesaobraznostKakva.sync="nesaobraznostKakva"
            :zamena.sync="zamena"
            :delovi.sync="delovi"
            :fajlovi.sync="fajlovi"
            :izvrsioc_prvi.sync="izvrsioc_prvi"
            :izvrsioc_drugi.sync="izvrsioc_drugi"
            :vrsta_saobraznosti.sync="vrsta_saobraznosti"
            :nosilac.sync="nosilac"
            :vreme.sync="vreme"
            :predprodaja.sync="predprodaja"
            :aparat.sync="aparat"
            :brend.sync="brend"            
            :servis.sync="servis"
        >
        </working-order-data>
        <b-row style="margin-bottom:20px;">
            <b-col >
                <b-button block variant="primary" v-on:click="saveWorkingOrder()">Snimi</b-button> 
            </b-col>
        </b-row>            
    </b-container>        
</template>
<script>
import WorkingOrderData from './WorkingOrderForm.vue';
import WorkingOrderMixins from '../../mixins/WorkingOrderMixins';
import WorkingOrderModule from '../../store/modules/workingorderdata';
import { mapFields } from 'vuex-map-fields';

export default {
    props:['group', 'type','idn'],
    name : 'radni-nalog',
    components : {        
        WorkingOrderData
    },
    mixins:[WorkingOrderMixins],
    data() {    
        return {
            imagesId:'',
            loading: false
        }
    },
    computed:{
        ...mapFields(['radniNalozi']),
        idNaloga:{
            get(){
                return this.idn;
            },
            set(){                
                console.log('set')
            }            
        }
    },
    methods:{
        setImageId:function(imageId){
            this.imagesId = imageId
        },
        saveWorkingOrder:function(){     
            this.idNaloga = '';
            console.log(this.$store.state.radniNalog, this.imagesId)
            //this.$store.state.radniNalozi.push(this.$store.state.radniNalog) 
            
            this.$http.post('/radninalog', this.$store.state.radniNalog).then((response) => {                
                if(response.data.success == true){
                    this.$emit('showMessage', {message : 'Nalog je uspesno snimljen',  color:'success'}) 
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message : 'Nalog nije snimljen',  color:'warning'}) 
            });      
            console.log('query', this.$route.query)                      
            if(this.$route.query.ret != null && this.$route.query.ret != ''){
                if(this.$route.query.ret == 'admsvi'){
                    this.$router.push('/radninalog/svi?load=1');    
                }
                if(this.$route.query.ret == 'lista'){
                    this.$router.push('/radninalog/lista');
                }
            }
            if(this.$route.query.type != null && this.$route.query.type == 0){
                this.$router.push('/radninalog/lista');

            }
           
        },
        showMessage:function(message){
			this.$emit('showMessage', message)
		},      
    },
    watch:{
        group:function(){            
            console.log('watch group')
            //this.$store.unregisterModule('radniNalog', WorkingOrderModule);
            //this.$store.registerModule('radniNalog', WorkingOrderModule);
            this.grupa = this.group;
            this.idNaloga = this.idn
        },
        idn:function(){
            console.log('watch idn')
            //this.$store.unregisterModule('radniNalog', WorkingOrderModule);
            //this.$store.registerModule('radniNalog', WorkingOrderModule);
            this.idNaloga = this.idn
            this.grupa = this.group;
        }
    },
    created(){    
        console.log('crated rn', this.$store.state.radniNalog)
        if(this.$store.state.radniNalog != null){
            //this.$store.registerModule('radniNalog', WorkingOrderModule);            
        }              
        
    },
    mounted(){      

        this.grupa = this.group;
        console.log('mounted nalog', this.grupa, this.idn)
        if(this.idNaloga != null && this.idNaloga != ''){
            console.log(this.idNaloga)
            this.loading = true;
            this.$http.get('/radninalog/nalog?id='+this.idNaloga).then((response) => { 
                this.loading = false;               
				if(response.data.success == true){	                    				
                    let nalog = response.data.data;
                    console.log(nalog)
                    let id = nalog.id;
                    if(id == ''){
                        id = this.idNaloga;
                    }
                    this.id = id;
					this.rbnaloga = nalog.rbnaloga;
                    this.imeiprez = nalog.imeiprez;
                    this.adresa = nalog.adresa;
                    this.ptt  = nalog.ptt;
                    this.mesto = nalog.mesto;
                    this.telefon = nalog.telefon;
                    this.email = nalog.email;
                    this.work = nalog.work;
                    this.status = nalog.status;
                    this.ftdate = nalog.ftdate;
                    this.ptdate = nalog.ptdate;
                    this.itdate = nalog.itdate;
                    this.pnc = nalog.pnc;
                    this.model = nalog.model;
                    this.vrsta = nalog.vrsta;
                    this.serijski = nalog.serijski;
                    this.prodavac = nalog.prodavac;
                    this.brgar = nalog.brgar;
                    this.opis = nalog.opis;
                    this.misljenje = nalog.misljenje;
                    this.lokacija_aparata = nalog.lokacija_aparata;
                    this.predjeno = nalog.predjeno;
                    this.prihvata = nalog.prihvata;
                    this.brizlazaka = nalog.brizlazaka;
                    this.nesaobraznost = nalog.nesaobraznost;
                    this.nesaobraznostKakva = nalog.nesaobraznostKakva;
                    this.zamena = nalog.zamena;
                    this.delovi = nalog.delovi;
                    this.fajlovi = nalog.fajlovi;
                    this.napomena = nalog.napomena;
                    this.napservisu = nalog.napservisu;
                    this.izvrsioc_prvi= nalog.izvrsioc_prvi;
                    this.izvrsioc_drugi= nalog.izvrsioc_drugi;
                    this.predprodaja = nalog.predprodaja;
                    this.vreme = nalog.vreme;
                    this.vrsta_naloga= nalog.vrsta_naloga;
                    this.vrsta_saobraznosti= nalog.vrsta_saobraznosti;
                    this.nosilac= nalog.nosilac;
                    this.aparat= nalog.aparat;
                    this.brend= nalog.brend;
                    this.grupabrend= nalog.grupabrend;
                    this.work= nalog.work;
                    this.grupa= nalog.grupa;
                    this.servis= nalog.servis;
                    this.sameEditor = (this.$route.query.ret != null && this.$route.query.ret != '') ? false : true; // ako admin edituje za nekog                                        
                    
				}
                
			}).catch((error) => {
				console.log(error)
                this.loading = false;               
			})

            for(let nalog of this.radniNalozi){
                if(this.idNaloga == nalog.rbnaloga){
                    this.rbnaloga = nalog.rbnaloga;
                    this.imeiprez = nalog.imeiprez;
                    this.adresa = nalog.adresa;
                    this.ptt  = nalog.ptt;
                    this.mesto = nalog.mesto;
                    this.telefon = nalog.telefon;
                    this.email = nalog.email;
                    this.work = nalog.work;
                    this.status = nalog.status;
                    this.ftdate = nalog.ftdate;
                    this.ptdate = nalog.ptdate;
                    this.itdate = nalog.itdate;
                    this.pnc = nalog.pnc;
                    this.vrsta = nalog.vrsta;
                    this.model = nalog.model;
                    this.serijski = nalog.serijski;
                    this.prodavac = nalog.prodavac;
                    this.brgar = nalog.brgar;
                    this.opis = nalog.opis;
                    this.misljenje = nalog.misljenje;
                    this.lokacija_aparata = nalog.lokacija_aparata;
                    this.predjeno = nalog.predjeno;
                    this.prihvata = nalog.prihvata;
                    this.brizlazaka = nalog.brizlazaka;
                    this.nesaobraznost = nalog.nesaobraznost;
                    this.nesaobraznostKakva = nalog.nesaobraznostKakva;
                    this.zamena = nalog.zamena;
                    this.delovi = nalog.delovi;
                    this.fajlovi = nalog.fajlovi;
                    this.napomena = nalog.napomena;
                    this.izvrsioc_prvi= nalog.izvrsioc_prvi;
                    this.izvrsioc_drugi= nalog.izvrsioc_drugi;
                    this.predprodaja = nalog.predprodaja;
                    this.vreme = nalog.vreme;
                    this.vrsta_naloga= nalog.vrsta_naloga;
                    this.vrsta_saobraznosti= nalog.vrsta_saobraznosti;
                    this.nosilac= nalog.nosilac;
                    this.aparat= nalog.aparat;
                    this.brend= nalog.brend;
                    this.grupabrend = nalog.grupabrend;
                    this.work= nalog.work;
                    this.grupa= nalog.grupa;
                    this.servis= nalog.servis;
                    
                } 
            }
        }    
        else{
            console.log('d', WorkingOrderModule.state);
            this.$store.commit('updateField', {
                        path: 'radniNalog',
                        value: {
                            id:'',
                            options:'',
                            rbnaloga:'',
                            imeiprez :'',
                            adresa : '',
                            ptt: '',
                            mesto :'',
                            telefon : '',
                            email : '',
                            work : '',
                            status :'',
                            ftdate : '',
                            ptdate : '',
                            itdate : '',
                            pnc :'',
                            vrsta: '',
                            model : '',
                            serijski :'',
                            prodavac : '',
                            brgar : '',
                            opis : '',
                            misljenje :'',
                            lokacija_aparata : '',
                            predjeno : '',
                            prihvata : '',
                            brizlazaka :'',
                            nesaobraznost :'',
                            nesaobraznostKakva :'',
                            zamena :'',
                            delovi : [],
                            fajlovi : [],
                            napomena : '',
                            napservisu : '',
                            izvrsioc_prvi:'',
                            izvrsioc_drugi:'',
                            predprodaja : '',
                            vreme: '08:30:00',
                            vrsta_naloga:'',
                            vrsta_saobraznosti:'',
                            nosilac:'',
                            aparat:'',
                            brend:'',
                            grupa:parseInt(this.grupa),
                            grupabrend:parseInt(this.grupa),                                                       
                            servis:'',
                            sameEditor:true
                        }
                    });
        }    
    },
    beforeDestroy() {
        //this.$store.unregisterModule('radniNalog', WorkingOrderModule);
    }
}
</script>