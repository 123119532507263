<template>
  <div>
    <b-table striped hover :items="items" :fields="fields">       
    </b-table>
  </div>
</template>

<script>
  export default {
    props : ['items'],
    name : 'unsent-order-parts-table',
    data() {
      return {
        fields:[
            {
                key: 'sifradela',
                label: 'sifradela',
                sortable: true
            },
            {
                key: 'naziv',
                label: 'Naziv dokumenta',
                sortable: true
            },
            {
                key: 'kolicina',
                label: 'kolicina',
                sortable: true
            },
            {
                key: 'datum',
                label: 'porucen',
                sortable: true
            },
            {
                key: 'porucio',
                label: 'Porucio',
                sortable: true
            },           
            {
                key: 'napomena',
                label: 'Napomena',
                sortable: true
            }, 
            
        ],
        
      }
    },
    methods:{
      details:function(row){
        console.log(row)
      }
    }
  }
</script>
<style scoped>
  .blue{
    color:blue
  }
</style>