<template>
    <b-container>
        <h2>Porucivanje delova</h2>
        <search-select v-if="alid == '44c2d9d87a62acfee636b95df766ce26' && type != 'user'" :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem" class="mb-2"></search-select>
        <add-part-basket :nalozi="nalozi" :id="id" @partAdded="partAdded" @showMessage="showMessage"></add-part-basket>
        <parts-list :items="delovi" :type="'basket'" @deletePart="deletePart"></parts-list>
		<div v-if="delovi.length > 0">
      <h4 class="center" v-if="alid == '44c2d9d87a62acfee636b95df766ce26' && type != 'user'">PORUDZBINE PRAVITE I ZAKLJUCUJTE PO SERVISU</h4>
			<b-col class="mb-2">
				<b-button block variant="primary" v-on:click="submitOrder()">Posalji porudzbinu</b-button>        
			</b-col>
		</div>
        <p>Sadržaj porudžbe je odgovornost poručioca, kao i troškovi neodgovarajućih porudžbi. Poručilac preuzima troškove sanitarnog i laboratorijskog pregleda za poručene artikle koji su „u direktnom dodiru sa hranom“</p>
    </b-container>
</template>

<script>

import AddPartBasket from './AddPartBasket.vue'
import PartsList from './PartsList.vue'
import Helpers from '../utils/helpers';
import SearchSelect from '../razno/SearchSelect.vue';
import { mapFields } from 'vuex-map-fields';

export default {
  components: { AddPartBasket, PartsList, SearchSelect },
    name : 'basket',
    props:['type', 'id'],
    data() {
      return {
        delovi:[],
        naloziArray :[],
        reset:false,
        servis:'',
        services:[]
      }
    },
    computed:{
      ...mapFields(['alid']),
      nalozi:function(){
        let result = [];
        for(let i in this.naloziArray){
          result.push({
            value : this.naloziArray[i].id,
            text : this.naloziArray[i].id
          })
        }
        return result;
      }
    },    
    watch:{
      servis:function(newVal){
        if(newVal != '' && newVal != null){
          this.getWo(newVal);
        }
      }
    },
    methods:{
        partAdded:function(part){        
          
          this.$http.post('/korpa',{ data: part, for:this.servis }).then((response) => {                
            if(response.data.success == true){
              //this.delovi.push({...part});
              this.getBasket();
              //this.reset = true;
              setTimeout(()=>{
                  this.reset = false;
                  //this.searchOrders();
              },300);
              
              this.$emit('showMessage', {message:'Artikal dodat u korpu', color:'success'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Artikal nije dodat u korpu', color:'warning'})
          });     
            
        },
        deletePart:function(index){    
          this.$http.delete('/korpa',{ data: { id: this.delovi[index].id } }).then((response) => {                
            if(response.data.success == true){
              this.delovi.splice(index,1);
              this.$emit('showMessage', {message:'Artikal je obrisan iz korpe', color:'success'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Artikal nije obrisan iz korpe', color:'warning'})
          });        
           
        },
        showMessage:function(message){
          this.$emit('showMessage', message)
        },
        submitOrder:function(){
          this.$http.post('/porudzbine/korpa', {for:this.servis}).then((response) => {                
            if(response.data.success == true){              
              this.$emit('showMessage', {message:'Vasa porudzbina je poslata', color:'success'}) 
              this.getBasket();             
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Vasa porudzbina nije poslata', color:'success'})
          });          
        },
        getServices:function(){
          this.$http.get('/korisnici/servis').then((response) => {                
              if(response.data.success == true){
                  this.services = response.data.data;
              }
          }).catch((error) => {
              console.log(error)
          })
        },
        searchItem:function(item){        
          this.servis = item.value;
          console.log(item)
          //this.inputServis = item.text;
        },
        getBasket:function(){
          this.$http.get('/korpa').then((response) => {                
            if(response.data.success == true){
                this.delovi = response.data.data;
            }
          }).catch((error) => {
              console.log(error)
          })
        },
        getWo:function(servis = null){
          let params = {
            status : [1]
          }
          if(servis != null){
            params['servis'] = servis;
          }
          this.$http.get('/radninalog?' + Helpers.objectToParams(params)).then((response) => {                
            if(response.data.success == true){
                this.naloziArray = response.data.data;
                console.log(this.nalozi)
            }
            }).catch((error) => {
                console.log(error)
            });
          this.getServices(); 
        }
    },
    mounted(){
      this.getBasket();
      this.getWo();

       
    }
  }
</script>	