<template>
    <div>
        <b-row>
            <b-col cols="4" class="mb-2">
                <b-form-input v-model="inputValue" placeholder="unos šifra SOĆA, šifra dobavljača ili naziv" v-on:input="search"></b-form-input>     
                <div v-if="inputValue != '' && !itemFound" class="dropdown-list">
                    <div v-for="(item, index) in items" :key="index" class="dropdown-item" v-on:click="chosen(item)">        
                        {{ item.text }}
                    </div>
                </div>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-spinbutton id="demo-sb" v-model="deo.kolicina" min="1" max="20"></b-form-spinbutton>                       
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-input v-model="deo.cena" placeholder="cena"></b-form-input>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-button variant="primary" v-on:click="addToList()">{{buttonText}}</b-button>
            </b-col>
        </b-row> 
    </div>
</template>

<script>
  export default {
    name : 'add-part-returns',
    props:['type'],
    data() {
      return {
          deo:{
            sifra:'',
            naziv:'',
            kol :1,
            cena : '0',
            id_poslato : ''
          },
          inputValue: '',
          itemFound : false,
          items :[],
          errors :[]
      }
    },
    computed:{
        buttonText:function(){
            let text = 'Stavi u nalog';
            if(this.type == 'return'){
                text = 'Stavi u povratnicu';
            }
            return text;
        }
    },
    methods:{
        addToList:function(){   
            if(this.canAdd() === true){                 
                this.$emit('partAdded', this.deo);
                setTimeout(() => {
                    this.deleteValues();
                }, 300);
            }
            else{
                this.$emit('showMessage', {message:'Morate uneti sifru dela', color:'warning'})
            }            
        },
        deleteValues:function(){
            this.deo.sifra = '';
            this.deo.kol = 1;
            this.deo.cena = ''; 
            this.deo.id_poslato = ''           
        },
        canAdd:function(){            
            if(this.deo.sifradela != '' && this.deo.kolicina > 0){
                return true;
            }
            else{
                this.errors = [];
                if(this.deo.sifra == ''){
                    this.errors.push('Morate uneti sifru dela')
                }
                if(this.deo.kol == ''){
                    this.errors.push('Morate uneti kolicinu')
                }
                return false;
            }
        },
        search:function(){  
            this.itemFound = false;      
            this.$http.get('/povratnice/automagacin?term=' + this.inputValue).then((response) => {                
                if(response.data.success == true){
                    console.log(response.data.data)
                    this.items = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        chosen:function(item){
            this.deo.sifra = item.value;
            this.deo.naziv = item.text;
            this.deo.id = item.id;
            this.deo.kol = 1;
            this.inputValue = item.text;
            this.itemFound = true;
            this.getLastDelivered(item.value);
            console.log('deo', this.deo)
        },
        getLastDelivered:function(sifra){
            this.$http.get('/povratnice/nadjizadnji?term=' + sifra).then((response) => {                
                if(response.data.success == true){
                    let data = response.data.data;
                    this.deo.id_poslato = data.idposlato;
                    this.deo.cena = data.cena;

                }
            }).catch((error) => {
                console.log(error)
            })
        }  

    }
  }
</script>
<style scoped>
    .dropdown{
        position: relative;
        }
    .dropdown-list{
        position: absolute;
        background-color: white;
        border: 1px solid #e8e8e8;
        border-radius: 3px;
        z-index: 1000;
    }
</style>