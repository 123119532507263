<template>
    <div class="orders-table">
        <b-container>
            <b-table striped hover :items="users" :fields="fields">
                <template #cell(actions)="row">
                    <b-button size="sm" variant="primary" @click="openModal(row)">
                        Izmeni
                    </b-button>
                </template>
            </b-table>
        </b-container>    
        <user-modal :user="user" :loadingUser="loading" @saveChanges="saveChanges"></user-modal>
    </div>
</template>
<script>
import UserModal from '../modals/userModal.vue'
    
    export default {
        components:{
            UserModal
        },
        data(){
            return{
                loadingUser:false,
                loading:false,
                row : {},
                user: {},
                users:[],
                fields: [
                {
                    key: 'idpartner',
                    label: 'id',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'naziv',
                    sortable: true
                },
                {
                    key: 'adresa',                
                    label: 'Adresa',
                    sortable: true
                },
                {
                    key: 'mesto',
                    label: 'Mesto',
                    sortable: true
                },
                {
                    key: 'ptt',
                    label: 'Ptt',
                    sortable: false
                },                
                {
                    key: 'actions',
                    label :''
                }
            ]
            }
        },
        methods:{
            getUsers:function(){
                this.loadingUser = true;
                this.$http.get('/korisnici/svi').then((response) => {                
                    if(response.data.success == true){
                        this.users = response.data.data;
                    }
                    this.loadingUser = false;
                }).catch((error) => {
                    console.log(error)
                    this.loadingUser = false;
                })
            },
            getUser:function(id){
                this.loading = true;
                this.$http.get('/korisnici/user?id=' + id).then((response) => {                
                    if(response.data.success == true){
                        this.user = response.data.data;
                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log(error)
                    this.loading = false;
                })
            },
            openModal:function(row){
                console.log(row)
                this.row = row;
                this.getUser(row.item.idpartner);                
                this.$bvModal.show('userModal')
            },
            saveChanges:function(user){
                this.$http.patch('/korisnici', user).then((response) => {                
                if(response.data.success == true){          
                                     
                    this.$emit('showMessage', {message:'Snimljeno', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'nije snimljeno', color:'warning'})
            }); 
                this.getUsers();
                console.log('save', this.users)
            }
        },
        created(){
            this.user = {};
            this.getUsers();
        }
    }
</script>