<template>
    <b-container>
        <b-row class="setter-menu">
            <b-col cols="7" >
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="menu"
                        v-model.lazy="menu"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                    >
                    <b-form-radio value="1">status naloga</b-form-radio>
                    <b-form-radio value="2">vrsta naloga</b-form-radio>                                                                                                                                
                    </b-form-radio-group>
                </b-form-group>
            </b-col> 
            <b-col >
                <b-button variant="primary" block v-on:click="save()">Snimi podesavanja</b-button>
            </b-col>           
        </b-row>          
        <b-row>
            <b-col cols="6" class="mb-2 text-center">Meni</b-col>
            <b-col cols="6" class="mb-2 text-center">PDF-ovi</b-col>
            <b-col cols="6" class="mb-2">
                 <draggable
                    :list="newList"            
                    @start="dragging = true"
                    @end="dragging = false"
                    >
                    <div
                    class="list-group-item"
                    v-for="element in newList"
                    :key="element"
                    >
                    <span v-on:click="removeEntry(element)">
                        <i class="fas fa-minus"></i>
                    </span> 
                    {{ pdfs[element].name }}
                    </div>
                </draggable>
            </b-col>
            <b-col cols="6" class="mb-2">
                <ul class="lista">
                    <li v-for="(pdf, index) in filteredPdfs" :key="index">
                        <div
                    class="list-group-item">
                        <span v-on:click="addEntry(pdf)">
                            <i class="fas fa-plus"></i> {{ pdf.name }}
                        </span>   
                        </div>                     
                    </li>
                </ul>
            </b-col>
        </b-row>       
    </b-container>
</template>
<script>
import draggable from 'vuedraggable'
import '../../assets/styles/app.css'
export default {
    name:'config-working-order',
    components:{draggable},
    data(){
        return{
            menuPdfs:[],
            pdfs : {},
            menu : 1,
            newList : []
        }
    },
    computed:{
        filteredPdfs:function(){            
            let filtered = [];
            for(let i in this.pdfs){
                if(this.pdfs[i].group == this.menu && !this.newList.includes(this.pdfs[i].id)){
                    filtered.push(this.pdfs[i]);
                }
            }            
            return filtered;
        }
    },
    watch:{
        menu:function(){
            this.newList = [...this.$store.getters.getMenu(this.menu)];       
        }
    },
    methods:{
        save:function(){
            let value = {menu: this.menu, list: this.newList}
            this.$store.dispatch('saveConfigMenu', JSON.parse(JSON.stringify(value)))
        },
        addEntry:function(entry){
            this.newList.push(entry.id)            
        },
        removeEntry:function(entry){
            for(let i in this.newList){
                if(this.newList[i] == entry){
                    this.newList.splice(i, 1)
                }
            }
            
        }        
    },
    created(){ 
        this.pdfs = this.$store.getters.getPdfs; 
        this.newList = [...this.$store.getters.getMenu(this.menu)];              
    }
}
</script>
<style scope>
    .lista{
        list-style-type: none;
        padding: 0;
        margin: 0;
    }    
</style>