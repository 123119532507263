export default {
    loader:{
        color: '#a40202',
        size: '20px'
    },
    parametri:[
        { value : '' , text: 'Odaberite parametar' },
        { value : 'imeiprez' , text: 'ime ili prezime' },
        { value : 'adresa' , text: 'adresa' },
        { value : 'telefon' , text: 'telefon' },
        { value : 'pnc' , text: 'PNC/REF/Service Code' },
        { value : 'model' , text: 'model' },
        { value : 'serijski' , text: 'serijski' },
        { value : 'id' , text: 'nalog broj' },
        { value : 'rbnaloga' , text: 'oznaka naloga servisa' },
        { value : 'status_prodavac' , text: 'status prodavac' },
        { value : 'zamenjen' , text: 'uredjaj zamenjen (upisati on)' },
        { value : 'ftdate' , text: 'Datum kupovine od (YYYY-MM-DD)' },
        { value : 'itdate' , text: 'Datum izvrsenja od (YYYY-MM-DD)' }
    ],
    kategorijeRada:[
        { value : '' , text: 'Odaberite kategoriju rada' },
        { value : 1 , text: '1' },
        { value : 2 , text: '2' },
    ],
    brendoviMap:[
        {value : 1 , text: 'electrolux'},
        {value : 2 , text: 'seb'},
        {value : 3 , text: 'franke'},        
        {value : 4 , text: 'opsti'},
        {value : 5 , text: 'soca'},
        {value : '' , text: 'primus'},
        {value : 100 , text: 'prijava'},
        {value : 101 , text: 'mka'},        
    ],
    nivoi : {
        1 : '1b63a771bd7f09244c493450df0c8a72',
        2 : 'a2e1c28d704ac7f1c665b5092cb45ff5',
        3 : '44c2d9d87a62acfee636b95df766ce26',
        4 : 'd41ad1f32c42bae24fd4e5b547bae66b',
        5 : '79e9790ac2827ddca0db7109f51686d1',
        6 : 'b403ed2da54eeab68ecf1647ee485832',
        7 : 'bf11c73a524eb1db264adf07ab2b96d5',
        8 : '3d6b2f0cb5beb1847a110faad0c826c0'
    }  
 }