<template>
    <div>
        <div class="login-card">        
            <b-card header="Login" header-tag="header" class="mb-2">  
                <b-row>
                    <b-col cols="12" class="mb-2">
                        <b-form-input type="email" v-model.lazy="user.email" placeholder="email"></b-form-input>                      
                    </b-col>
                    <b-col cols="12" class="mb-2">
                        <b-form-input id="type-password" type="password" v-model.lazy="user.password" placeholder="password"></b-form-input>                      
                    </b-col>
                    <b-button block variant="primary" @click="loginUser()">login</b-button>
                </b-row>
            </b-card>
            {{error}}
        </div>
    </div>
</template>
<script>
export default {
    name : 'login',
    data(){
        return{
            user:{
                email : '',
                password : ''
            },
            error:''
        }
    },
    methods:{
        loginUser:function(){     
            this.$http.post('/login_user', this.user ).then((response) => {                
                this.$store.commit('updateField', {
                        //path: 'user.token',
                        path: 'uid',
                        value: response.data.access_token
                    }); 
                this.$store.commit('updateField', {
                    //path: 'user.token',
                    path: 'alid',
                    value: response.data.lid
                });  
                this.$store.commit('updateField', {
                    //path: 'user.token',
                    path: 'vm',
                    value: response.data.vm
                }); 

                let groups = response.data.groups;
                groups = groups.replace('[', '');
                groups = groups.replace(']', '');
                groups = groups.split(',');
                console.log(groups)
                this.$store.commit('updateField', {
                    //path: 'user.token',
                    path: 'groups',
                    value: groups
                });   
                    
            }).catch((error) => {
                console.log(error);                
                this.error = error
            });       
        }
    }
}
</script>
<style>
    .login-card{
        width: 500px;
        margin: auto;
        margin-top:30px;
    }
</style>