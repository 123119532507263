import http from "./http-common";
//import helpers from '../utils/helpers';
import store from '../../store/store';

class UploadFilesService {
  upload(file, data,  onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("data", data.id);
    formData.append("group", data.group)

    return http.post("files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": 'Bearer ' + store.state.uid
      },
      onUploadProgress
    });
  }

  getFiles(orderId) {
    if(orderId == ''){
      return [];
    }
    return http.get("files?id=" + orderId,{
      headers: {        
        "Authorization": 'Bearer ' + store.state.uid
      }});
  }
}

export default new UploadFilesService();