<template>
    <div>
        <div v-if="group != 5" >
            <b-row class="mb-2 text-center" >
                <b-col> {{ nazivServisa }} </b-col>
            </b-row>
        </div>
        <b-row class="mb-2 text-center" v-if="status > 7">
            <b-col cols="3">
                <button class="btn btn-primary" @click="toggleLock" size="sm">otkljucaj/zakljucaj nalog</button>
            </b-col>
        </b-row>
        <b-alert show variant="warning" v-if="napservisuHandler !== '' && napservisuHandler !== null">
            <h3>Napomena za nalog</h3>
            <p>{{ napservisuHandler }}</p>
        </b-alert>
        <div v-if="$store.state.alid === '44c2d9d87a62acfee636b95df766ce26' && group != 11">
        <b-row class="mb-2" v-if="pickService">
            <b-col cols="6">
                <search-select :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem"></search-select>
                <!-- <b-form-select v-model="servisHandler" :options="servisi"></b-form-select> -->
            </b-col>
        </b-row>
        </div>
        <div v-if="group == 1">
            <table width="100%">
                <tbody>
                <tr>
                    <td align="center">
                    <img src="@/assets/images/elluxpdf.jpg" width="200px;" />
                    </td>
                    <td align="center">
                    <img src="@/assets/images/aegpdf.jpg" width="200px;" />
                    </td>
                    <td align="center">
                    <img src="@/assets/images/zanussipdf.jpg" width="200px;" />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="group == 2">
            <table width="100%">
                <tbody>
                <tr>
                    <td align="center"><img src="@/assets/images/tefal.jpg" width="200px;" /></td>
                    <td align="center"><img src="@/assets/images/krups.jpg" width="200px;" /></td>
                    <td align="center">
                    <img src="@/assets/images/rowenta.jpg" width="200px;" />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
                
        <b-card header="Podaci o stranci" header-tag="header" class="mb-2">  
            <b-row>
                <b-col cols="2" class="mb-2">
                    <span style="font: bold 18px !important;">Nalog br.: {{id}} </span>
                </b-col> 
                <b-col cols="2" class="mb-2">
                    <b-form-input v-model="rbnalogaHandler" :disabled="disabled" placeholder="Redni broj naloga"></b-form-input>
                </b-col> 
                <b-col cols="8" class="mb-2">
                    <b-row v-if="group == 5 || group == 11">
                        <b-col cols="11"><b-form-input v-model.lazy="imeiprezHandler" :disabled="disabled" placeholder="Ime i prezime stranke"></b-form-input></b-col>
                        <b-col class=" s-icons-form">
                            <i class="fas fa-user-plus"                            
                             v-on:click="showAddContactModal"></i>
                        </b-col>
                    </b-row>
                    <b-form-input v-else v-model.lazy="imeiprezHandler" :disabled="disabled" placeholder="Ime i prezime stranke"></b-form-input>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-form-input v-model.lazy="adresaHandler" :disabled="disabled"  placeholder="Adresa stranke"></b-form-input>                         
                </b-col>
                <b-col cols="2" class="mb-2">
                    <b-form-input v-model.lazy="pttHandler" :disabled="disabled" placeholder="Ptt stranke"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="mestoHandler" :disabled="disabled" placeholder="Mesto stranke"></b-form-input>                      
                </b-col>
                <b-col cols="5" class="mb-2">
                    <b-form-input v-model.lazy="telefonHandler" :disabled="disabled" placeholder="Telefon stranke, unosite samo broj bez / - i praznog mesta"></b-form-input>                      
                </b-col>
                <b-col cols="7" class="mb-2">
                    <b-form-input v-model.lazy="emailHandler" :disabled="disabled" placeholder="email stranke"></b-form-input>                      
                </b-col>
            </b-row>    
        </b-card>
        <add-contact-modal></add-contact-modal>
        <b-card header="Rasporediti" header-tag="header" class="mb-2" v-if="group == 5 || group == 11">
            <b-row>
                <b-col cols="3" class="mb-2">
                    <b-form-select v-model="izvrsiocPrviHandler" :disabled="disabled" :options="izvrsioci"></b-form-select>                                     
                </b-col> 
                <b-col cols="3" class="mb-2">
                    <b-form-select v-model="izvrsiocDrugiHandler" :disabled="disabled" :options="izvrsioci"></b-form-select>                                     
                </b-col>
                <b-col cols="3" class="mb-2">
                    <b-form-select v-model="statusHandler" :disabled="disabled" :options="statusi"></b-form-select>                         
                </b-col>
                <b-col cols="3" class="mb-2">
                    <b-form-select v-model="vrstanalogaHandler"  :disabled="disabled" :options="vrsteNaloga"></b-form-select>             
                </b-col>
                <b-col cols="3" class="mb-2" v-if="group == 5 || group == 11">
                    <b-form-select v-model="nosilacHandler" :disabled="disabled"  :options="warrantyHolder"></b-form-select>                        
                </b-col>
                <b-col cols="3" class="mb-2" v-if="group == 5 || group == 11">
                    <b-form-input v-model.lazy="garantniHandler" :disabled="disabled" placeholder="Garantni list"></b-form-input>                      
                </b-col>
                <b-col cols="3"  class="mb-2" v-if="group == 5|| group == 11">
                    <b-form-select v-model="vrstaSaobraznostiHandler" :disabled="disabled" :options="vrsteSaobraznosti"></b-form-select>            
                </b-col> 
                <!--
                <b-col cols="3" class=" s-icons-form" v-if="group == 5|| group == 11">
                    <i class="fas fa-user-plus"                            
                        v-on:click="showAddBusinesModal">
                    </i>
                    poslovni partner
                </b-col>
                -->
        <add-business-modal></add-business-modal>
            </b-row>
        </b-card>
        <b-card header="Podaci o uredjaju" header-tag="header" class="mb-2">  
            <b-row>                
                <b-col cols="3" class="mb-2">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model.lazy="predprodajaHandler"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                        :disabled="disabled"
                    >Aparat iz predprodaje
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row> 
                <b-col class="mb-2">
                    <label for="example-datepicker1">Vreme izvrsenja</label>
                    <b-form-timepicker 
                        v-model="vremeHandler" 
                        :disabled="disabled"
                        locale="de"
                        placeholder="vreme izvrsenja"
                        minutes-step="30"
                        >
                    </b-form-timepicker>
                </b-col>
                <b-col cols="3" class="mb-2">
                    <label for="example-datepicker1">Datum kupovine</label>
                    <b-form-datepicker locale="de" :disabled="disabled" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }" placeholder="izaberite datum" id="example-datepicker1" v-model.lazy="ftdateHandler" class="mb-2"></b-form-datepicker>
                </b-col> 
                <b-col cols="3" class="mb-2">
                    <label for="example-datepicker2">Datum prijave</label>
                    <b-form-datepicker locale="de" :disabled="disabled" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }" placeholder="izaberite datum" id="example-datepicker2" v-model.lazy="ptdateHandler" class="mb-2"></b-form-datepicker>
                </b-col> 
                <b-col cols="3" class="mb-2">
                    <label for="example-datepicker3">Datum izvrsenja</label>
                    <b-form-datepicker locale="de" :disabled="disabled" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric'  }" placeholder="izaberite datum" id="example-datepicker3" v-model.lazy="itdateHandler" class="mb-2"></b-form-datepicker>
                </b-col>               
                <b-col cols="6" class="mb-2">
                    <b-form-input v-model.lazy="pncHandler" :disabled="disabled" :placeholder="pncText"></b-form-input>                      
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-form-input v-model.lazy="modelHandler" :disabled="disabled" placeholder="Model"></b-form-input>                      
                </b-col>                
                <b-col cols="6" class="mb-2">
                    <b-form-select v-model="aparatHandler" :disabled="disabled" :options="aparati"></b-form-select>                                            
                </b-col>
                <b-col cols="3" class="mb-2">
                    <b-form-select v-model="brendHandler" :disabled="disabled" :options="brendovi"></b-form-select>                                                                
                </b-col>
                <b-col cols="3" class="mb-2">
                    <b-form-input v-model.lazy="serijskiHandler" :disabled="disabled" placeholder="Serijski broj"></b-form-input>                      
                </b-col>
                <b-col cols="8" class="mb-2">
                    <b-form-input v-model.lazy="prodavacHandler" :disabled="disabled" placeholder="uneti naziv, adresu i kontakt prodavca sa fiskalnog računa"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="brgarHandler" :disabled="disabled" placeholder="Oznaka dokumenta produzene garancije"></b-form-input>                      
                </b-col>
            </b-row>    
        </b-card>     
        <b-card header="Podaci o uredjaju" header-tag="header" class="mb-2">  
            <b-row>
                <b-col cols="12" class="mb-2">
                    <b-form-textarea
                        id="textareaOpis"
                        v-model.lazy="opisHandler"
                        placeholder="Opis prijave"
                        rows="10"
                        max-rows="10"
                        :disabled="disabled"
                    ></b-form-textarea>
                </b-col> 
                <b-col cols="12" class="mb-2">                    
                    <b-form-textarea
                        id="textareaMisljenje"
                        v-model.lazy="misljenjeHandler"
                        placeholder="Misljenje servisera"
                        rows="10"
                        max-rows="10"
                        :disabled="disabled"
                    ></b-form-textarea>
                </b-col>                
                <b-col cols="12" class="mb-2" v-if="group==5">                    
                    <b-form-textarea
                        id="textareaNapomena"
                        v-model.lazy="napomenaHandler"
                        placeholder="Napomena"
                        rows="10"
                        max-rows="10"
                        :disabled="disabled"
                    ></b-form-textarea>
                </b-col> 
                
            </b-row>                
        </b-card> 
        <b-card header="Troskovi rada" header-tag="header" class="mb-2">  
            <b-row>
                <b-col cols="8" class="mb-2">
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="lokacija_aparata"
                            v-model.lazy="lokacija_aparataHandler"
                            :aria-describedby="ariaDescribedby"
                            name="radio-sub-component"
                            :disabled="disabled"
                        >
                            <b-form-radio value="1">Popravka kod prodavca</b-form-radio>
                            <b-form-radio value="2">Popravka kod stranke</b-form-radio>
                            <b-form-radio value="3">Popravka u servisu</b-form-radio>                            
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-form-select v-model="kategorijaRadaHandler" :disabled="disabled" :options="kategorijeRada"></b-form-select>                     
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="predjenoHandler" :disabled="disabled" placeholder="Predjeno km"></b-form-input>                      
                </b-col>
                <b-col cols="5" class="mb-2">
                    <b-form-checkbox
                        id="prihvata"
                        v-model.lazy="prihvataHandler"
                        name="prihvata"
                        value="1"
                        :disabled="disabled"
                        unchecked-value="0"
                        >
                        Popravka moguca, stranka ne prihvata popravku
                    </b-form-checkbox>
                </b-col>
                <b-col cols="2" class="mb-2">
                    <label for="demo-sb">Broj izlazaka</label>
                    <b-form-spinbutton id="demo-sb" :disabled="disabled" v-model.lazy="brizlazakaHandler" min="1" max="5"></b-form-spinbutton>
                </b-col>
                
                <b-col cols="6" class="mb-2" v-if="prihvata == 'not_accepted'">
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="nesaobraznost"
                            v-model.lazy="nesaobraznostHandler"
                            :aria-describedby="ariaDescribedby"
                            name="radio-sub-component"
                            :disabled="disabled"
                        >
                            <b-form-radio value="0">Nesaobraznost ne postoji</b-form-radio>
                            <b-form-radio value="1">Nesaobraznost ne postoji</b-form-radio>                                                      
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="mb-2" v-if="prihvata == 'not_accepted'">
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="nesaobraznost-kakva"
                            v-model.lazy="nesaobraznostKakvaHandler"
                            :aria-describedby="ariaDescribedby"
                            name="radio-sub-component"
                            :disabled="disabled"
                        >
                            <b-form-radio value="0">Nesaobraznost je znatna</b-form-radio>
                            <b-form-radio value="1">Nesaobraznost je neznatna</b-form-radio>                                                      
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                
                <b-col cols="5" class="mb-2">
                    <b-form-checkbox
                        id="zamena"
                        v-model.lazy="zamenaHandler"
                        name="zamena"
                        value="1"
                        unchecked-value="0"
                        :disabled="disabled"
                        >
                        Snimanjem dokumenta šaljete zahtev za zamenu 
                    </b-form-checkbox>
                </b-col>
            </b-row>    
        </b-card> 
        <div v-if="id != ''">
            <b-alert show variant="warning">
                <strong> DELOVI za nalog, 
                    <router-link v-bind:to="{path: '/delovi/korpa',query: { group: 1, type : 0, id : id }}">
                        <font color="red"><b>PORUCI OVDE</b></font>
                    </router-link>
                </strong>            
            </b-alert>        
            <b-card header="UNOS UTROŠENIH DELOVA - CENE DELOVA SE UNOSE SA PDV-om" header-tag="header" class="mb-2">  
                <add-part @partAdded="partAdded" @showMessage="showMessage"></add-part>
                <!-- https://bootstrap-vue.org/docs/components/table#complete-example --> 
                <parts-list :items="deloviHandler" :type="'parts'" @deletePart="deletePart"></parts-list>
            </b-card> 
            <b-card header="Fajlovi" header-tag="header" class="mb-2">
                <file-upload @showMessage="showMessage" :orderId="idHandler"></file-upload>
            </b-card>
        </div>
        <b-card header="Istorija servisa" header-tag="header" class="mb-2" v-if="group==5">  
            <working-order-history-table></working-order-history-table>
        </b-card> 
      
    </div> 
       
</template>
<script>
import AddPart from '../delovi/AddPart';
import PartsList from '../delovi/PartsList';
import AddContactModal from '../modals/AddContactModal';
import AddBusinessModal from '../modals/AddBusinessModal.vue';
import WorkingOrderHistoryTable from './WorkingOrderHistoryTable';
import { mapFields } from 'vuex-map-fields';
import FileUpload from '../razno/FileUpload.vue';
import SearchSelect from '../razno/SearchSelect.vue';
import helpers from '../utils/helpers';

export default {
    props:[
        'id',
        'vrsta_naloga',
        'group',
        'grupabrend',
        'options',
        'rbnaloga',
        'imeiprez' ,
        'adresa',
        'ptt',
        'mesto' ,
        'telefon',
        'email',
        'work',
        'status' ,
        'predprodaja',
        'ftdate',
        'ptdate',
        'itdate',
        'vreme',
        'pnc' ,
        'vrsta',
        'model',
        'serijski' ,
        'garantni',
        'prodavac',
        'brgar',
        'opis',
        'misljenje' ,
        'napomena',
        'napservisu',
        'lokacija_aparata',
        'predjeno',
        'prihvata',
        'brizlazaka' ,
        'nesaobraznost' ,
        'nesaobraznostKakva' ,
        'zamena' ,
        'delovi',
        'fajlovi',
        'izvrsioc_prvi',
        'izvrsioc_drugi',
        'vrsta_saobraznosti',
        'nosilac',
        'aparat',
        'brend',
        'servis'
    ],
    name : 'radni-nalog',
    components : {        
        AddPart,
        PartsList,
        AddContactModal,
        AddBusinessModal,
        WorkingOrderHistoryTable,
        FileUpload,
        SearchSelect
    },
    data(){            
        return {
            disabled: false,
            kategorijeRada :this.$root.$options.globals.kategorijeRada,
            reset :false,
            statusi:[{
                value:'',
                text: 'Odaberite status'
            }],
            aparati:[{
                value:'',
                text: 'Odaberite aparat'
            }],
            brendovi:[{
                value:'',
                text: 'Odaberite brend'
            }],
            izvrsioci:[{
                value:'',
                text: 'Odaberite izvrsioca'
            }],
            vrsteNaloga:[{
                value:'',
                text: 'Odaberite vrstu naloga'
            }],
            vrsteSaobraznosti:[{
                value:'',
                text: 'Odaberite vrstu saobraznosti'
            }],
            services:[]
        }
    },
    computed:{        
        pickService:function(){
            let result = false;
            let allowed = ['1b63a771bd7f09244c493450df0c8a72', 'a2e1c28d704ac7f1c665b5092cb45ff5','44c2d9d87a62acfee636b95df766ce26'];
            if(allowed.includes(this.$store.state.alid)){
                result = true;
            }
            return result;
        },
        pncText:function(){
            let text = '';
            if(this.$route.query.group == 1){
                text = 'PNC code';
            }
            else if(this.$route.query.group == 2){
                text = 'Ref code';
            }
            return text
        },
        idHandler: {
            get () {
                if(this.id != ''){
                    return this.id
                }
                else{
                    let time = Date.now();
                    this.$emit('setImageId', time); 
                    return time;
                }
            },
            set(value) {this.$emit('update:id', value) }
        },
        vrstanalogaHandler: {
            get () {return this.vrsta_naloga },
            set(value) {this.$emit('update:vrsta_naloga', value) }
        },
        rbnalogaHandler: {
            get () {return this.rbnaloga },
            set(value) {this.$emit('update:rbnaloga', value) }
        },
        imeiprezHandler: {
            get () { return this.imeiprez },
            set(value) { this.$emit('update:imeiprez', value) }
        },
        adresaHandler: {
            get () { return this.adresa },
            set(value) { this.$emit('update:adresa', value)}
        },
        pttHandler: {
            get () { return this.ptt },
            set(value) { this.$emit('update:ptt', value) }
        },
        mestoHandler: {
            get () { return this.mesto },
            set(value) { this.$emit('update:mesto', value) }
        },
        telefonHandler: {
            get () { return this.telefon },
            set(value) { this.$emit('update:telefon', value) }
        },
        emailHandler: {
            get () { return this.email },
            set(value) { this.$emit('update:email', value) }
        },
        workHandler: {
            get () { return this.work },
            set(value) { this.$emit('update:work', value) }
        },
        statusHandler: {
            get () { return this.status },
            set(value) { this.$emit('update:status', value) }
        },
        predprodajaHandler: {
            get () { return this.predprodaja },
            set(value) { this.$emit('update:predprodaja', value) }
        },
        ftdateHandler: {
            get () { return this.ftdate},
            set(value) { this.$emit('update:ftdate', value) }
        }, 
        ptdateHandler: {
            get () { return this.ptdate},
            set(value) { this.$emit('update:ptdate', value) }
        },
        itdateHandler: {
            get () { return this.itdate},
            set(value) { this.$emit('update:itdate', value) }
        },
        vremeHandler: {
            get () {return this.vreme },
            set(value) {this.$emit('update:vreme', value) }
        },
        serijskiHandler: {
            get () { return this.serijski},
            set(value) { this.$emit('update:serijski', value) }
        },
        garantniHandler: {
            get () { return this.garantni},
            set(value) { this.$emit('update:garantni', value) }
        },
        prodavacHandler: {
            get () { return this.prodavac},
            set(value) { this.$emit('update:prodavac', value) }
        },
        brgarHandler: {
            get () { return this.brgar},
            set(value) { this.$emit('update:brgar', value) }
        },
        opisHandler: {
            get () { return this.opis},
            set(value) { this.$emit('update:opis', value) }
        },
        misljenjeHandler: {
            get () { return this.misljenje},
            set(value) { this.$emit('update:misljenje', value) }
        },
        napomenaHandler: {
            get () { return this.napomena},
            set(value) { this.$emit('update:napomena', value) }
        },
        napservisuHandler: {
            get () {return this.napservisu},
            set(value) { this.$emit('update:napservisu', value) }
        },
        lokacija_aparataHandler: {
            get () { return this.lokacija_aparata},
            set(value) { this.$emit('update:lokacija_aparata', value) }
        },
        predjenoHandler: {
            get () { return this.predjeno},
            set(value) { this.$emit('update:predjeno', value) }
        },
        prihvataHandler: {
            get () { return this.prihvata},
            set(value) { this.$emit('update:prihvata', value) }
        },
        brizlazakaHandler: {
            get () { return this.brizlazaka},
            set(value) { this.$emit('update:brizlazaka', value) }
        },
        pncHandler: {
            get () { return this.pnc},
            set(value) { this.$emit('update:pnc', value) }
        },
        modelHandler: {
            get () { return this.model},
            set(value) { this.$emit('update:model', value) }
        },
        nesaobraznostHandler: {
            get () { return this.nezaobraznost},
            set(value) { this.$emit('update:nesaobraznost', value) }
        },
        nesaobraznostKakvaHandler: {
            get () { return this.nezaobraznostKakva},
            set(value) { this.$emit('update:nesaobraznostKakva', value) }
        },
        zamenaHandler: {
            get () { return this.zamena},
            set(value) { this.$emit('update:zamena', value) }
        },
        deloviHandler: {
            get () { return this.delovi},
            set(value) { this.$emit('update:delovi', value) }
        },
        fajloviHandler: {
            get () { return this.fajlovi},
            set(value) { this.$emit('update:fajlovi', value) }
        },
        izvrsiocPrviHandler: {
            get () { return this.izvrsioc_prvi},
            set(value) { this.$emit('update:izvrsioc_prvi', value) }
        },
        izvrsiocDrugiHandler: {
            get () { return this.izvrsioc_drugi},
            set(value) { this.$emit('update:izvrsioc_drugi', value) }
        },
        vrstaSaobraznostiHandler: {
            get () { return this.vrsta_saobraznosti},
            set(value) { this.$emit('update:vrsta_saobraznosti', value) }
        },
        nosilacHandler: {
            get () { return this.nosilac},
            set(value) { this.$emit('update:nosilac', value) }
        },
        aparatHandler: {
            get () { return this.vrsta},
            set(value) { this.$emit('update:vrsta', value) }
        },
        brendHandler: {
            get () { return this.brend},
            set(value) { this.$emit('update:brend', value) }
        },
        kategorijaRadaHandler: {
            
            get () { return this.work},
            set(value) { this.$emit('update:work', value) }
            
           /*
            get () { return this.work},
            set(value) { this.$emit('update:work', value) }
            */
        },
        grupaHandler: {
            get () { return this.grupa},
            set(value) { this.$emit('update:grupa', value) }
        },
        servisHandler: {
            get () { return this.servis},
            set(value) { this.$emit('update:servis', value) }
        },
        nazivServisa:function(){
            return this.findText(this.servisi, this.servis)
        },
        ...mapFields([
            'workingOrderStatuses',
            'workingOrderTypes',
            'workingOrderCompliances',
            'warrantyHolder',                                               
            ])
    },
    methods:{
        showAddContactModal:function(){
            this.$bvModal.show('addContact')
        },
        showAddBusinesModal:function(){
            this.$bvModal.show('addBussiness')
        },
        partAdded:function(part){         
            console.log(this.delovi)   
            this.deloviHandler.push({...part});
            console.log('part', this.deloviHandler)
        },
        deletePart:function(index){            
            this.delovi.splice(index,1);
        },
        showMessage:function(message){
			this.$emit('showMessage', message)
		},
        workingOrderTypeSelected:function(selected){
            console.log('wotype', selected)
        },
        findText:function(array, id){        
            for(let i in array){           
                if(array[i].value != '' && array[i].value == id){
                    return array[i].text;
                }
            }
            return '';
        },
        inWarranty:function(){            
            if(this.ftdate != '' && this.ptdate != ''){
                let from = new Date(this.ftdate);
                let to = new Date(this.ptdate);
                if((to-from) / (1000*60*60*24) <= 730){
                    return true;
                }                
            }
            else if(this.ftdate != '' && this.ptdate == ''){
                return true;
            }
            else if(this.ftdate == '' && this.ptdate != ''){
                return true;
            }
            return false;
        },
        dateLess:function(reported, sale){
            let to = new Date(reported);
            let from = new Date(sale);
            if((to-from) < 0){
                return true;
            }
            return false;
        },
        getServices:function(){
            this.$http.get('/korisnici/servis').then((response) => {                
                if(response.data.success == true){
                    this.services = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },    
        searchItem:function(item){        
            //this.servis = item.value;
            this.servisHandler = item.text;
        },
        getStatuses:function(){
        this.$http.get('/statusi/svi').then((response) => {                
            if(response.data.success == true){                
                this.statusi.push(...response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        });
        },
        getApliances:function(){
            //console.log(this.$route.query.group);
            let brands = this.$root.$options.globals.brendoviMap;
            //let group = this.$route.query.group;
            let group = brands.filter((brand)=>{                 
                return brand.value == this.$route.query.group;
            });
            if(group[0]!=null && group[0].text != ''){
                this.$http.get('/aparati/brend?' + helpers.objectToParams({brend : group[0].text})).then((response) => {                
                    if(response.data.success == true){
                        console.log(response.data.data)
                        this.aparati.push(...response.data.data);					
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
            
        },
        getBrands:function(){
            this.$http.get('/brendovi/svi?'+ helpers.objectToParams({grupa : this.group})).then((response) => {                
                if(response.data.success == true){                
                    this.brendovi.push(...response.data.data);
                }
            }).catch((error) => {
                console.log(error)
            });
        },  
        getIzvrsioci:function(){
            this.$http.get('/korisnici/izvrsioci').then((response) => {                
                if(response.data.success == true){
                    this.izvrsioci.push(...response.data.data);
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getVrsteNaloga:function(){
            this.$http.get('/radninalog/vrste').then((response) => {                
                if(response.data.success == true){
                    this.vrsteNaloga.push(...response.data.data);
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getVrsteSaobraznosti:function(){
            this.$http.get('/radninalog/saobraznosti').then((response) => {                
                if(response.data.success == true){
                    this.vrsteSaobraznosti.push(...response.data.data);
                }
            }).catch((error) => {
                console.log(error)
            })
        },   
        toggleLock:function(){
            this.disabled = !this.disabled;
            console.log(this.disabled);
        }
    },
    watch:{
        status:function(newVal){
            this.disabled = false;
            if(newVal > 7){
                this.disabled = true;
            }
            
        },
        ftdate:function(){
            // proveriti da li je unazad 2 godine        
            if(this.brgar == '' && !this.inWarranty()){
                this.$emit('showMessage', {message : 'Za uredjaje starije od 2 godine obavezno unesite broj produzene garancije', color:'danger'});
            }
            if(this.itdate != null && this.ftdate != null && this.itdate != '' && this.ftdate != '' && this.dateLess(this.itdate, this.ftdate)){
                this.$emit('showMessage', {message : 'Datum izvrsenja ne moze biti pre datuma prodaje', color:'danger'});
            }
        },
        ptdate:function(){
            // prijava
            if(this.brgar == '' && !this.inWarranty()){
                this.$emit('showMessage', {message : 'Za uredjaje starije od 2 godine obavezno unesite broj produzene garancije', color:'danger'});
            }
            if(this.itdate != '' && this.ptdate != ''  && this.dateLess(this.itdate, this.ptdate)){
                this.$emit('showMessage', {message : 'Datum izvrsenja ne moze biti pre datuma prijave', color:'danger'});
            }
                // prijava ne moze biti starija od kupovine i ne moze biti mladja od datuma izlaska

        },
        itdate:function(){
            // izlazak
            if(this.itdate != '' && this.ftdate != ''  && this.dateLess(this.itdate, this.ftdate)){
                this.$emit('showMessage', {message : 'Datum izvrsenja ne moze biti pre datuma prodaje', color:'danger'});
            }
            if(this.itdate != '' && this.ptdate != ''  && this.dateLess(this.itdate, this.ptdate)){
                this.$emit('showMessage', {message : 'Datum izvrsenja ne moze biti pre datuma prijave', color:'danger'});
            }
            // izlazak ne moze biti pre prijave i datuma kupovine
        }
    },
    mounted(){
        console.log(this.id)
        this.getServices();
        this.getStatuses();
        this.getApliances();
        this.getBrands();
        this.getIzvrsioci();
        this.getVrsteNaloga();
        this.getVrsteSaobraznosti();
    }

}
</script>
