<template>
    <div>
        <b-modal id="showImage" title="" size="xl" hide-footer>            
            <img :src="filepath" alt="" width="100%" height="100%">         
        </b-modal>         
    </div>
</template>
<script>


export default {  
    props :['filepath'],
    name:'add-files-modal',    
    methods:{
        closeModal:function(){            
            this.$bvModal.hide('showImage');
        },
    }
}
</script>