<template>
    <div>
        <b-modal id="addFiles" title="Dodaj fajlove u nalog" size="xl" hide-footer>
            <file-upload @showMessage="showMessage" :orderId="row.item.id" :utilPdfs="utilPdfs"></file-upload>  
            <template #modal-footer="{}">      
                <b-button size="sm"  @click="closeModal()">
                    Otkazi
                </b-button>
                <b-button size="sm" variant="primary" @click="saveFiles()">
                    Snimi
                </b-button>
            </template>         
        </b-modal>         
    </div>
</template>
<script>
import FileUpload from '../razno/FileUpload.vue';

export default {
  components: { FileUpload },
    props :['row', 'utilPdfs'],
    name:'add-files-modal',    
    methods:{
        closeModal:function(){            
            this.$bvModal.hide('addFiles');
        },
        saveFiles:function(){            
            this.$bvModal.hide('addFiles');
        },
        showMessage:function(message){
            this.$emit('showMessage', message)
        }
    }
}
</script>