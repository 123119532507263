<template>
    <div>
        <b-modal id="serviceModal" :title="placeholder" size="lg">
            <search-select :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem"></search-select>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContent()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>
import SearchSelect from '../razno/SearchSelect.vue';

export default {    
    components:{
        SearchSelect
    },
    props :{
        row :{
            type : Object,
            default : ()=>{}
        },
        action:{
            type:String,
            default:''
        }
    },
    name:'change-service-modal',
    data(){
        return{
            servis:'',
            reset : false,
            services:[]
        }
    },
    computed:{
        placeholder:function(){
            let text = 'Promena servisa';     
            return text;       
        }
    },
    methods:{
        closeModal:function(){            
            this.servis = '';
            this.$bvModal.hide('serviceModal');
        },
        saveContent:function(){
            this.$emit('saveContent', {data: this.servis, rowData:this.row});
            this.servis = '';
            this.$bvModal.hide('serviceModal');           
        },
        searchItem:function(item){
            this.servis = item.value;
        },
        getServices:function(){
            this.$http.get('/korisnici/servis').then((response) => {                
                if(response.data.success == true){
                    this.services = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
    },
    mounted(){
        this.getServices();
    }
    
}
</script>