import { getField, updateField } from "vuex-map-fields";

const WorkingOrderData =  {
  namespaced: true,
  state() {
    return {
      id:'',
      options:'',
      rbnaloga:'',
      imeiprez :'',
      adresa : '',
      ptt: '',
      mesto :'',
      telefon : '',
      email : '',
      status :'',
      ftdate : '',
      ptdate : '',
      itdate : '',
      pnc :'',
      vrsta: '',
      model : '',
      serijski :'',
      prodavac : '',
      brgar : '',
      opis : '',
      misljenje :'',
      lokacija_aparata : '',
      predjeno : '',
      prihvata : '',
      brizlazaka :'',
      nesaobraznost :'',
      nesaobraznostKakva :'',
      zamena :'',
      delovi : [],
      fajlovi : [],
      napomena : '',
      napservisu : '',
      izvrsioc_prvi:'',
      izvrsioc_drugi:'',
      predprodaja : '',
      vreme: '08:30:00',
      vrsta_naloga:'',
      vrsta_saobraznosti:'',
      nosilac:'',
      aparat:'',
      brend:'',
      grupabrend:'',
      work:'',
      grupa:'',
      servis:'',
      sameEditor:true
    };
  },
  mutations: {
    updateField,
    setDeletable(state, payload){
        state.deletable = payload
    }
  },
  actions: {},
  getters: {getField}
}

export default WorkingOrderData;

