<template>
    <div>
        <b-row>
            <b-col cols="3" class="mb-2">
                <b-form-select v-model.lazy="filter.group" :options="grupacije"></b-form-select>                                         
            </b-col>
            <b-col cols="3" class="mb-2">
                <b-form-select v-model="filter.status" :options="statusi"></b-form-select>                
            </b-col>
            <b-col cols="3" class="mb-2">
                <search-select :itemList="aparati" :reset="reset" :placeholder="'Odaberite aparat'" @searchItem="searchAparat"></search-select>
                <!-- <b-form-select v-model="filter.aparat" :options="aparati"></b-form-select> -->
            </b-col>
            <b-col cols="3" class="mb-2" v-if="alid === '44c2d9d87a62acfee636b95df766ce26' && showServis">  
                <search-select :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem"></search-select>
                <!-- <b-form-select v-model="filter.servis" :options="servisi"></b-form-select> -->
            </b-col>
            <b-col cols="3" class="mb-2">
                <b-form-select v-model="filter.parametar" :options="parametri"></b-form-select>
            </b-col>
            <b-col cols="3" class="mb-2">
                <b-form-input v-model="filter.searchText" placeholder="trazeno"></b-form-input>
            </b-col>
            <b-col cols="3" class="mb-2">
                <i class="fas fa-search blue s-icons" v-on:click="searchOrders"></i>     
                <i class="fas fa-trash-alt red s-icons"  v-on:click="resetFilter"></i>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import SearchSelect from '../razno/SearchSelect.vue';
import { mapFields } from 'vuex-map-fields';

export default {
  name : 'working-order-filter',
  components:{
    SearchSelect
  },
  props:{
      filterValues:{
          type:Object,
          default:()=>({
                group:'',
                aparat:'',
                status:'',
                searchText:'',
                servis:'',
                parametar:''
          })
      },
      showServis:{
          type:Boolean,
          default:true
      }
  },
  data(){
      return{
        filter:{  
            group:'',
            aparat:'',
            status:'',
            searchText:'',
            servis:'',
            parametar:''
        },
        reset:false,
        grupacije:[{
            value : '',
            text : 'Odaberite grupaciju'
        }],
        statusi :[{
            value : '',
            text : 'Odaberite status'
        }],
        aparati :[{
            value : '',
            text : 'Odaberite aparat'
        }],
        services:[],
        parametri:this.$root.$options.globals.parametri
      }
  },
  watch:{
      filter:{
          handler(newVal){
              this.$store.commit('updateField', {
                        //path: 'user.token',
                        path: 'adminNalogFilter',
                        value: newVal
                    }); 
          },
          deep:true
      },
      filterValues:{
          handler(newVal){
              if(newVal != null && newVal.group != undefined){
                  console.log('izmena', this.filterValues)
                  this.filter = this.filterValues
              }
          },
          deep:true
      }
  },
  computed: {    
      ...mapFields(['alid'])
  },
  methods:{
      resetFilter:function(){
        this.filter.searchText = '';
        this.filter.group = '';
        this.filter.aparat = '';
        this.filter.status = '';
        this.filter.searchText = '';
        this.filter.servis = '';
        this.filter.parametar = '';
        this.reset = true;
        setTimeout(()=>{
            this.reset = false;
            this.searchOrders();
        },300);
        
    },
    searchOrders:function(){
        this.$emit('search', this.filter);
    },
    getGroups:function(){
        this.$http.get('/grupe/svi').then((response) => {                
            if(response.data.success == true){
                this.grupacije.push(...response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    getStatuses:function(){
        this.$http.get('/statusi/svi').then((response) => {                
            if(response.data.success == true){                
                this.statusi.push(...response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    getAppliances:function(){
        this.$http.get('/aparati/select').then((response) => {                
            if(response.data.success == true){                
                this.aparati.push(...response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    getServices:function(){
        this.$http.get('/korisnici/servis').then((response) => {                
            if(response.data.success == true){
                this.services = response.data.data;
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    searchAparat:function(item){
        this.filter.aparat = item.value;

    },
    searchItem:function(item){        
        this.filter.servis = item.value;
        //this.inputServis = item.text;
    }
  },
  created(){
      this.getGroups();
      this.getStatuses();
      this.getAppliances();
      this.getServices();
  }
    
}
</script>