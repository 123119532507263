<template>
  <b-container>
    <h2>Pregled porudzbina</h2>
    <b-row>
      <b-col cols="12" class="mb-2">
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="porudzbine"
            v-model.lazy="porudzbina"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <b-form-radio value="0">poruceno</b-form-radio>
            <b-form-radio value="1">isporuceno</b-form-radio>
            <b-form-radio value="2">neisporuceno</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="porudzbina == 0">
      <orders-filter @search="search" :admin="admin"></orders-filter>
    </div>
    <div v-if="porudzbina == 0">
      <order-parts-table :items="orders" :admin="admin" @stornoPorudzbine="stornoPorudzbine"></order-parts-table>
    </div>
    <div v-if="porudzbina == 1">
      <sent-orders-table :items="ordersRecieved"></sent-orders-table>
    </div>
    <div v-if="porudzbina == 2">
      <un-sent-parts-table :items="unsentParts"></un-sent-parts-table>
    </div>
  </b-container>
</template>

<script>
import OrderPartsTable from "./OrderPartsTable";
import SentOrdersTable from "./SentOrdersTable";
import OrdersFilter from "./OrdersFilter.vue";
import UnSentPartsTable from "./UnSentPartsTable";

export default {
  props: ["orders", "ordersRecieved", "unsentParts", "admin"],
  components: { OrderPartsTable, OrdersFilter, SentOrdersTable, UnSentPartsTable },
  name: "orders",
  data() {
    return {
      porudzbina: 0,
    };
  },
  watch: {
    porudzbina: function () {
      this.$emit("show", this.porudzbina);
    },
  },
  methods: {
    search: function (filter) {
      console.log(filter);
      this.$emit("search", filter);
    },
    stornoPorudzbine:function(row){
      this.$http.patch('/porudzbine/storno/{id}', { id: row.item.id }).then((response) => {                
        if(response.data.success == true){          
          console.log(response.data.data, this.orders[row.index])
          this.orders[row.index].preuzeta = response.data.data.preuzeta;
          this.orders[row.index].razlog = response.data.data.razlog;          
          this.$emit('showMessage', {message:'Porudzbina je stornirana', color:'success'})
        }
      }).catch((error) => {
          console.log(error);
          this.$emit('showMessage', {message:'Porudzbina nije stornirana', color:'warning'})
      }); 
    }
  },
  created() {
    // poruceno
  },
};
</script>
