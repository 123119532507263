<template>
    <div>
        <b-modal id="woTextModal" title="Sadrzaj naloga" size="xl">              
            id : {{wo.id}} <br>
            rbnaloga : {{wo.rbnaloga}} <br>
            id_stranke : {{wo.id}} <br>
            imeiprez : {{wo.imeiprez}} <br>
            adresa : {{wo.adresa}} <br>
            ptt : {{wo.ptt}} <br>
            mesto : {{wo.mesto}} <br>
            pozivni : {{wo.pozivni}} <br>
            telefon : {{wo.telefon}} <br>
            email : {{wo.email}} <br>
            katergorija rada : {{wo.work}} <br>
            datum kupovine : {{wo.ftdate}} <br>
            datum prijave : {{wo.ptdate}} <br>
            datum izvrsenja : {{wo.itdate}} <br>
            pnc : {{wo.pnc}} <br>
            aparat : {{aparat}} <br>
            model : {{wo.model}} <br>
            serijski : {{wo.serijski}} <br>
            lokacija : {{wo.lokacija}} <br>
            vrstagreske : {{wo.vrstagreske}} <br>
            opis : {{wo.opis}} <br>
            misljenje : {{wo.misljenje}} <br>
            kompleksnost : {{wo.kompleksnost}} <br>
            cenarada : {{wo.cenarada}} <br>
            troskovi : {{wo.troskovi}} <br>
            predjeno : {{wo.predjeno}} <br>
            brgar : {{wo.brgar}} <br>
            saobraznost : {{wo.saobraznost}} <br>
            saobraznostkakva : {{wo.saobraznostkakva}} <br>
            grupabrend : {{wo.grupabrend }} <br>
            brend : {{wo.brend}} <br>
            iduser : {{wo.iduser}} <br>
            idprodavac : {{wo.idprodavac}} <br>
            prodavac : {{wo.prodavac}} <br>
            status : {{status}} <br>
            
            verifikovan : {{wo.verifikovan}} <br>
            obracun : {{wo.obracun}} <br>
            racun_poslat : {{wo.racun_poslat}} <br>
            usluga : {{wo.usluga}} <br>
            napservisu : {{wo.napservisu}} <br>
            prihvata : {{wo.prihvata}} <br>
            brizlazaka : {{wo.brizlazaka}} <br>
            zamenjen : {{wo.zamenjen}} <br>
            lokacija_aparata : {{wo.lokacija_aparata}} <br>
            regulus : {{wo.regulus}} <br>
            datum_proizvodnje : {{wo.datum_proizvodnje}} <br>
            predprodaja : {{wo.predprodaja}} <br>
            kreirao : {{wo.kreirao}} <br>
            vreme : {{wo.vreme}} <br>
            
            <table class="table"> 
                <thead>
                    <tr>
                        <th>sifra</th>
                        <th>deo</th>
                        <th>kolicina</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(deo, index) in wo.delovi" :key="index">
                        <td>{{deo.sifradela}}</td>
                        <td>{{deo.naziv}}</td>
                        <td>{{deo.kolicina}}</td>
                    </tr>
                </tbody>
            </table>
                    
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Zatvori
            </b-button>
            
        </template>
       
        </b-modal>    
    </div>
</template>
<script>

export default {    
    props :['row'],
    name:'wo-text-modal',
    data(){
        return{
            wo : {
                
            }
        }
    },
    watch:{
        row:function(newVal){
            this.$http.get('/radninalog/ceo?id=' + newVal.item.nalog_id).then((response) => {                
                if(response.data.success == true){
                    this.wo = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    computed:{
        aparat:function(){
            let aparat = '';
            if(this.wo != null && this.wo.aparat != null){
                aparat = this.wo.aparat.aparat;
            }
            return aparat;
        },
        status:function(){
            let status = '';
            if(this.wo != null && this.wo.status != null){
                status = this.wo.status.status;
            }
            return status
        }
    },
    methods:{
        closeModal:function(){            
            this.$bvModal.hide('woTextModal');
        },
        
    },
    mounted(){
        console.log('mounted')
    }
}
</script>