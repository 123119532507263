<template>
    <b-container>        
        <b-row>
            
            <b-col cols="7" class="mb-2">
                <b-form-group label="" v-slot="{ ariaDescribedby2 }">
                    <b-form-radio-group
                        id="grupa"
                        v-model.lazy="grupa"
                        :aria-describedby="ariaDescribedby2"
                        name="radio-sub-component-2"
                    >
                        <b-form-radio value="1">Electrolux, Aeg, Zanussi</b-form-radio>
                        <b-form-radio value="2">Tefal, Krups, Rowenta</b-form-radio>
                        <b-form-radio value="3">Franke, Liebherr</b-form-radio>                                                      
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        
        <div>
            <b-row>
                <b-col cols="3" class="mb-2">
                    <search-select :itemList="services" :reset="reset" :placeholder="'Odaberite servis'" @searchItem="searchItem"></search-select>
                </b-col>
            </b-row>   
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>
            <calculated-working-order-table v-if="!loading" :items="obracunati" :servis="servis"></calculated-working-order-table>
            <div class="loader">
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
            </div>
            
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>            
        </div>
    </b-container>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { mapFields } from 'vuex-map-fields';
import SearchSelect from '../razno/SearchSelect.vue';
import CalculatedWorkingOrderTable from './CalculatedWorkingOrderTable.vue';

export default {
    props:['page'],
    components: { 
        SearchSelect,
        CalculatedWorkingOrderTable,
        PulseLoader
    },
    data(){
        return{    
            checkedValues:[],        
            status:0,
            grupa:1,
            locked:[],
            verified:[],            
            calculated :[],
            loading : false,
            color: this.$root.$options.globals.loader.color,
            size : this.$root.$options.globals.loader.size,
            navLocked:{},            
            navVerified: 1,
            services:[],
            reset : false,
            servis:null,
            obracunati:[],
            nav:{}
        }
    },
    computed:{
        ...mapFields(['servisi']),
        pageNo:function(){
			if(this.page == null){
				return 1;
			}
			return this.page;
		}
    },
    watch:{
        status:function(newVal){
            console.log('status', newVal)
        },
        grupa:function(newVal){
            this.search(newVal);
            
        },
        page:function(){
            console.log('page', this.page)
			this.getLocked(this.grupa);
		},
        servis:function(newVal){
            (!isNaN(newVal)) ? this.search() : ''            
        }
    },
    
    methods:{
        searchItem:function(item){        
            this.servis = item.value;            
            //this.inputServis = item.text;
        },
        getServices:function(){
            this.$http.get('/korisnici/servis').then((response) => {                
                if(response.data.success == true){
                    this.services = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        
        linkGen(pageNum) {
			return pageNum === 1 ? '?' : `?page=${pageNum}`
		},
        showMessage:function(message){
			this.$emit('showMessage', message)
		},        
        search:function(){
            this.loading = true;
            
            this.$http.get('/radninalog/obracunati?page=' + this.pageNo+ '&user=false&group=' + this.grupa + '&servis=' + this.servis).then((response) => {                                    
                if(response.data.success == true){					
                    this.nav = response.data.data;
                    this.obracunati = response.data.data.data;					
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
                this.loading = false;
            })
        }
        
    },
    mounted(){
        this.getServices();
        this.search();
    }
}
</script>