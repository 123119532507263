import Home from '../components/home';
import RadniNalog from '../components/radninalog/WorkingOrder';
import WorkingOrderList from '../components/radninalog/WorkingOrderList';
import WorkingOrderAll from '../components/radninalog/WorkingOrderAll';
import WorkingOrderByParams from '../components/radninalog/WorkingOrderByParams';
import CalculatedWorkingOrderList from '../components/radninalog/CalculatedWorkingOrderList';
import CalcWorkingOrderDetails from '../components/radninalog/CalcWorkingOrderDetails';
import Basket from '../components/delovi/Basket';
import BasketWrapper from '../components/delovi/BasketWrapper';
import OrdersClient from '../components/delovi/OrdersClient';
import OrdersAdmin from '../components/delovi/OrdersAdmin';
import Warehouse from '../components/delovi/Warehouse';
import Returns from '../components/delovi/Returns';
import Return from '../components/delovi/Return';
import ShowPdf from '../components/pdf/ShowPdf';
import WorkingOrdersStatusesVerified from '../components/radninalog/WorkingOrderStatusesVerified';
import WorkingOrdersStatusesCalculated from '../components/radninalog/WorkingOrderStatusesCalculated';
import WorkingOrdersStatusesLocked from '../components/radninalog/WorkingOrderStatusesLocked';
import BasketAll from '../components/delovi/BasketAll';
import ConfigMenu from '../components/konfiguracije/ConfigMenu';
import ConfigOptions from '../components/konfiguracije/ConfigOptions';
import ConfigAppliances from '../components/konfiguracije/ConfigAppliances';
import ConfigAccess from '../components/konfiguracije/ConfigAccess';
import ConfigWorkingOrder from '../components/konfiguracije/ConfigWorkingOrder';
import ConfigUsers from '../components/konfiguracije/ConfigUsers';
import PricelistUpload from '../components/admin/PricelistUpload';

export const routes = [
        {path:'/', component:Home},       
        {path:'/radninalog', component:RadniNalog, props(route) {
            return  route.query || {}
          }
        }, 
        {path:'/radninalog/lista', component:WorkingOrderList, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/all', component:WorkingOrderAll, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/lista-parametri', component:WorkingOrderByParams, props(route) {
          return  route.query || {}
        }},         
        {path:'/radninalog/obracuni-naloga', component:CalculatedWorkingOrderList, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/svi', component:WorkingOrderByParams, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/verifikovani', component:WorkingOrdersStatusesVerified, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/zakljuceni', component:WorkingOrdersStatusesLocked, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/obracunati', component:WorkingOrdersStatusesCalculated, props(route) {
          return  route.query || {}
        }}, 
        {path:'/radninalog/obracunati-detalji', component:CalcWorkingOrderDetails, props(route) {
          return  route.query || {}
        }}, 
        {path:'/porudzbine/pregled', component:OrdersAdmin},
        {path:'/delovi/korpa', component:BasketWrapper},
        {path:'/delovi/korpe', component:BasketAll},
        {path:'/delovi/poruci-za-partnera', component:Basket}, 
        {path:'/delovi/porudzbine', component:OrdersClient}, 
        {path:'/delovi/magacin', component:Warehouse, props(route) {
          return  route.query || {}
        }},
        {path:'/delovi/magacini', component:Warehouse, props(route) {
          return  route.query || {}
        }},
        {path:'/delovi/povratnice', component:Returns},
        {path:'/delovi/povratnice/kreiraj', component:Return},
        {path:'/delovi/povratnice/izmeni', component:Return, props(route) {
          return  route.query || {}
        }}, 
        {path:'/pdf', component:ShowPdf, props(route) {
          return  route.query || {}
        }}, 
        {path:'/konfiguracija/meni', component:ConfigMenu},
        {path:'/konfiguracija/naloga', component:ConfigWorkingOrder},
        {path:'/konfiguracija/opcije', component:ConfigOptions},
        {path:'/konfiguracija/aparati', component:ConfigAppliances},
        {path:'/konfiguracija/pristupa', component:ConfigAccess},
        {path:'/konfiguracija/korisnici', component:ConfigUsers},
        {path:'/konfiguracija/upload-cenovnik', component:PricelistUpload},

      ];
