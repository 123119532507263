<template>
    <div class="container">
        <table class="table stripped">
            <thead>
                <tr>
                    <td>nalog</td>
                    <td>ime i prezime <br> adresa</td>                    
                    <td>usluga</td>
                    <td>trosak</td>
                    <td class="align-center">delovi</td>
                </tr>
            </thead>            
            <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                    <td>{{row.id}}</td>
                    <td>{{row.imeiprez}} <br> {{row.adresa}}</td>                    
                    <td>{{formatNumber(row.usluga)}}</td>
                    <td>{{formatNumber(row.troskovi)}}</td>
                    <td>
                        <div class="ro">
                            <div class=" row col-12" v-for="(deo, indexD) in row.delovi" :key="indexD">
                                <div class="col-2">{{deo.sifradela}}</div>
                                <div class="col-4">{{deo.naziv}}</div>
                                <div class="col-2">{{deo.kolicina}}</div>
                                <div class="col-2">{{formatNumber(deo.cena)}}</div>
                                <div class="col-2 align-end">{{formatNumber(deo.cena * deo.kolicina)}}</div>
                            </div>
                        </div>                       
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props:{
        obracun:{
            type: Number,
            default: null
        },
        servis:{
            type: Number,
            default: null
        },
        per:{
            type: Boolean,
            default: null
        }
    },
    data(){
        return{
            rows:[]
        }
    },
    methods:{
        formatNumber:function(number){
            return (Math.round(number * 100) / 100).toFixed(2);
        },
        getData:function(){
            this.$http.get('/radninalog/obracunati-nalozi?obracun=' + this.obracun + '&servis=' + this.servis + '&per=' + this.per).then((response) => {                            
//                console.log(response)
				if(response.data.success == true){
					
					this.rows = response.data.data;
					//this.loading = false;
				}
			}).catch((error) => {
				console.log(error)
			})
        }
    },
    mounted(){
        this.getData();
    }
}
</script>