let helpers = {
    objectToParams:function(params){
        let url = '';
        for(let i in params){
            let value = params[i];            
            if(Array.isArray(params[i]) && params[i].length > 0 
            || (typeof params[i] === 'object' && params[i] !== null)){
                value = '';    
                for(let n in params[i]){
                    value += params[i][n] + ',';
                }
                
                value = value.slice(0,-1);
            }                       
            url = url + i + '=' + value + '&';
            
        }
        url = url.slice(0,-1)             
        return url;
    }
}

export default helpers