<template>
    <div>
        <b-modal id="historyModal" :title="placeholder" size="xl">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
            <table class="table" v-if="!loading">
                <thead>
                    <tr>
                        <th width="150px">korisnik</th>
                        <th width="100px">promena</th>
                        <th >menjano</th>
                        <th width="120px">kada</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in rows" :key="index">
                        <td>{{row.user}}</td>
                        <td>{{row.uradjeno}}</td>
                        <td>{{row.podaci}}</td>
                        <td>{{row.updated_at}}</td>
                    </tr>
                </tbody>
            </table>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Zatvori
            </b-button>
            
        </template>
       
        </b-modal>    
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {   
    components:{
        PulseLoader
    }, 
    props :{
        id :{
            
            default :''
        },
        action:{
            type:String,
            default:''
        }
    },
    name:'history-modal',
    data(){
        return{
            rows:[],
            loading : false
        }
    },
    computed:{
        placeholder:function(){
            let text = 'Istorija naloga';            
            return text;
        }
    },
    watch:{
        id:function(){
            this.getHistory();
        }
    },
    methods:{
        closeModal:function(){            
            this.memo = '';
            this.$bvModal.hide('historyModal');
        },
        getHistory:function(){
            this.loading = true;
            if(this.id != ''){
                this.$http.get('/radninalog/istorija?id=' + this.id).then((response) => {                
                    if(response.data.success == true){
                        console.log(response.data.data)
                        this.rows = response.data.data;
                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log(error)
                    this.loading = false;
                })
            }
        }
    },
    mounted(){
        this.getHistory();
    }
}
</script>
<style scoped>
.table{
    table-layout: fixed;
  word-wrap: break-word;
}
</style>