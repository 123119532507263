<template>
    <div>        
        <b-modal id="userModal" title="Kontakt" size="lg">
            <pulse-loader :loading="loadingUser" :color="color" :size="size"></pulse-loader>
            <b-row v-if="!loadingUser" >
                <b-col cols="12" class="mb-2">
                    <b-form-input v-model.lazy="user.name" placeholder="Naziv"></b-form-input>                    
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-form-input v-model.lazy="user.adresa" placeholder="Adresa"></b-form-input>                         
                </b-col>
                <b-col cols="2" class="mb-2">
                    <b-form-input v-model.lazy="user.ptt" placeholder="Ptt"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.mesto" placeholder="Mesto"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.tel" placeholder="tel"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.pdv" placeholder="pdv"></b-form-input>                                          
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.idpartner" placeholder="idpartner"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-checkbox v-model="user.ptt_izuzetak" value="1" unchecked-value="0">ptt izuzetak</b-form-checkbox>                    
                </b-col>
                <b-col cols="4" class="mb-2">
                    <select v-model="user.grupe" id="" class="form-control" multiple>
                        <option :value="grupa.value" v-for="(grupa, index) in options" :key="index" 
                            :selected="user.grupe.includes(grupa.value)">
                            {{grupa.text}}
                        </option>
                    </select>
                    
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.glavni" placeholder="glavni"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.pib" placeholder="pib"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="user.centrala" placeholder="centrala"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-checkbox v-model="user.izvrsioc" value="1" unchecked-value="0">izvrsioc</b-form-checkbox>                                                           
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-checkbox v-model="user.is_deleted" value="1" unchecked-value="0">neaktivan</b-form-checkbox>                    
                </b-col>
                <b-col cols="4" class="mb-3">
                    <select v-model.lazy="user.vrsta" id="" class="form-control">
                        <option :value="vrsta.value" v-for="(vrsta, index) in vrste" :key="index" 
                            :selected="user.vrsta.includes(vrsta.value)">
                            {{vrsta.text}}
                        </option>
                    </select>                    
                </b-col>
                <b-col cols="4" class="mb-3">
                    <select v-model.lazy="user.podvrsta" id="" class="form-control">
                        <option :value="podvrsta.value" v-for="(podvrsta, index) in podvrste" :key="index" 
                            :selected="user.vrsta.includes(podvrsta.value)">
                            {{podvrsta.text}}
                        </option>
                    </select>                    
                </b-col>
            </b-row> 
            
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContact">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    props :['user', 'loadingUser'],
    name:'user-modal',
    components:{
        PulseLoader
    },
    data(){
        return{            
            name :'',
            adresa : '',
            mesto: '',
            ptt : '',
            tel : '',
            email : '',
            vrsta: '',
            pdv : '',
            idpartner : '',
            ptt_izuzetak : '',
            grupe: '',
            glavni: '',
            pib :'',
            options:[],
            vrste:[
                {value : 'servis', text : 'servis'},
                {value : 'prodavac', text : 'prodavac'},
                {value : 'kancelarija', text : 'kancelarija'}
            ],
            podvrste:[
                {value : 'soca_serviser', text : 'Soca serviser'},
                {value : 'soca_prijem', text : 'Soca prijem'},
                {value : 'soca_admin', text : 'Soca admin'},
                {value : 'podservis', text : 'podservis'},
                {value : 'podservis_admin', text : 'podservis admin'},
                {value : 'prodavac', text : 'prodavac'},
                {value : 'prodavac_admin', text : 'prodavac admin'},
                {value : 'kancelarija', text : 'kancelarija'}
            ]             
        }

    },

    methods:{
        closeModal:function(){            
            this.$bvModal.hide('userModal');
        },
        saveContact:function(){            
            this.$emit('saveChanges', this.user);
            this.$bvModal.hide('userModal');
            
        }
    },
    mounted(){
        
        this.$http.get('/grupe/svi').then((response) => {                
            if(response.data.success == true){
                console.log(response.data.data)
                this.options.push(...response.data.data);
                
            }
        }).catch((error) => {
            console.log(error)
            
        })
    }
}
</script>