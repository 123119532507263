<template>
    <b-container>
        <b-row class="setter-menu">
            <b-col cols="7" >
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="menu"
                        v-model.lazy="menu"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                    >
                    <b-form-radio value="1">Elektroluks, Aeg, Zanussi</b-form-radio>
                    <b-form-radio value="2">Tefal, Krups, Rowenta</b-form-radio>                                                      
                    <b-form-radio value="3">Franke, Liebherr</b-form-radio>                                                      
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col >
                <b-button variant="primary" v-on:click="showModal()">Ucitaj fajl</b-button>
            </b-col> 
            <b-col >
                <b-button variant="primary" v-on:click="save()">Snimi podesavanja</b-button>
            </b-col>           
        </b-row>   
        <add-files-modal :row="this.row" :utilPdfs="true"></add-files-modal>       
        <b-row>
            <b-col cols="6" class="mb-2 text-center">Meni</b-col>
            <b-col cols="6" class="mb-2 text-center">PDF-ovi</b-col>
            <b-col cols="6" class="mb-2">
                <div v-if="newList.length > 0">
                 <draggable
                    :list="newList"            
                    @start="dragging = true"
                    @end="dragging = false"
                    >
                    <div
                    class="list-group-item"
                    v-for="element in newList"
                    :key="element.id"
                    >
                    <span v-on:click="removeEntry(element)">
                        <i class="fas fa-minus"></i>
                    </span> 
                    {{ element.naziv }}
                    </div>
                </draggable>
                </div>
            </b-col>
            <b-col cols="6" class="mb-2">
                <ul class="lista">
                    <li v-for="(pdf, index) in filteredPdfs" :key="index">
                        <div class="list-group-item">
                            <span v-on:click="addEntry(pdf)">
                                <i class="fas fa-plus"></i> {{ pdf.naziv }}
                            </span>   
                        </div>                     
                    </li>
                </ul>
            </b-col>
        </b-row>       
    </b-container>
</template>
<script>
import draggable from 'vuedraggable'
import '../../assets/styles/app.css'
import AddFilesModal from '../modals/AddFilesModal.vue'

export default {
    name:'config-menu',
    components:{draggable,AddFilesModal },
    data(){    
        return{
            row : {item:{id:''}},
            menuPdfs:[],
            pdfs : [],
            menu : 1,
            newList : []
        }
    },
    computed:{
        filteredPdfs:function(){            
            let filtered = [];
            for(let i in this.pdfs){                
                let exists = this.newList.filter((nList)=>{return nList.id === this.pdfs[i].id});                
                if(this.pdfs[i].grupa == this.menu && exists.length == 0){
                    filtered.push(this.pdfs[i]);
                }
            }            
            return filtered;
        }
    },
    watch:{
        menu:function(){
            this.getPdfs();
            this.getMenuPdfs();
            //this.newList = [...this.$store.getters.getMenu(this.menu)];       
        }
    },
    methods:{
        save:function(){
            let value = {menu: this.menu, list: this.newList}
            this.$http.patch('/menu_pdfs', value).then((response) => {                
                if(response.data.success == true){          
                    console.log(response.data.data)                   
                    this.$emit('showMessage', {message:'Snimljeno', color:'success'})
                    this.loadNewMenuList();
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'nije snimljeno', color:'warning'})
            }); 
            //this.$store.dispatch('saveConfigMenu', JSON.parse(JSON.stringify(value)))
        },
        addEntry:function(entry){
            this.newList.push(entry)            
        },
        removeEntry:function(entry){
            for(let i in this.newList){
                if(this.newList[i] == entry){
                    this.newList.splice(i, 1)
                }
            }
            
        },
        showModal:function(){
            this.$bvModal.show('addFiles');
        },
        closeModal:function(modal){
            this[modal] = false;
        },
        getPdfs:function(){
            this.pdfs = [];
            this.$http.get('/files/group_pdfs?group=' + this.menu).then((response) => {                
				if(response.data.success == true){
					console.log(response.data.data)
                    this.pdfs = [...response.data.data];
				}
			}).catch((error) => {
				console.log(error)
			})
        },   
        getMenuPdfs:function(){
            this.newList = [];
            this.$http.get('/menu_pdfs?group=' + this.menu).then((response) => {                
				if(response.data.success == true){					
                    this.newList = [...response.data.data];
				}
			}).catch((error) => {
				console.log(error)
			})
        },
        loadNewMenuList:function(){
            this.$http.get('/menu_pdfs/svi').then((response) => {                
                if(response.data.success == true){					
                    this.$store.commit('updateField', {
                        path: 'pdfMenu',
                        value: response.data.data
                    });
                }
            }).catch((error) => {
                console.log(error)
            })
        }  
    },
    created(){ 
        //this.pdfs = this.$store.getters.getPdfs; 
        //this.newList = [...this.$store.getters.getMenu(this.menu)];
        this.getPdfs();   
        this.getMenuPdfs(); 
      
                
    }
}
</script>
<style scope>
    .lista{
        list-style-type: none;
        padding: 0;
        margin: 0;
    }    
</style>