<template>
    <div>
        <orders 
        :orders="orders" 
        :ordersRecieved="ordersRecieved" 
        :unsentParts="unsentParts"
		:admin="false"
        @search="search"
		@show="showChanged"
        ></orders>
    </div>
</template>
<script>
import Orders from './Orders.vue';
import Helpers from '../utils/helpers';

export default {
	components: { Orders },
	data(){
		return{
			orders: [],
			ordersRecieved: [],
			unsentParts: [],
			filter:{
				porucio:'',
				sifradela:'',
				rbnaloga:'',
				datumOd:'',
				datumDo:''
			},
			visible : 0
			}
		},
	methods:{
		search:function(filter){
			delete(filter.porucio);
			console.log('filter', filter);			
			this.filter = filter;
			switch(this.visible){
				case(0):
					this.getPorudzbine();
					break;
				case(1):
					this.getPoslato();
					break;	
				case(2):
					this.getNeisporuceno();
					break;
			}
		},  
		showChanged:function(show){
			this.visible = show;
		},
		getPorudzbine:function(){
			console.log('get', this.filter)
			this.$http
			.get("/porudzbine?" + Helpers.objectToParams(this.filter))
			.then((response) => {
				if (response.data.success == true) {
				this.orders = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getPoslato:function(){
			this.$http
			.get("/poslato")
			.then((response) => {
				if (response.data.success == true) {
				this.ordersRecieved = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getNeisporuceno:function(){
			this.$http
			.get("/porudzbine/stavke/neisporuceno")
			.then((response) => {
				if (response.data.success == true) {
				this.unsentParts = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
		}
	},
	created(){
		this.getPorudzbine();    
		this.getPoslato();
		this.getNeisporuceno();
	}
    
}
</script>