import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

import { getField, updateField } from "vuex-map-fields";


const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})


export default new Vuex.Store({
    state:{
        user : {
            type:'admi',
            token : ''
        },
        uid : '',
        alid : '',
        vm : {},
        groups:[],
        radniNalozi:[],        
        pdfMenu : {},
        warrantyHolder :[
            {value:'' , text:'Nosilac garancije'},
            {value:1 , text:'Soca'},
            {value:2 , text:'Electrolux'},
            {value:3 , text:'Seb'},
        ],
        adminNalogFilter:{}
    },
    mutations:{
        updateField,
        saveConfigMenu(state, payload){
            Vue.set(state.pdfMenu, payload.menu , payload.list);
        }
    },
    actions:{
        saveConfigMenu(context, payload){
            context.commit('saveConfigMenu', payload)    
        }
    },
    getters:{
        getField,
        getPdf:(state)=>(pdfId)=>{
            return state.pdfs[pdfId];            
        },
        getPdfs:(state)=>{
            return state.pdfs;            
        },
        getMenu:(state)=>(menu)=>{
            return state.pdfMenu[menu];
        },
        getUser:(state)=>{
            return state.user;
        },                 
    },
    modules:{

    },
    plugins:[vuexLocal.plugin]
});
