<template>
    <div>
        <b-modal id="orderItemsModal" title="Detalji porudzbine" size="xl" hide-footer>
            <order-part-list-table :items="parts" :statusChange="true" @refreshStavke="refreshStavke"></order-part-list-table>       
        </b-modal>    
    </div>
</template>
<script>
import OrderPartListTable from '../delovi/OrderPartListTable.vue';

export default {
  components: { OrderPartListTable },    
    props :['showOrderItemsModal', 'row', 'parts'],
    name:'order-item-modal',
    data(){
        return{
            memo:''
        }
    },
    watch:{
        showOrderItemsModal:function(newVal){
            if(newVal){
                this.$bvModal.show('orderItemsModal'); 
            }
            else{
                this.$bvModal.hide('orderItemsModal'); 
            }
        }
    },
    methods:{
        closeModal:function(){
            console.log('cancel')
            this.memo = '';
            this.$emit('toggleModal', 'showOrderItemsModal')
        },
        saveContent:function(){
            console.log('save')
            this.$emit('saveContent', {data: this.memo, rowData:this.row});
            this.memo = '';
            this.$emit('toggleModal', 'showOrderItemsModal')
        },
        refreshStavke:function(row){
            this.$emit('refreshStavke', row);
        }
    }
}
</script>
