<template>
    <div>
        <b-modal id="reworkModal" title="Potrebna dorada naloga" size="lg">
            <b-form-textarea
                id="textarea-default"
                placeholder="Unesite razlog"
                v-model.lazy="memo"
            ></b-form-textarea>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContent()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>

export default {    
    props :['row'],
    name:'add-memo-modal',
    data(){
        return{
            memo:''
        }
    },
    methods:{
        closeModal:function(){            
            this.memo = '';
            this.$bvModal.hide('reworkModal');
        },
        saveContent:function(){
            this.$emit('saveContent', {data: this.memo, row:this.row});
            this.memo = '';
            this.$bvModal.hide('reworkModal');           
        }
    }
}
</script>