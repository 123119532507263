<script>
    export default {
        computed: {
            id: {
                get() {
                    return this.$store.state.radniNalog.id;
                    //return this.$store.getters['radniNalog/getField']('id');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.id',
                        value: value
                    });
                }
            },
            vrsta_naloga: {
                get() {
                    return this.$store.getters['radniNalog/getField']('vrsta_naloga');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.vrsta_naloga',
                        value: value
                    });
                }
            },
            rbnaloga: {
                get() {
                    return this.$store.getters['radniNalog/getField']('rbnaloga');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.rbnaloga',
                        value: value
                    });
                }
            },
            imeiprez: {
                get() {
                    return this.$store.getters['radniNalog/getField']('imeiprez');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.imeiprez',
                        value: value
                    });
                }
            },
            adresa: {
                get() {
                    return this.$store.getters['radniNalog/getField']('adresa');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.adresa',
                        value: value
                    });
                }
            },
            mesto: {
                get() {
                    return this.$store.getters['radniNalog/getField']('mesto');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.mesto',
                        value: value
                    });
                }
            },
            ptt: {
                get() {
                    return this.$store.getters['radniNalog/getField']('ptt');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.ptt',
                        value: value
                    });
                }
            },
            telefon: {
                get() {
                    return this.$store.getters['radniNalog/getField']('telefon');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.telefon',
                        value: value
                    });
                }
            },
            email: {
                get() {
                    return this.$store.getters['radniNalog/getField']('email');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.email',
                        value: value
                    });
                }
            },
            status: {
                get() {
                    return this.$store.getters['radniNalog/getField']('status');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.status',
                        value: value
                    });
                }
            },
            predprodaja: {
                get() {
                    return this.$store.getters['radniNalog/getField']('predprodaja');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.predprodaja',
                        value: value
                    });
                }
            },
            ftdate: {
                get() {
                    return this.$store.getters['radniNalog/getField']('ftdate');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.ftdate',
                        value: value
                    });
                }
            },
            ptdate: {
                get() {
                    return this.$store.getters['radniNalog/getField']('ptdate');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.ptdate',
                        value: value
                    });
                }
            },
            vreme: {
                get() {
                    return this.$store.getters['radniNalog/getField']('vreme');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.vreme',
                        value: value
                    });
                }
            },
            itdate: {
                get() {
                    return this.$store.getters['radniNalog/getField']('itdate');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.itdate',
                        value: value
                    });
                }
            },
            pnc: {
                get() {
                    return this.$store.getters['radniNalog/getField']('pnc');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.pnc',
                        value: value
                    });
                }
            },
            vrsta: {
                get() {
                    return this.$store.getters['radniNalog/getField']('vrsta');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.vrsta',
                        value: value
                    });
                }
            },
            model: {
                get() {
                    return this.$store.getters['radniNalog/getField']('model');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.model',
                        value: value
                    });
                }
            },
            serijski: {
                get() {
                    return this.$store.getters['radniNalog/getField']('serijski');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.serijski',
                        value: value
                    });
                }
            },
            garantni: {
                get() {
                    return this.$store.getters['radniNalog/getField']('garantni');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.garantni',
                        value: value
                    });
                }
            },
            prodavac: {
                get() {
                    return this.$store.getters['radniNalog/getField']('prodavac');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.prodavac',
                        value: value
                    });
                }
            },
            brgar: {
                get() {
                    return this.$store.getters['radniNalog/getField']('brgar');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.brgar',
                        value: value
                    });
                }
            },
            opis: {
                get() {
                    return this.$store.getters['radniNalog/getField']('opis');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.opis',
                        value: value
                    });
                }
            },
            misljenje: {
                get() {
                    return this.$store.getters['radniNalog/getField']('misljenje');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.misljenje',
                        value: value
                    });
                }
            },
            lokacija_aparata: {
                get() {
                    return this.$store.getters['radniNalog/getField']('lokacija_aparata');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.lokacija_aparata',
                        value: value
                    });
                }
            },
            predjeno: {
                get() {
                    return this.$store.getters['radniNalog/getField']('predjeno');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.predjeno',
                        value: value
                    });
                }
            },
            prihvata: {
                get() {
                    return this.$store.getters['radniNalog/getField']('prihvata');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.prihvata',
                        value: value
                    });
                }
            },
            brizlazaka: {
                get() {
                    return this.$store.getters['radniNalog/getField']('brizlazaka');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.brizlazaka',
                        value: value
                    });
                }
            },
            nesaobraznost: {
                get() {
                    return this.$store.getters['radniNalog/getField']('nesaobraznost');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.nesaobraznost',
                        value: value
                    });
                }
            },
            nesaobraznostKakva: {
                get() {
                    return this.$store.getters['radniNalog/getField']('nesaobraznostKakva');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.nesaobraznostKakva',
                        value: value
                    });
                }
            },
            zamena: {
                get() {
                    return this.$store.getters['radniNalog/getField']('zamena');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.zamena',
                        value: value
                    });
                }
            },
            delovi: {
                get() {
                    return this.$store.getters['radniNalog/getField']('delovi');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.delovi',
                        value: value
                    });
                }
            },
            fajlovi: {
                get() {
                    return this.$store.getters['radniNalog/getField']('fajlovi');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.fajlovi',
                        value: value
                    });
                }
            },
            napomena: {
                get() {
                    return this.$store.getters['radniNalog/getField']('napomena');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.napomena',
                        value: value
                    });
                }
            },
            napservisu: {
                get() {
                    return this.$store.getters['radniNalog/getField']('napservisu');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.napservisu',
                        value: value
                    });
                }
            },
            izvrsioc_prvi: {
                get() {
                    return this.$store.getters['radniNalog/getField']('izvrsioc_prvi');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.izvrsioc_prvi',
                        value: value
                    });
                }
            },
            izvrsioc_drugi: {
                get() {
                    return this.$store.getters['radniNalog/getField']('izvrsioc_drugi');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.izvrsioc_drugi',
                        value: value
                    });
                }
            },
            vrsta_saobraznosti: {
                get() {
                    return this.$store.getters['radniNalog/getField']('vrsta_saobraznosti');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.vrsta_saobraznosti',
                        value: value
                    });
                }
            },
            nosilac: {
                get() {
                    return this.$store.getters['radniNalog/getField']('nosilac');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.nosilac',
                        value: value
                    });
                }
            },
            aparat: {
                get() {
                    return this.$store.getters['radniNalog/getField']('aparat');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.aparat',
                        value: value
                    });
                }
            },
            brend: {
                get() {
                    return this.$store.getters['radniNalog/getField']('brend');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.brend',
                        value: value
                    });
                }
            },
            grupabrend: {
                get() {
                    return this.$store.getters['radniNalog/getField']('grupabrend');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.grupabrend',
                        value: value
                    });
                }
            },
            work: {
                get() {
                    return this.$store.getters['radniNalog/getField']('work');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.work',
                        value: value
                    });
                }
            },
            grupa: {
                get() {
                    return this.$store.state.radniNalog.grupa;
                    //return this.$store.getters['radniNalog/getField']('grupa');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.grupa',
                        value: value
                    });
                }
            },
            servis: {
                get() {
                    return this.$store.getters['radniNalog/getField']('servis');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.servis',
                        value: value
                    });
                }
            },
            sameEditor: {
                get() {
                    return this.$store.getters['radniNalog/getField']('sameEditor');
                },
                set(value){
                    this.$store.commit('updateField', {
                        path: 'radniNalog.sameEditor',
                        value: value
                    });
                }
            },
            
        }
    }
</script>