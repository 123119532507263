<template>
    <div>
        <b-modal id="groupModal" :title="placeholder" size="lg">        
            <select v-model="grupa" id="" class="form-control">
                <option :value="grupa.value" v-for="(grupa, index) in options" :key="index" 
                    :selected="false">
                    {{grupa.text}}
                </option>
            </select>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContent()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>

export default {    
    props :{
        row :{
            type :Object,
            default : ()=>{}
        },
        action:{
            type:String,
            default:''
        },        
    },
    name:'change-group-modal',
    data(){
        return{
            grupa:'',
            options:[]
        }
    },
    computed:{
        placeholder:function(){
            let text = 'Promena grupe';            
            return text;
        }
    },
    methods:{
        closeModal:function(){            
            this.memo = '';
            this.$bvModal.hide('groupModal');
        },
        saveContent:function(){
            this.$emit('saveContent', {data: this.grupa, rowData:this.row});
            this.grupa = '';
            this.$bvModal.hide('groupModal');           
        }
    },
    mounted(){
        this.$http.get('/grupe/svi').then((response) => {                
            if(response.data.success == true){
                console.log(response.data.data)
                this.options.push(...response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        })
    }
}
</script>