<template>
    <b-container>        
        <b-row>            
            <b-col cols="7" class="mb-2">
                <b-form-group label="" v-slot="{ ariaDescribedby2 }">
                    <b-form-radio-group
                        id="grupa"
                        v-model.lazy="grupa"
                        :aria-describedby="ariaDescribedby2"
                        name="radio-sub-component-2"
                    >
                        <b-form-radio value="1">Electrolux, Aeg, Zanussi</b-form-radio>
                        <b-form-radio value="2">Tefal, Krups, Rowenta</b-form-radio>
                        <b-form-radio value="3">Franke, Liebherr</b-form-radio>                                                      
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <div >
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="navLocked.last_page" use-router></b-pagination-nav>
            <working-order-statuses-table :items="locked" @refreshList="refreshList" @showMessage="showMessage"  :status="status"></working-order-statuses-table>
            <div class="loader">                
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
            </div> 
            <div v-if="!loading">
                <b-pagination-nav :link-gen="linkGen" :number-of-pages="navLocked.last_page" use-router></b-pagination-nav>
            </div>	
                     
        </div>
        
    </b-container>
</template>
<script>

import { mapFields } from 'vuex-map-fields';
import WorkingOrderStatusesTable from './WorkingOrderStatusesTable.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    props:['page'],
    components: { 
        WorkingOrderStatusesTable,
        //SearchSelect,  
        PulseLoader, 
        //CalculatedWorkingOrderList 
    },
    data(){
        return{    
            checkedValues:[],        
            status:0,
            grupa:1,
            locked:[],
            verified:[],            
            calculated :[],
            loading : false,
            color: this.$root.$options.globals.loader.color,
            size : this.$root.$options.globals.loader.size,
            navLocked:{},            
            navVerified: 1,
            services:[],
            reset : false,
            servis:null
        }
    },
    computed:{
        ...mapFields(['servisi']),
        pageNo:function(){
			if(this.page == null){
				return 1;
			}
			return this.page;
		}
    },
    watch:{
        status:function(newVal){
            console.log('status', newVal)
        },
        grupa:function(newVal){
            //console.log(newVal)
            //if(this.status == 0){
                this.getLocked(newVal);        
            //}
            //else if(this.status == 1){
                this.getVerified(newVal);
            //}
            //else{
                this.calculated(newVal);
            //}
            
        },
        page:function(){
            console.log('page', this.page)
			this.getLocked(this.grupa);
		}
    },
    
    methods:{
        searchItem:function(item){        
            this.servis = item.value;            
            //this.inputServis = item.text;
        },
       
        
        linkGen(pageNum) {
			return pageNum === 1 ? '?' : `?page=${pageNum}`
		},
        showMessage:function(message){
			this.$emit('showMessage', message)
		},
        refreshList:function(){
            console.log('refresh list izvrsena')
            this.getLocked(this.grupa);
            
        },
        getLocked:function(group){
            this.loading = true;
            this.$http.get('/radninalog/zakljuceni?page=' + this.pageNo + '&group='+group).then((response) => {                              
                if(response.data.success == true){
                    console.log('zakljuceni', response.data.data.data)
                    let locked = JSON.parse(JSON.stringify(response.data.data.data));
                    for(let i=0;i<locked.length;i++){
                        if(locked[i].regulus == 'da'){
                            locked[i]['_rowVariant'] = 'success';
                        }
                    }
                    this.navLocked = response.data.data;
                    //this.locked = response.data.data.data;
                    this.locked = locked;
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
                this.loading = false;
            })
        },
        
    },
    mounted(){
        this.getLocked(1);
        
    }
}
</script>