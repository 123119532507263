<template>
    <b-container>
        <b-row>
            <b-col cols="6">
                <b-form-select v-model="selectedGroup" :options="groups" @change="loadOptions"></b-form-select>
            </b-col>
            <b-col cols="6">
                <b-form-select v-model="selectedOption" :options="options" @change="setOption"></b-form-select>
            </b-col>
            
        </b-row>
        <hr/>
        <b-row>
            <b-col cols="9">
                <b-form-input v-model="optionValue" ></b-form-input>    
            </b-col>
             <b-col cols="3">
                <b-button block variant="primary" @click="saveOption">Snimi</b-button>
            </b-col>
        </b-row>
        <div>
            {{description}}
        </div>
    </b-container>
</template>
<script>
export default {
    data(){
        return{
            options:[],
            groups:[],
            optionValue:'',
            selectedOption: '',
            selectedGroup : '',
            description : ''
        }
    },
    methods:{
        getGroups:function(){
            this.$http.get('/configs/option_groups').then((response) => {                
				if(response.data.success == true){
					console.log(response.data.data)
					this.groups = response.data.data;
				}
			}).catch((error) => {
				console.log(error)
			})
        },
        loadOptions:function(){
            this.$http.get('/configs/options?group=' + this.selectedGroup).then((response) => {                
				if(response.data.success == true){
					
                    let dropdown = [];
                    for(let i in response.data.data){
                        let values = {
                            value : response.data.data[i].id,
                            text : response.data.data[i].name,
                            desc : response.data.data[i].description,
                            data : response.data.data[i].value
                        }
                        dropdown.push(values);
                    }
                    console.log(dropdown)
					this.options = dropdown;
				}
			}).catch((error) => {
				console.log(error)
			})
        },
        setOption:function(){
            let data = this.options.filter((option) => {
                return option.value == this.selectedOption;
            });
            console.log(data)
            this.optionValue = data[0].data;
            this.description = data[0].desc;
        },
        saveOption:function(){
            this.$http.patch('/configs/options', { id: this.selectedOption, value : this.optionValue }).then((response) => {                
                if(response.data.success == true){          
                    console.log(response.data.data)                   
                    this.$emit('showMessage', {message:'Snimljeno', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'nije snimljeno', color:'warning'})
            }); 
        }

    },
    created(){
        this.getGroups();
    }
    
}
</script>