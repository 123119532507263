<template>
    <b-container>
        <div class="card mb-2">
            <div class="card-header">
                <b-link class="red-link" to="/radninalog/lista">Lista naloga u radu</b-link> - prikazano je samo poslednjih 5 naloga
            </div>  
            <div class="card-body">                
                <working-order-table :nalozi="nalozi" @refreshList="refreshList" @showMessage="showMessage"></working-order-table>                
            </div>
        </div>
        <div class="card mb-2" >
            <div class="card-header">
                <b-link class="red-link" to="/delovi/korpa">Sadrzaj korpe</b-link>
            </div>  
            <div class="card-body">
            
                <parts-list :items="delovi" :type="'basket'" @deletePart="deletePart"></parts-list>
            
            </div>
        </div>
        <div class="card mb-2" >
            <div class="card-header">
                Poslednji dodati fajlovi
            </div>  
            <div class="card-body">
                <div v-for="(file, index) in fajlovi" :key="index">
                    {{file.original_name}}
                </div>
            </div>
        </div>
    </b-container>
</template>
<script>
import WorkingOrderTable from './radninalog/WorkingOrderTable.vue'
import PartsList from './delovi/PartsList';
import helpers from './utils/helpers';

export default {
  components: { 
      WorkingOrderTable,
      PartsList 
      },
    name: 'home',
    data(){
        return{
            listaNaloga:'Lista naloga u radu - prikazano je samo poslednjih 5 naloga',
            nalozi:[],
            delovi:[],
            fajlovi:[]
        }
    },
    computed:{
        
    },
    methods:{
        getWorkingOrders:function(){
            
            
            this.$http.get('/radninalog/poslednji?' + helpers.objectToParams({status : [1]})).then((response) => {                            
                console.log(response)
				if(response.data.success == true){
					console.log(response.data.data.data)
					this.nalozi = response.data.data.data;
					this.loading = false;
				}
			}).catch((error) => {
				console.log(error)
			})
            
        },
        refreshList:function(){
            console.log('refresh')
            this.getWorkingOrders();
        },
        showMessage:function(message){
          this.$emit('showMessage', message)
        },
        partAdded:function(part){        
          console.log('part', part)
          this.$http.post('/korpa',{ data: part }).then((response) => {                
            if(response.data.success == true){
              this.delovi.push({...part});
              
              this.$emit('showMessage', {message:'Artikal dodat u korpu', color:'success'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Artikal nije dodat u korpu', color:'warning'})
          });     
            
        },
        deletePart:function(index){    
            this.$http.delete('/korpa',{ data: { id: this.delovi[index].id } }).then((response) => {                
                if(response.data.success == true){
                this.delovi.splice(index,1);
                this.$emit('showMessage', {message:'Artikal je obrisan iz korpe', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'Artikal nije obrisan iz korpe', color:'warning'})
            });        
           
        },
        getParts:function(){
            this.$http.get('/korpa').then((response) => {                
                if(response.data.success == true){
                    this.delovi = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getLastAdded:function(){
            this.$http.get('/files/lastadded').then((response) => {                
                if(response.data.success == true){
                    this.fajlovi = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    created(){
        this.getWorkingOrders();
        this.getParts();
        this.getLastAdded();
    }
}
</script>
<style scoped>
    .red-link{
        color:red;
        font-weight: bold;
    }
</style>