<template>
  <div>
    <b-table hover :items="items" :fields="fields"></b-table>
  </div>
</template>

<script>


  export default {
    props:['showButtons'],    
    name : 'working-order-history-table',
    data() {
      return {          
          row : {},
          showAddMemoModal : false,
          
            fields: [
            {
                key: 'rbnaloga',
                label: 'Br naloga',
                sortable: true
            },
            {
                key: 'aparat',
                label: 'aparat',
                sortable: true
            },
            {
                key: 'model',                
                label: 'model',
                sortable: true
            },
            {
                key: 'PNC',
                label: 'PNC',
                sortable: true
            },
            {
                key: 'serijski',
                label: 'Serijski',
                sortable: false
            },
            {
                key: 'opis',
                label :'opis'
            },
            {
                key: 'uradjeno',
                label :'uradjeno'
            }
            ],
            items: [
            
            ]
      }
    },
    methods:{
      
    },
    computed:{
      admin:function(){
        if(this.$store.getters.getUser.type == 'admin'){
          return true
        }
        
        return false;
      }
    }
  }
</script>
<style scoped>
    .settings{
      font-size: 25px;
    }
    .link{
      color: blue;
    }
</style>