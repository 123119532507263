<template>
    <b-container>        
         <b-row class="setter-menu">
            <b-col cols="7" >
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="menu"
                        v-model.lazy="aparat"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                    >
                    <b-form-radio value="electrolux">Elektroluks</b-form-radio>
                    <b-form-radio value="seb">Seb</b-form-radio>                                                      
                    <b-form-radio value="franke">Franke</b-form-radio>   
                    <b-form-radio value="primus">Primus</b-form-radio>  
                    <b-form-radio value="opsti">Opsti</b-form-radio>                                                       
                    <b-form-radio value="prijava">Prijava</b-form-radio>   
                    <b-form-radio value="mka">mka</b-form-radio>   
                    </b-form-radio-group>
                </b-form-group>
            </b-col> 
            <b-col >
                <b-button variant="primary" block v-on:click="save()">Snimi podesavanja</b-button>
            </b-col>           
        </b-row> 
        <b-row>
            <b-col cols="6" class="mb-2 text-center">Lista</b-col>
            <b-col cols="6" class="mb-2 text-center">Aparati</b-col>
            <b-col cols="6" class="mb-2">
                 <draggable
                    :list="filteredAppliances"            
                    @start="dragging = true"
                    @end="dragging = false"
                    >
                    <div
                    class="list-group-item"
                    v-for="(element, index) in filteredAppliances"
                    :key="index"
                    >
                    <span v-on:click="removeEntry(element)">
                        <i class="fas fa-minus"></i>
                    </span> 
                    {{ element.aparat }}
                    </div>
                </draggable>
            </b-col>
            <b-col cols="6" class="mb-2">
                <ul class="lista">
                    <li v-for="(appliance, index) in allAppliances" :key="index">
                        <div
                    class="list-group-item">
                        <span v-on:click="addEntry(appliance)">
                            <i class="fas fa-plus"></i> {{ appliance.aparat }}
                        </span>   
                        </div>                     
                    </li>
                </ul>
            </b-col>
        </b-row>
        
    </b-container>
</template>
<script>
//import func from 'vue-editor-bridge';
import draggable from 'vuedraggable';

export default {
    components:{draggable},
    data(){
        return{
            aparat:'electrolux',
            allAppliances:[],
            filteredAppliances : [],
            allData : []
        }
    },
    watch:{
        aparat:function(){
            this.getAppliances();
            /*
            this.filteredAppliances = this.allData.filter((appliance) => {
                return appliance[this.aparat] != '';
            });
            setTimeout(()=>{
                this.filteredAppliances = this.lodash.sortBy(this.filteredAppliances, o => o[this.aparat]);
            }, 500);
            
            this.allAppliances = this.removeDuplicates();
            */
        }
    },
    methods:{
        getAppliances:function(){
            this.$http.get('/aparati/svi').then((response) => {                
				if(response.data.success == true){
					console.log(response.data.data)
                    this.allData = response.data.data;                    
                    this.filteredAppliances = response.data.data.filter((appliance) => {
                        return appliance[this.aparat] != '';
                    });
                    this.filteredAppliances = this.lodash.sortBy(this.filteredAppliances, o => o[this.aparat])
                    this.allAppliances = this.removeDuplicates();
				}
			}).catch((error) => {
				console.log(error)
			})
        },
        removeDuplicates:function(){
           let filteredIds = [];
           for(let i in this.filteredAppliances){
               filteredIds.push(this.filteredAppliances[i].id);
           }
           return this.allData.filter(item => {
               return !filteredIds.includes(item.id)
           })
        },
        addEntry:function(entry){            
            this.filteredAppliances.push(entry)  
            this.allAppliances = this.removeDuplicates();          
        },
        removeEntry:function(entry){
            for(let i in this.filteredAppliances){
                if(this.filteredAppliances[i] == entry){
                    this.filteredAppliances.splice(i, 1)
                }
            }
            this.allAppliances = this.removeDuplicates();            
        },
        save:function(){
            this.$http.post('/configs/appliances',{ appliance: this.aparat, data: this.filteredAppliances }).then((response) => {                
                if(response.data.success == true){                
                    this.$emit('showMessage', {message:'Podesavanje snimljeno', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'Doslo je do greske', color:'warning'})
            }); 
        }
    },
    created(){
        this.getAppliances();
    }
}
</script>