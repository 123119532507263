<template>
    <div class="container">
        <file-upload />
    </div>
</template>
<script>
import FileUpload from '../razno/FileUpload.vue';

export default {
    components:{
        FileUpload
    }
}
</script>