<template>
    <div>
        <b-row>
            <b-col cols="4" class="mb-2">                  
                <b-form-input v-model="inputValue" placeholder="Sifra dela" v-on:input="search"></b-form-input>     
                <div v-if="inputValue != '' && !itemFound" class="dropdown-list">
                    <div v-for="(item, index) in items" :key="index" class="dropdown-item" v-on:click="chosen(item)">        
                        {{ item.text }}
                    </div>
                </div>
            </b-col>
            <b-col cols="1" class="mb-2">
                <b-form-input v-model="deo.kolicina" placeholder="kol"></b-form-input>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-input v-model="deo.cena" placeholder="cena"></b-form-input>     
            </b-col>
            <b-col cols="3" class="mb-2">
                <b-form-input v-model="deo.napomena" placeholder="napomena"></b-form-input>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-button variant="primary" v-on:click="addToList()">Stavi u nalog</b-button>
            </b-col>
        </b-row> 
    </div>
</template>

<script>
  export default {
    name : 'add-part',
    data() {
      return {
          deo:{
            sifradela:'',
            naziv:'a',
            id:'',
            kolicina :'',
            cena : '',
            napomena : ''
          },
          inputValue: '',
          itemFound : false,
          items :[],
          errors :[]
      }
    },
    methods:{
        addToList:function(){   
            if(this.canAdd() === true){      
                console.log('moze')           
                this.$emit('partAdded', this.deo);
                setTimeout(() => {
                    this.deleteValues();
                }, 300);
            }
            else{
                for(let error of this.errors){
                    this.$emit('showMessage', {message: error, color:'warning'})
                }
            }            
        },
        deleteValues:function(){
            this.deo.sifradela = '';
            this.deo.kolicina = '';
            this.deo.cena = '';
            this.deo.napomena = '';
            this.inputValue = '';
        },
        canAdd:function(){
            console.log(this.deo.sifradela)
            if(this.deo.sifradela != '' && this.deo.kolicina > 0){
                return true;
            }
            else{
                this.errors = [];
                if(this.deo.sifradela == ''){
                    this.errors.push('Morate uneti sifru dela')
                }
                if(this.deo.kolicina == ''){
                    this.errors.push('Morate uneti kolicinu')
                }
                return false;
            }
        },
        search:function(){  
            this.itemFound = false;      
            this.$http.get('/delovi/nadjideo?term=' + this.inputValue).then((response) => {                
                if(response.data.success == true){
                    this.items = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        chosen:function(item){
            this.deo.sifradela = item.value;
            this.deo.naziv = item.text;
            this.deo.id = item.id;
            this.inputValue = item.text;
            this.itemFound = true;
        }  
    }
  }
</script>
<style scoped>
    .dropdown{
        position: relative;
        }
    .dropdown-list{
        position: absolute;
        background-color: white;
        border: 1px solid #e8e8e8;
        border-radius: 3px;
        z-index: 1000;
    }
</style>