<template>
    <b-container>        
        <b-row>
           
            <b-col cols="7" class="mb-2">
                <b-form-group label="" v-slot="{ ariaDescribedby2 }">
                    <b-form-radio-group
                        id="grupa"
                        v-model.lazy="grupa"
                        :aria-describedby="ariaDescribedby2"
                        name="radio-sub-component-2"
                    >
                        <b-form-radio value="1">Electrolux, Aeg, Zanussi</b-form-radio>
                        <b-form-radio value="2">Tefal, Krups, Rowenta</b-form-radio>
                        <b-form-radio value="3">Franke, Liebherr</b-form-radio>                                                      
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
       
        <div>
            <button class="btn btn-primary mb-2" @click="calculate">Obracunaj oznacene</button>
            <button class="btn btn-success ml-3 mb-2" @click="markAll">Oznaci sve</button>
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="navVerified.last_page" use-router></b-pagination-nav>
            <working-order-statuses-table :items="verified" @showMessage="showMessage"  @refreshList="refreshList" :status="status" ></working-order-statuses-table>
            <div v-if="!loading">
                <b-pagination-nav :link-gen="linkGen" :number-of-pages="navVerified.last_page" use-router></b-pagination-nav>
            </div>
        </div>
        
    </b-container>
</template>
<script>

import { mapFields } from 'vuex-map-fields';
//import WorkingOrdersProcesseedTable from './WorkingOrdersProcesseedTable.vue'
import WorkingOrderStatusesTable from './WorkingOrderStatusesTable.vue'
//import CalculatedWorkingOrderList from './CalculatedWorkingOrderList.vue';
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
//import SearchSelect from '../razno/SearchSelect.vue';

export default {
    props:['page'],
    components: { 
        WorkingOrderStatusesTable,
    //    SearchSelect,  
    //    PulseLoader, 
    //    CalculatedWorkingOrderList 
    },
    data(){
        return{    
            checkedValues:[],        
            status:1,
            grupa:1,
            locked:[],
            verified:[],            
            calculated :[],
            loading : false,
            color: this.$root.$options.globals.loader.color,
            size : this.$root.$options.globals.loader.size,
            navLocked:{},            
            navVerified: 1,
            services:[],
            reset : false,
            servis:null
        }
    },
    computed:{
        ...mapFields(['servisi']),
        pageNo:function(){
			if(this.page == null){
				return 1;
			}
			return this.page;
		}
    },
    watch:{
        status:function(newVal){
            console.log('status', newVal)
        },
        grupa:function(newVal){
            this.getVerified(newVal);
        },
        page:function(){
            console.log('page', this.page)
			this.getLocked(this.grupa);
		}
    },
    
    methods:{
        searchItem:function(item){        
            this.servis = item.value;            
            //this.inputServis = item.text;
        },
        
        checked:function(data){
            console.log('item, val', data.item, data.value)
            //this.checkedValues.push(id);
        },
        markAll:function(){
            let verified = this.verified;
        
            for(let i=0; i<verified.length;i++){
                verified[i].checkbox = 1;
            }
                   
            this.verified = verified;
            
        },
        linkGen(pageNum) {
			return pageNum === 1 ? '?' : `?page=${pageNum}`
		},
        showMessage:function(message){
			this.$emit('showMessage', message)
		},
        refreshList:function(){
            this.getVerified(this.grupa);
        },
        getVerified:function(group){
            this.$http.get('/radninalog/verifikovani?page=' + this.pageNo + '&group='+group).then((response) => {                               
                if(response.data.success == true){
                    console.log('verified', response.data.data.data)
                    let verified = JSON.parse(JSON.stringify(response.data.data.data));
                    for(let i=0;i<verified.length;i++){                        
                        verified[i]['checkbox'] = 0;                        
                    }
                    this.verified = verified;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        calculate:function(){
            console.log('checked', this.verified)
            this.$http.post('/radninalog/obracunaj',{ data: this.verified, group : this.grupa }).then((response) => {                
                if(response.data.success == true){
                    this.getVerified(this.grupa);
                    this.$emit('showMessage', {message:'Nalozi su obracunati', color:'success'})
                }
            }).catch((error) => {
                console.log(error);
                this.$emit('showMessage', {message:'Nalozi nisu obracunati', color:'warning'})
            });   
        }
        
    },
    mounted(){
       
        this.getVerified(1);
        
    }
}
</script>