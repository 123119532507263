<template>
    <b-container>        
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>
        <calculated-working-order-table :items="obracunati" :per="per"></calculated-working-order-table>
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>
    </b-container>
</template>
<script>
import CalculatedWorkingOrderTable from './CalculatedWorkingOrderTable.vue'

export default {
  components: { CalculatedWorkingOrderTable },
  name:'working-orders-by-params',
  props:['page', 'adm', 'servis', 'per'], //per - personal -> kad je samo za taj servis
  data(){
    return{
      obracunati : [],
      nav:{}
    }
  },
  computed:{
    pageNo:function(){
			if(this.page == null){
				return 1;
			}
			return this.page;
		},
  },
  watch:{
    servis:function(newVal){
      this.search(newVal)
    }
  },
  methods:{
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
      },
      search:function(){
        let user = true;
        if(this.adm != null && this.adm == true){
          user=false;
        }
        this.$http.get('/radninalog/obracunati?page=' + this.pageNo+ '&user=' + user + '&servis=' + this.servis + '&per=' + this.per).then((response) => {                                    
				if(response.data.success == true){					
          this.nav = response.data.data;
					this.obracunati = response.data.data.data;					
				}
			}).catch((error) => {
				console.log(error)
			})
      }
  },
  mounted(){
    
    this.search();
  }
    
}
</script>