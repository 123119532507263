<template>
  <div>
    <b-table striped hover :items="items" :fields="fields">
      <template #cell(details)="row">
        <div v-on:click="toggleModal('showOrderItemsModal', row)">
          <span class="blue">{{row.item.id}}</span>
        </div>        
      </template>
      <template #cell(actions)="row">        
        <div v-on:click="stornoPorudzbine(row)" v-if="admin">
        <b-button size="sm" variant="primary">Storno</b-button>     
      </div>
      </template>
    </b-table>
    <b-modal id="storno" title="Storno">
      <p class="my-4">Da li sigurno zelite da stornirate ovu porudzbinu!</p>
      <template #modal-footer="{}">          
        <b-button size="sm"  @click="cancel">
          Otkazi
        </b-button>
        <b-button size="sm" variant="primary" @click="stornoConfirmed">
          Storno
        </b-button>
      </template>
    </b-modal>
    <order-items-modal :showOrderItemsModal="showOrderItemsModal" :parts="parts" @toggleModal="toggleModal"></order-items-modal>
  </div>
</template>

<script>
import OrderItemsModal from '../modals/OrderItemsModal.vue'
import Helpers from '../utils/helpers';
  export default {
  components: { OrderItemsModal },
    props : ['items', 'admin'],
    name : 'order-parts-table',
    data() {
      return {
        row : {},
        showOrderItemsModal:false,
        parts : []       
      }
    },
    computed:{
      fields:function(){
        if(!this.admin){
			return [
				{
					key: 'details',
					label: 'Broj porudzbine',
					sortable: true
				},
				{
					key: 'kupac',
					label: 'Porucio',
					sortable: true
				},
				{
					key: 'datum',
					label: 'Datum',
					sortable: false
				},
				{ 	key: 'actions',
					label: '' 
				}
        ]
        }
        else{
          return [
					{
						key: 'details',
						label: 'Broj porudzbine',
						sortable: true
					},
					{
						key: 'kupac',
						label: 'Porucio',
						sortable: true
					},
					{
						key: 'datum',
						label: 'Datum',
						sortable: false
					},
          {
						key: 'preuzeta',
						label: 'Storno',
						sortable: false
					},
          {
						key: 'razlog',
						label: 'Razlog',
						sortable: false
					},
					{ 	  
            key: 'actions',
						label: '' 
					}
        ]
        }
      }
    },
    methods:{
		details:function(row){
			console.log(row)
		},
		cancel:function(){
			this.$bvModal.hide('storno');
		},
		stornoPorudzbine:function(row){        
			this.row = row;
			this.$bvModal.show('storno');
		},
		stornoConfirmed:function(){
			console.log(this.row)
			this.$emit('stornoPorudzbine', this.row);
			this.$bvModal.hide('storno');
		},
		toggleModal:function(property, row){
			this.orderDetails(row);
			switch(property){
				case 'showOrderItemsModal':
					//this.showOrderItemsModal = !this.showOrderItemsModal;
					this.$bvModal.show('orderItemsModal');
					break;          
			}    
		},
		orderDetails:function(row){
			this.$http.get('/porudzbine/stavke?' + Helpers.objectToParams({id:row.item.id}) ).then((response) => {                
				if(response.data.success == true){          
					this.parts = response.data.data
				}
			}).catch((error) => {
				console.log(error);				
			});
		}
    }
  }
</script>
<style scoped>
  .blue{
    color:blue;
	cursor: pointer;
  }
</style>