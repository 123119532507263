<template>
    <b-container>
        <add-part-returns @showMessage="showMessage" @partAdded="partAdded" :type="'return'"></add-part-returns>
        <parts-list :items="delovi" :type="'return'" @deletePart="deletePart"></parts-list>
        <div v-if="delovi.length > 0">
            <b-button block variant="primary" v-on:click="createReturn()">Snimi povratnicu</b-button>
        </div>
    </b-container>
</template>
<script>

import AddPartReturns from './AddPartReturns.vue'
import PartsList from './PartsList.vue'
export default {
    components: { PartsList, AddPartReturns },
    data(){
        return{
            delovi:[],
            editMode:false,
            povratnicaId:null
        }
    },
    methods:{
        showMessage:function(message){
            this.$emit('showMessage', message);
        },
        partAdded:function(deo){
            this.delovi.push({...deo})
        },
        deletePart:function(index){
            this.delovi.splice(index,1);
        },
        createReturn:function(){
            this.$http.post('/povratnice',{ data: this.delovi , edit : this.editMode, povratnicaId : this.povratnicaId}).then((response) => {                
            if(response.data.success == true){
                this.$router.push('/delovi/povratnice')                            
                this.showMessage({message: 'Povratnica snimljena delova', color:'success'});
            }
          }).catch((error) => {
              console.log(error);
              this.showMessage({message: 'Greska prilikom kreiranja povratnice delova', color:'success'});
          });             
        },
        returnData:function(){
            this.$http.get("/povratnica?id=" + this.$route.query.id)
			.then((response) => {
				if (response.data.success == true) {
                    console.log(response.data)
                    if(response.data.data.delovi != null){
                        this.delovi = response.data.data.delovi;
                    }
                    this.povratnicaId = this.$route.query.id;
				}
			})
			.catch((error) => {
				console.log(error);
			});
        }
    },
    mounted(){
        if(this.$route.query.id != null && this.$route.query.id != '' ){
            this.editMode = true;
            this.returnData();
        }
    }
}
</script>