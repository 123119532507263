import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios';
import store from './store/store';

Axios.defaults.baseURL = 'http://api.soca.rs/api';
//Axios.defaults.baseURL = 'http://localhost:8000/api';

Axios.defaults.headers = {
  "Authorization": 'Bearer ' + store.state.uid,
};

Axios.interceptors.request.use(
  (config) => {
    //let token = localStorage.getItem('authtoken');
    let token = store.state.uid;

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);

//Axios.defaults.baseURL = 'http://socaapi.loc/api';
Vue.prototype.$http = Axios;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueRouter from 'vue-router';
Vue.use(VueRouter);
import { routes } from './routes/routes';
const router = new VueRouter({
  routes:routes
});

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
Vue.component('pulse-loader', PulseLoader);

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret);
Vue.component('font-awesome-icon', FontAwesomeIcon)

import globals from './components/globals';



Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  globals
}).$mount('#app')
