<template>
    <b-container>
        <h2>Lista naloga u radu</h2>
        <working-order-table :nalozi="nalozi" @refreshList="refreshList" @showMessage="showMessage"></working-order-table>
        <div class="loader">
			<pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
		</div>
    </b-container>
</template>
<script>
import helpers from '../utils/helpers'
import WorkingOrderTable from './WorkingOrderTable.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    components: { WorkingOrderTable, PulseLoader},
    data(){
        return{
            loading:false,
            color : this.$root.$options.globals.loader.color,
            size : this.$root.$options.globals.loader.size,
            nalozi:[]
        }
    },
    methods:{
        showMessage:function(message){
			this.$emit('showMessage', message)
		},
        getWorkingOrders:function(){
            this.$http.get('/radninalog?' + helpers.objectToParams({status : [1,2,3,4,5,6,7]})).then((response) => {                
				if(response.data.success == true){					
					this.nalozi = response.data.data;
					this.loading = false;
				}
			}).catch((error) => {
				console.log(error)
			})
        },
        refreshList:function(){            
            this.getWorkingOrders();
        }
    },
    created(){
        this.getWorkingOrders();
        console.log(this.$root.$options.globals.loader.color)
    }
}
</script>