<template>
  <div>
    <b-table striped hover :items="items" :fields="fields">
		<template #cell(actions)="row">
			<div>
				<div>
					<router-link v-bind:to="{path: '/povratnica/view', query: { id: row.item.id }}">
						<i class="fas fa-eye mr-3"></i>
					</router-link>
					
					<router-link v-bind:to="{path: '/delovi/povratnice/izmeni', query: { id: row.item.povratnica_id, edit:true }}">
						<i class="fas fa-pen"></i> 
					</router-link>				
					
				</div>
			</div>			
		</template>	
	</b-table>
  </div>
</template>

<script>
  export default {
	props:['items'],  
    name : 'returns-table',
    data() {
      return {
        fields:[
			{
				key: 'povratnica_id',
				label: '#',
				sortable: false
			},
			{
				key: 'kovraca',
				label: 'Od koga',
				sortable: true
			},
			{
				key: 'komevraca',
				label: 'Kome',
				sortable: true
			},
			{
				key: 'datum',
				label: 'Datum',
				sortable: false
			},
			{ 
				key: 'actions',
				label: '' 
			}
        ]
      }
    }
  }
</script>