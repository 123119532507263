<template>
    <div>
        <b-modal id="memoModal" :title="placeholder" size="lg">
            <b-form-textarea
                id="textarea-default"
                :placeholder="placeholder"
                v-model.lazy="memo"
            ></b-form-textarea>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContent()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>

export default {    
    props :{
        row :{
            type :Object,
            default : ()=>{}
        },
        action:{
            type:String,
            default:''
        }
    },
    name:'add-memo-modal',
    data(){
        return{
            memo:''
        }
    },
    computed:{
        placeholder:function(){
            let text = 'Unesite razlog storniranja';
            if(this.action == 'napomena'){
                text = 'Unesite napomenu';
            }
            if(this.action == 'dorada'){
                text = 'Unesite napomenu za doradu';
            }
            return text;
        }
    },
    methods:{
        closeModal:function(){            
            this.memo = '';
            this.$bvModal.hide('memoModal');
        },
        saveContent:function(){
            this.$emit('saveContent', {data: this.memo, rowData:this.row, action:this.action});
            this.memo = '';
            this.$bvModal.hide('memoModal');           
        }
    }
}
</script>