<template>
    <b-container>
        <h2>Pregled magacina</h2>
        <warehouse-table :items="magacin" :admin="admin" @search="search"></warehouse-table>
        <div class="loader">
			<pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
		</div>
    </b-container>
</template>

<script>
    import helpers from '../utils/helpers';
    import WarehouseTable from './WarehouseTable';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    
    export default {
        props : ['model'],
        components: { WarehouseTable, PulseLoader },
        name : 'warehouse',
        data() {
            return {
                magacin : [],
                admin : false,
                color:this.$root.$options.globals.loader.color,
                size:this.$root.$options.globals.loader.size,
                loading:false
            }
        },
        methods:{
            getWarehouseData:function(filter){  
                this.loading = true;              
                this.$http.get('/magacin?' + helpers.objectToParams(filter)).then((response) => {                
                    if(response.data.success == true){
                        this.loading = false;
                        this.magacin = response.data.data;
                    }
                }).catch((error) => {
                    this.loading = false;
                    console.log(error)
                })
            },
            search:function(filter){
                this.getWarehouseData(filter);
            }
        },
        mounted(){
            let usersOnly = false;
            if(this.$route.query.model != null && this.$route.query.model == 0){
                
                usersOnly = true;
            }
            console.log('warehouse', this.$route.query.model != null, this.$route.query.model == 0, usersOnly)
            this.getWarehouseData({sifradela:'%', naziv:'%' , usersOnly: usersOnly});
        },
        watch:{
            model:function(newVal){
                console.log(newVal)
                if(newVal == 1){
                    this.admin = true;
                }
                else{
                    this.admin = false;
                }
            }
        }
  }
</script>