<template>
  <div>
    <b-row>
      <b-col cols="3" class="mb-2" v-if="$route.query.model == 1">        
        <search-select :itemList="services" :reset="reset" :placeholder="'servis'" @searchItem="searchItem"></search-select>                      
      </b-col>
      <b-col cols="3" class="mb-2">
        <b-form-input v-model.lazy="filter.sifradela" placeholder="sifra dela"></b-form-input>                      
      </b-col>
      <b-col cols="3" class="mb-2">
        <b-form-input v-model.lazy="filter.nazivdela" placeholder="naziv dela"></b-form-input>                      
      </b-col>
      <b-col cols="2" class="mb-2">
        <i class="fas fa-search blue s-icons" v-on:click="searchWarehouse"></i>     
        <i class="fas fa-trash-alt red s-icons" v-on:click="deleteFilter"></i>
      </b-col>
    </b-row>
    <b-table striped hover :items="items" :fields="fields"></b-table>
  </div>
</template>

<script>
import SearchSelect from '../razno/SearchSelect.vue';
  export default {
  components: { SearchSelect },
    props: ['items', 'admin'],
    name : 'warehouse-table',
    data() {
      return {
        filter:{
          sifradela:'',
          nazivdela:'',
          servis : '',          
        },
        reset:false,
        services:[],
        fields:[
          {
						key: 'sifradela',
						label: 'Sifra dela',
						sortable: true
					},
					{
						key: 'naziv',
						label: 'Naziv dela',
						sortable: true
					},
					{
						key: 'kol',
						label: 'Kolicina',
						sortable: false
					},
          {
						key: 'servis.name',
						label: '',
						sortable: false
					},
					{ 	key: 'actions',
						label: '' 
					}
        ]
        
      }
    },
    methods:{
      deleteFilter:function(){
        this.filter.sifradela = '';
        this.filter.nazivdela = '';
        this.filter.servis = '';
        this.reset = true;
        this.searchWarehouse();
        setTimeout(()=>{this.reset = false;},500)
      },
      searchWarehouse:function(){        
        this.$emit('search', this.filter);
      },
      searchItem:function(item){        
        this.filter.servis = item.value;
        this.inputServis = item.text;
      }
    },
    created(){
      this.$http.get('/korisnici/servis').then((response) => {                
        if(response.data.success == true){
            this.services = response.data.data;
        }
      }).catch((error) => {
          console.log(error)
      })
    }
  }
</script>