<template>
  <div id="app">
    <div v-if="uid === '' || uid === null">
      <login></login>
    </div>
    <div v-else>
      <navbar></navbar>
      <div class="container-wrapper">
        <div style="padding-top: 70px">
          <router-view @showMessage="showMessage"  :key="$route.fullPath" class="app-font"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./components/Login.vue";
import Navbar from "./components/navbar.vue";
import { mapFields } from "vuex-map-fields";
import WorkingOrderModule from './store/modules/workingorderdata';

export default {
  name: "App",
  components: {
    Navbar,
    Login,
  },
  computed: {
    ...mapFields(["user","uid",  "alid"]),    
  },
  methods: {
    popToast: function (message, color) {
      this.$bvToast.toast(message, {
        toastClass: color,
        noCloseButton: true,
      });
    },
    showMessage: function (popUp) {
      this.popToast(popUp.message, popUp.color);
    },
  },
  mounted(){
    this.$store.registerModule('radniNalog', WorkingOrderModule);
  }
};
</script>

<style>
.app-font {
  font-size: 14px;
}
* {
  border-radius: 0 !important;
}

.container-wrapper {
  background-color: white;
}
.container {
  background-color: white !important;
}

.toast {
  opacity: 1 !important;
}

#toast-container > div {
  opacity: 1 !important;
}
</style>
