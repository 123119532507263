<template>
    <div>
        <b-modal id="addBussiness" title="Poslovni partner" size="lg">    
            <b-row>
                <b-col cols="12" class="mb-2">
                    <b-form-input v-model.lazy="naziv" placeholder="Naziv"></b-form-input>                    
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-form-input v-model.lazy="adresa" placeholder="Adresa"></b-form-input>                         
                </b-col>
                <b-col cols="2" class="mb-2">
                    <b-form-input v-model.lazy="ptt" placeholder="Ptt"></b-form-input>                      
                </b-col>
                <b-col cols="4" class="mb-2">
                    <b-form-input v-model.lazy="mesto" placeholder="Mesto"></b-form-input>                      
                </b-col>
            </b-row> 
            <b-row>
                <b-col cols="6" class="mb-2">
                    <b-row>
                        <b-col cols="10">
                            <b-form-input v-model.lazy="telefon" placeholder="Telefon"></b-form-input>                      
                        </b-col>
                        <b-col>
                            <i class="fas fa-plus s-icons-form"></i>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <b-row>
                        <b-col cols="10">
                            <b-form-input v-model.lazy="email" placeholder="email"></b-form-input>                      
                        </b-col>
                        <b-col>
                            <i class="fas fa-plus s-icons-form"></i>
                        </b-col>
                    </b-row>
                    
                </b-col>
            </b-row>       
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveContact()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>


export default {
    props :[],
    name:'add-business-modal',
    data(){
        return{
            naziv : '',
            adresa : '',
            ptt : '',
            mesto : '',
            telefon :'',            
            email :'',
            pib:'',
            mbr:'',
            kontakt:''
        }

    },
    methods:{
        closeModal:function(){            
            this.$bvModal.hide('addBussiness');
        },
        saveContact:function(){            
            this.$bvModal.hide('addBussiness');
        }
    },
    onDestroy(){
        console.log('destroy')
    }
}
</script>