<template>
    <b-container>
        <h2>Pregled povratnica</h2>
        <b-button variant="primary" class="mb-2" v-on:click="createReturn()">Kreiraj povratnicu</b-button>
        <returns-table :items="returns"></returns-table>
    </b-container>
</template>

<script>
    import ReturnsTable from './ReturnsTable';
    export default {
    components: { ReturnsTable },
        name : 'returns',
        data() {
        return {
            returns:[]
        }
    },
    methods:{
        createReturn:function(){
            this.$router.push('/delovi/povratnice/kreiraj')
        },
        getReturns:function(){
            this.$http.get('/povratnice').then((response) => {                
                if(response.data.success == true){
                    this.returns = response.data.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    created(){
        this.getReturns();
    }
  }
</script>