<template>
	<b-container>
		<working-order-filter @search="search" :filterValues="filter" :showServis="showServis"></working-order-filter>		
		<b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>
		<working-order-table :nalozi="nalozi" :all="true" @showMessage="showMessage" @refreshList="refreshList"></working-order-table>
		<div class="loader">
			<pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
		</div>
		<div v-if="!loading">
			<b-pagination-nav :link-gen="linkGen" :number-of-pages="this.nav.last_page" use-router></b-pagination-nav>
		</div>	
	</b-container>
</template>
<script>
import WorkingOrderTable from './WorkingOrderTable.vue'
import WorkingOrderFilter from './WorkingOrderFilter';
import helpers from '../utils/helpers';
import {mapFields} from 'vuex-map-fields';

export default {
	props:['page', 'user'],
	components: { WorkingOrderTable, WorkingOrderFilter },
	name:'working-orders-by-params',
	data(){
		return{
			nalozi:[],
			loading:false,
			size:'20px',
			color : '#a40202',			
			nav : {},
			filter:{  
				group:'',
				aparat:'',
				status:'',
				searchText:'',
				servis:'',
				parametar:''
			},
			showServis : true
		}
	},
	methods:{
		linkGen(pageNum) {
			return pageNum === 1 ? '?' : `?page=${pageNum}`
		},
		showMessage:function(message){
			this.$emit('showMessage', message)
		},
		refreshList:function(refresh){
			if(refresh == true){
				this.search(this.filter);
			}
		},
		search:function(filter){
			this.filter = filter;
			this.loading = true;
			this.nalozi = [];			
			this.$http.get('/radninalog/filter?page=' + this.pageNo + '&' + helpers.objectToParams(filter) + '&userfilter=' + !this.showServis).then((response) => {                
				if(response.data.success == true){
					this.nav = response.data.data
					this.nalozi = response.data.data.data;
					this.loading = false;
				}
			}).catch((error) => {
				console.log(error);
				this.$emit('showMessage', {message : 'Doslo je do greske!',  color:'warning'} )
				this.loading =false;
			})
		}
	},
	computed:{
		pageNo:function(){
			if(this.page == null){
				return 1;
			}
			return this.page;
		},
		...mapFields(['adminNalogFilter'])
	},
	watch:{
		page:function(){
			this.search(this.filter);
		}
	},
	created(){
		
		
		
	},
	mounted(){
		if(this.$route.query.load != null && this.$route.query.load == 1){
			this.filter = this.adminNalogFilter;
			this.search(this.filter);
			console.log('from if', this.filter)
			
		}
		else{
			this.search({});
		}	
		this.showServis = true;
		console.log('all params', this.$route.query.user)
		if(this.$route.query.user != null && this.$route.query.user == 'true'){
			this.showServis = false;
		}	
		
	}
	
}
</script>
<style>
	.loader{
		text-align: center;
		margin-top: 50px;
	}

</style>