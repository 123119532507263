<template>
  <div class="orders-table">
    <b-table striped hover :items="nalozi" :fields="fields">
      <template #cell(rb)="row">
        <router-link :to="{path: '/radninalog', query: { group: row.item.grupabrend, idn: row.item.id, ret: retVal }}">
          {{row.item.id}}
        </router-link>        
      </template>
      <template #cell(actions)="row">                 
          <b-dropdown   variant="link" toggle-class="text-decoration-none" class="soca-settings-wheel" no-caret>
            <template #button-content>
              <i class="fas fa-cog settings"></i>
            </template>            
              <b-dropdown-item href="#">
                <router-link v-bind:to="{path: '/radninalog',
                    query: { group: row.item.grupabrend, idn: row.item.id, ret: retVal }}">
                    Izmeni nalog
                </router-link>
              </b-dropdown-item>             
              <b-dropdown-item href="#" >
                <a href="#" v-on:click="showAddPartModal(row)">
                  Poruci delove
                </a>  
              </b-dropdown-item>              
              <b-dropdown-item href="#">Prikazi PDF</b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" v-on:click="showAddFilesModal(row)">
                  Dodaj slike
                </a>
              </b-dropdown-item>
              <div v-if="!all">               
                
                <b-dropdown-item href="#">
                  <a href="#" v-on:click="lockWorkingOrder(row)">
                    Zakljuci nalog
                  </a>
                </b-dropdown-item>
                
                <b-dropdown-item href="#">
                  <a href="#" v-on:click="replacementOrder(row)">
                    Zahtev za zamenu
                  </a>                
                </b-dropdown-item>              
              </div>
              <b-dropdown-item href="#">
                <a href="#" v-on:click="showAddMemoModal(row, 'storno')">
                  Storniraj nalog
                </a>
              </b-dropdown-item>
                
              <div v-if="admin">
              <b-dropdown-item href="#">
                <a href="#" v-on:click="showAddMemoModal(row, 'napomena')">
                  Napomena
                </a>
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" v-on:click="showAddMemoModal(row, 'dorada')">
                  Potrebna dorada
                </a>
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" @click="replacementOrderAction(row, true)">Odobrena zamena</a>                  
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" @click="replacementOrderAction(row, false)">Odbijena zamena</a>                  
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" @click="historyAction(row)">Istorija naloga</a>
              </b-dropdown-item>       
              <b-dropdown-item href="#">
                <a href="#" @click="changeGroupAction(row)">Promeni grupu naloga</a>                  
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <a href="#" @click="changeServiceAction(row)">Promeni servis</a>                  
              </b-dropdown-item>                       
            </div>  
          </b-dropdown>       
        
      </template>
      
    </b-table>
    <add-part-modal :row="this.row" ></add-part-modal>
    <add-files-modal :row="this.row"></add-files-modal>
    <add-memo-modal  :row="this.row" :action="action" @saveContent="saveContent"></add-memo-modal> 
    <change-group-modal :row="this.row" @saveContent="saveGroup"></change-group-modal>
    <change-service-modal :row="this.row" @saveContent="saveService"></change-service-modal>
    <WOHistoryModal :id="this.row.item.id"></WOHistoryModal>
  </div>
</template>

<script>
import AddFilesModal from '../modals/AddFilesModal.vue';
import AddPartModal from '../modals/AddPartModal.vue'
import AddMemoModal from '../modals/AddMemoModal.vue';
import ChangeGroupModal from '../modals/ChangeGroupModal.vue';
import ChangeServiceModal from '../modals/ChangeServiceModal.vue';
import WOHistoryModal from '../modals/WOHistoryModal.vue';
import { mapFields } from 'vuex-map-fields';

  export default {
  components: { AddPartModal, AddFilesModal, AddMemoModal, ChangeGroupModal, ChangeServiceModal, WOHistoryModal },
    name : 'working-order-table',
    props:{      
      nalozi:{
        type:Array,
        default : () => []
      },
      all:{type:Boolean, default:false}
    },
    data() {
      return {          
            row : {item:{id:''}},
            action : '',
            fields: [
            {
                key: 'rb',
                label :'Br'
            },
            {
                key: 'rbnaloga',
                label: 'Br. naloga',
                sortable: true
            },
            {
                key: 'imeiprez',
                label: 'Ime i prezime',
                sortable: true
            },
            {
                key: 'adresa',                
                label: 'Adresa',
                sortable: true
            },
            {
                key: 'aparat',
                label: 'Aparat',
                sortable: true
            },
            {
                key: 'model',
                label: 'Model',
                sortable: false
            },
            {
                key: 'brend',
                label: 'Brend',
                sortable: true
            },
            {
                key: 'status',
                label: 'Status',
                sortable: true
            },
            {
                key: 'napomena',
                label: 'Napomena',
                sortable: true
            },
            {
                key: 'actions',
                label :''
            }
            ],
            
      }
    },
    methods:{
      showAddFilesModal:function(row){        
        this.row = row;
        this.$bvModal.show('addFiles')
      },
      showAddPartModal:function(row){        
        this.row = row;
        this.$bvModal.show('addPart')
      },
      showAddMemoModal:function(row, action = ''){        
        this.row = row;
        this.action = action;
        this.$bvModal.show('memoModal')
      },
      closeModal:function(modal){
        console.log('modal', modal)
        this[modal] = false;
      },
      lockWorkingOrder:async function(row){
        this.$http.post('/radninalog/lock-order',{ id : row.item.id }).then(async (response) => {                
          if(response.data.success == true){            
            await this.changeStatus(row, 8,  'Nalog je uspesno zakljucen');
            this.$emit('refreshList');
          }
          else{
            for(let i in response.data.data){
              this.$emit('showMessage', {message:'Nadostaje podatak : ' + response.data.data[i], color:'warning'})
            }
          }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message:'Nalog nije mogao biti zakljucen', color:'warning'})
        }); 
        
        //this.$emit('showMessage', {message : 'Nalog je uspesno zakljucen',  color:'success'} )
      },
      replacementOrder:async function(row){
        console.log(row)
        await this.changeStatus(row, 5,'Poslat je nalog za zamenu' )
        //this.$emit('showMessage', {message : 'Poslat je nalog za zamenu',  color:'success'} )
      },
      findText:function(array, id){        
        for(let i in array){           
          if(array[i].value != '' && array[i].value == id){
            return array[i].text;
          }
        }
        return '';
      },
      changeStatus:async function(row, status, message){
        this.$http.post('/radninalog', { id: row.item.id, params:{status:status} }).then((response) => {                
          if(response.data.success == true || response.success == true){          
            this.$emit('refreshList', true);                              
            this.$emit('showMessage', {message:message, color:'success'})
          }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message: 'status nije promenjen', color:'warning'})
        });
      },
      stornoNaloga:function(data){
        this.$http.post('/radninalog/storniraj', { id: data.rowData.item.id, message : data.data }).then((response) => {                
          if(response.data.success == true || response.success == true){          
            console.log(response.data)
            this.$emit('refreshList', true); 
          }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message:'Nalog nije storniran', color:'warning'})
        }); 
      },
      napomenaNaloga:function(data){
        console.log(data)        
        this.$http.post('/radninalog/napomena', { id: data.rowData.item.id, message : data.data }).then((response) => {                
          if(response.data.success == true || response.success == true){          
            console.log(response.data)
            this.$emit('showMessage', {message:'Napomena snimljena', color:'warning'})
            this.$emit('refreshList', true); 
          }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message:'Napomena nije snimljena', color:'warning'})
        });
      },
      doradaNaloga:function(data){        
        this.$http.patch('/radninalog/rework', { id: data.rowData.item.id, message : data.data }).then((response) => {                            
            if(response.data.success == true){                                    
              this.$emit('refreshList', true); 
              this.$emit('showMessage', {message:'Izmena snimljena', color:'warning'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Izmena nije snimljena', color:'warning'})
          });
        },
      saveContent:function(data){       
        if(data.action == 'storno'){
          this.stornoNaloga(data);
        } 
        else if(data.action == 'napomena'){
          this.napomenaNaloga(data);
        }
        else if(data.action == 'dorada'){
          this.doradaNaloga(data);
        }        
      },
      replacementOrderAction:async function(row, action){
        if(action == true){          
          await this.changeStatus(row, 6, 'Zamena odobrena')  
          this.$emit('refreshList', true);        
        }
        else{
          await this.changeStatus(row, 7, 'Zamena odbijena')
          this.$emit('refreshList', true);
        }
      },
      changeGroupAction:function(row){
        this.row = row;
        this.$bvModal.show('groupModal')
      },
      saveGroup:function(data){
        console.log(data)
        this.$http.post('/radninalog', { id: data.rowData.item.id, params:{grupabrend:data.data} }).then((response) => {                
        if(response.data.success == true){          
          this.$emit('refreshList', true);                              
          this.$emit('showMessage', {message: 'Grupa je promenjena' , color:'success'})
        }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message: 'Grupa nije promenjena', color:'warning'})
        });
      },
      changeServiceAction:function(row){
        this.row = row;
        this.$bvModal.show('serviceModal')
      },
      saveService:function(data){
        console.log(data)
        this.$http.post('/radninalog', { id: data.rowData.item.id, params:{iduser:data.data} }).then((response) => {                
        if(response.data.success == true){          
          this.$emit('refreshList', true);                              
          this.$emit('showMessage', {message: 'Servis je promenjen' , color:'success'})
        }
        }).catch((error) => {
            console.log(error);
            this.$emit('showMessage', {message: 'Servis nije promenjen', color:'warning'})
        });
      },
      historyAction:function(row){
        this.row = row;
        this.$bvModal.show('historyModal')
      }
    },
    computed:{
      ...mapFields(['radniNalozi', 'aparati', 'brendovi', 'workingOrderStatuses']),
      admin:function(){
        if(this.$store.state.alid == '44c2d9d87a62acfee636b95df766ce26'){
          return true
        }        
        return false;
      }   ,
      retVal:function(){
        if(this.$route.query.ret != null & this.$route.query.ret != ''){
          return this.$route.query.ret;
        }
        if(this.$route.path == '/radninalog/svi'){
          return 'admsvi'
        }
        console.log(this.$route.path == '/radninalog/svi')
        return 'lista';
      }  
    }
  }
</script>
<style>
    .settings{
      font-size: 25px;
    }
    .link{
      color: blue;
    }
    .soca-settings-wheel .btn{
      padding: 0px !important;
    }
    
</style>