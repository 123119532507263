var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{staticClass:"s-navbar",attrs:{"toggleable":"lg","type":"dark","fixed":"top"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_vm._v(" data.soca ")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(_vm.vm[1] == '1' || _vm.vm[2] == '1' || _vm.vm[3] == '1' || _vm.vm[4] == '1' || _vm.vm[5] == '1' )?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Nalozi"}},[(_vm.vm[1] == '1')?_c('b-dropdown-group',{attrs:{"id":"nalozi","header":"Otvori nalog"}},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog',
                            query: { group: 1, type : 0 }}}},[_vm._v(" Electrolux, AEG, Zanussi ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog',
                            query: { group: 2, type : 0 }}}},[_vm._v(" Tefal, Krups, Rowenta ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog',
                            query: { group: 4, type : 0 }}}},[_vm._v(" Opsti ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog',
                            query: { group:11, type : 0 }}}},[_vm._v(" Soca nalog ")])],1)],1):_vm._e(),_c('b-dropdown-divider'),(_vm.vm[2] == '1')?_c('b-dropdown-group',{attrs:{"id":"nalozi2","header":"Stampa praznog naloga"}},[_c('b-dropdown-item',{attrs:{"href":"https://www.soca.rs/download/ELXRegulusBlankoNalog.pdf"}},[_c('b-link',{attrs:{"href":"https://www.soca.rs/download/ELXRegulusBlankoNalog.pdf","target":"blank"}},[_vm._v(" Electrolux, AEG, Zanussi ")])],1),_c('b-dropdown-item',{attrs:{"href":"https://www.soca.rs/download/ServisniNalogSebWeb.pdf"}},[_c('b-link',{attrs:{"href":"https://www.soca.rs/download/ServisniNalogSebWeb.pdf","target":"blank"}},[_vm._v(" Tefal, Krups, Rowenta ")])],1),_c('b-dropdown-item',{attrs:{"href":"https://www.soca.rs/download/SocaWebServnalogGEMMA.pdf"}},[_c('b-link',{attrs:{"href":"https://www.soca.rs/download/SocaWebServnalogGEMMA.pdf","target":"blank"}},[_vm._v(" Opsti ")])],1),_c('b-dropdown-item',{attrs:{"href":"https://www.soca.rs/download/SocaWebServnalogOPSTI.pdf"}},[_c('b-link',{attrs:{"href":"https://www.soca.rs/download/SocaWebServnalogOPSTI.pdf","target":"blank"}},[_vm._v(" Van garancije ")])],1)],1):_vm._e(),_c('b-dropdown-divider'),(_vm.vm[16] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/all',
                        query: { }}}},[_vm._v(" Soca nalozi ")])],1):_vm._e(),(_vm.vm[3] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/lista',
                        query: { }}}},[_vm._v(" Lista naloga u radu ")])],1):_vm._e(),(_vm.vm[4] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/lista-parametri',
                        query: { user : true }}}},[_vm._v(" Pregled naloga po parametrima ")])],1):_vm._e(),(_vm.vm[5] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/obracuni-naloga',
                        query: { per : true}}}},[_vm._v(" Obracuni naloga ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.vm[7] == '1' || _vm.vm[8] == '1' || _vm.vm[9] == '1' || _vm.vm[10] == '1' || _vm.vm[11] == '1' )?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Delovi"}},[(_vm.vm[6] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/korpa',
                        query: { }}}},[_vm._v(" Sadrzaj korpe ")])],1):_vm._e(),(_vm.vm[7] == '1' || _vm.vm[8] == '1' || _vm.vm[9] == '1' )?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/porudzbine',
                        query: { group: 1, type : 0 }}}},[_vm._v(" Pregled porudzbina ")])],1):_vm._e(),(_vm.vm[10] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/magacin',
                        query: { model:0 }}}},[_vm._v(" Pregled magacina ")])],1):_vm._e(),(_vm.vm[11] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/povratnice',
                        query: { group: 1, type : 0 }}}},[_vm._v(" Povratnice delova ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.groups.includes('1'))?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Electrolux"}},_vm._l((_vm.elxMenu),function(entry,index){return _c('b-dropdown-item',{key:index,attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/pdf',
                        query: { id:entry.id }}}},[_vm._v(" "+_vm._s(_vm.pdfNaziv(entry.naziv))+" ")])],1)}),1)],1):_vm._e(),(_vm.groups.includes('2'))?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"SEB"}},_vm._l((_vm.sebMenu),function(entry,index){return _c('b-dropdown-item',{key:index,attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/pdf',
                        query: { id:entry.id}}}},[_vm._v(" "+_vm._s(_vm.pdfNaziv(entry.naziv))+" ")])],1)}),1)],1):_vm._e(),(_vm.groups.includes('3'))?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Franke/Liebherr"}},_vm._l((_vm.frankeMenu),function(entry,index){return _c('b-dropdown-item',{key:index,attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/pdf',
                        query: { id:entry.id }}}},[_vm._v(" "+_vm._s(_vm.pdfNaziv(entry.naziv))+" ")])],1)}),1)],1):_vm._e(),(_vm.alid == '44c2d9d87a62acfee636b95df766ce26' && (_vm.vm[12] == '1' || _vm.vm[13] == '1' || _vm.vm[14] == '1' || _vm.vm[15] == '1' || _vm.vm[17] == '1' || _vm.vm[23] == '1'))?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Admin"}},[(_vm.vm[12] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/svi',
                        query: { }}}},[_vm._v(" Svi radni nalozi ")])],1):_vm._e(),(_vm.vm[13] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/zakljuceni',
                        query: { }}}},[_vm._v(" Radni nalozi - zakljuceni ")])],1):_vm._e(),(_vm.vm[13] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/verifikovani',
                        query: { }}}},[_vm._v(" Radni nalozi - verifikovani ")])],1):_vm._e(),(_vm.vm[13] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/obracunati',
                        query: { }}}},[_vm._v(" Radni nalozi - obracunati ")])],1):_vm._e(),(_vm.vm[14] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/radninalog/online',
                        query: { }}}},[_vm._v(" Otvoreni nalozi online ")])],1):_vm._e(),_c('b-dropdown-divider'),(_vm.vm[17] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/porudzbine/pregled',
                        query: { }}}},[_vm._v(" Pregled svih porudzbina ")])],1):_vm._e(),(_vm.vm[17] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/poruci-za-partnera',
                        query: { }}}},[_vm._v(" Poruci deo za Partnera ")])],1):_vm._e(),(_vm.vm[17] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/korpe',
                        query: { }}}},[_vm._v(" Stanje korpi ")])],1):_vm._e(),(_vm.vm[17] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/delovi/magacini',
                        query: { model : 1 }}}},[_vm._v(" Stanje magacina ")])],1):_vm._e(),_c('b-dropdown-divider'),(_vm.vm[23] == '1')?_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/upload-cenovnik',
                        query: { }}}},[_vm._v(" Obnovi cenovnik ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.alid == '44c2d9d87a62acfee636b95df766ce26')?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Konfiguracija"}},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/opcije',
                        query: { }}}},[_vm._v(" Opcije ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/aparati',
                        query: { }}}},[_vm._v(" Aparati ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/naloga',
                        query: { }}}},[_vm._v(" Naloga ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/meni',
                        query: { }}}},[_vm._v(" Meni ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/pristupa',
                        query: { }}}},[_vm._v(" Pristupa ")])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{attrs:{"to":{path: '/konfiguracija/korisnici',
                        query: { }}}},[_vm._v(" Korisnici ")])],1)],1)],1):_vm._e(),(_vm.alid == '1b63a771bd7f09244c493450df0c8a72')?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":"Servis"}})],1):_vm._e(),_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"href":"https://www.soca.rs/pdf/UputstvoDataSocaServisi.pdf","target":"blank"}},[_vm._v("Uputstvo")])],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('em',[_vm._v("User")])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Profile")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.logOut()}}},[_vm._v("Sign Out")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }