<template>
  <div>
    <b-table striped hover :items="items" :fields="fields" >
		<template #cell(actions)="row">
			<div>
				<div v-on:click="deleteItem(row)">
					<i class="fas fa-trash red"></i>       
				</div>
			</div>			
		</template>	
    </b-table>
    
    <b-modal id="delete" title="Brisanje">
      <p class="my-4">Da li sigurno zelite da obrisete ovaj deo!</p>
      <template #modal-footer="{}">      
        <b-button size="sm"  @click="cancel()">
          Otkazi
        </b-button>
        <b-button size="sm" variant="primary" @click="deleteConfirmed">
          Obrisi
        </b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
  export default {
    props:['items', 'type'],
    name : 'parts-list',
    data() {
		return {
			row : '',
			
		}
    },
    computed:{
		fields:function(){
			if(this.type == 'parts'){
				return [				  
					{
						key: 'sifradela',
						label: 'Sifra dela',
						sortable: true
					},
					{
						key: 'naziv',
						label: 'Naziv dela',
						sortable: true
					},
					{
						key: 'kolicina',
						label: 'Kolicina',
						sortable: true
					},
					{
						key: 'cena',
						label: 'Cena',
						sortable: true
					},
					{
						key: 'napomena',
						label: 'Napomena',
						sortable: false
					},
					{ 	key: 'actions',
						label: '' 
					}
				]
			}
			else if(this.type == 'return'){
				return [				  
					{
						key: 'sifra',
						label: 'Sifra dela',
						sortable: true
					},
					{
						key: 'naziv',
						label: 'Naziv dela',
						sortable: true
					},
					{
						key: 'kolicina',
						label: 'Kolicina',
						sortable: true
					},
					{
						key: 'cena',
						label: 'Cena',
						sortable: true
					},
					{
						key: 'napomena',
						label: 'Napomena',
						sortable: false
					},
					{ 	key: 'actions',
						label: '' 
					}
				]
			}
			else if(this.type == 'basket'){
			return  [
				{
					key: 'sifradela',
					label: 'Sifra dela',
					sortable: true
				},
				{
					key: 'naziv',
					label: 'Naziv dela',
					sortable: true
				},
				{
					key: 'kolicina',
					label: 'Kolicina',
					sortable: true
				},
				{
					key: 'napomena',
					label: 'Napomena',
					sortable: false
				},
				{ key: 'actions', label: '' }
			]			
			}
			else if(this.type == 'basketAll'){
			return  [
				{
					key: 'sifradela',
					label: 'Sifra dela',
					sortable: true
				},
				{
					key: 'naziv',
					label: 'Naziv dela',
					sortable: true
				},
				{
					key: 'kolicina',
					label: 'Kolicina',
					sortable: true
				},
				{
					key: 'napomena',
					label: 'Napomena',
					sortable: false
				},
				{
					key: 'servis.name',
					label: 'Servis',
					sortable: true
				},
				{ key: 'actions', label: '' }
			]
			}
			return [];
      }
    },
    methods:{
      deleteItem:function(row){
        this.row = row;
        this.$bvModal.show('delete');        
      },
      deleteConfirmed:function(){        
        this.$emit('deletePart', this.row.index);
        this.$bvModal.hide('delete');
      }
    }
  }
</script>
<style >
    .red{
      color : red;
    }
</style>