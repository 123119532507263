<template>
    <div>
         <b-navbar toggleable="lg" type="dark" fixed="top" class="s-navbar">
            <b-navbar-brand to="/">
                data.soca               
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="vm[1] == '1' || vm[2] == '1' || vm[3] == '1' || vm[4] == '1' || vm[5] == '1' ">  
                <b-nav-item-dropdown text="Nalozi">
                    <b-dropdown-group id="nalozi" header="Otvori nalog" v-if="vm[1] == '1'">
                        <b-dropdown-item href="#">
                            <router-link v-bind:to="{path: '/radninalog',
                                query: { group: 1, type : 0 }}">
                                Electrolux, AEG, Zanussi
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item href="#">
                            <router-link v-bind:to="{path: '/radninalog',
                                query: { group: 2, type : 0 }}">
                                Tefal, Krups, Rowenta
                            </router-link>                            
                        </b-dropdown-item>
                        <b-dropdown-item href="#">
                            <router-link v-bind:to="{path: '/radninalog',
                                query: { group: 4, type : 0 }}">
                                Opsti
                            </router-link> 
                        </b-dropdown-item>
                        <b-dropdown-item href="#">
                            <router-link v-bind:to="{path: '/radninalog',
                                query: { group:11, type : 0 }}">
                                Soca nalog
                            </router-link> 
                        </b-dropdown-item>
                    </b-dropdown-group>                    
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group id="nalozi2" header="Stampa praznog naloga"  v-if="vm[2] == '1'">
                        <b-dropdown-item href="https://www.soca.rs/download/ELXRegulusBlankoNalog.pdf">
                            <b-link href="https://www.soca.rs/download/ELXRegulusBlankoNalog.pdf" target="blank">
                                Electrolux, AEG, Zanussi                            
                            </b-link>
                        </b-dropdown-item>
                        <b-dropdown-item href="https://www.soca.rs/download/ServisniNalogSebWeb.pdf">
                            <b-link href="https://www.soca.rs/download/ServisniNalogSebWeb.pdf" target="blank">
                                Tefal, Krups, Rowenta
                            </b-link>    
                        </b-dropdown-item>
                        <b-dropdown-item href="https://www.soca.rs/download/SocaWebServnalogGEMMA.pdf">
                            <b-link href="https://www.soca.rs/download/SocaWebServnalogGEMMA.pdf" target="blank">
                                Opsti
                            </b-link>                            
                        </b-dropdown-item>
                        <b-dropdown-item href="https://www.soca.rs/download/SocaWebServnalogOPSTI.pdf">
                            <b-link href="https://www.soca.rs/download/SocaWebServnalogOPSTI.pdf" target="blank">
                                Van garancije
                            </b-link>                            
                        </b-dropdown-item>
                    </b-dropdown-group> 
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#"  v-if="vm[16] == '1'">
                        <router-link v-bind:to="{path: '/radninalog/all',
                            query: { }}">
                            Soca nalozi
                        </router-link>                        
                    </b-dropdown-item>
                    <b-dropdown-item href="#"  v-if="vm[3] == '1'">
                        <router-link v-bind:to="{path: '/radninalog/lista',
                            query: { }}">
                            Lista naloga u radu
                        </router-link>                        
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[4] == '1'">
                        <router-link v-bind:to="{path: '/radninalog/lista-parametri',
                            query: { user : true }}">
                            Pregled naloga po parametrima
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[5] == '1'">
                        <router-link v-bind:to="{path: '/radninalog/obracuni-naloga',
                            query: { per : true}}">
                            Obracuni naloga
                        </router-link>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--DELOVI-->
            <b-navbar-nav v-if="vm[7] == '1' || vm[8] == '1' || vm[9] == '1' || vm[10] == '1' || vm[11] == '1' ">  
                <b-nav-item-dropdown text="Delovi">                    
                    <b-dropdown-item href="#" v-if="vm[6] == '1'">
                        <router-link v-bind:to="{path: '/delovi/korpa',
                            query: { }}">
                            Sadrzaj korpe
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[7] == '1' || vm[8] == '1' || vm[9] == '1' ">
                        <router-link v-bind:to="{path: '/delovi/porudzbine',
                            query: { group: 1, type : 0 }}">
                            Pregled porudzbina
                        </router-link>
                    </b-dropdown-item>        
                    <b-dropdown-item href="#" v-if="vm[10] == '1'">
                        <router-link v-bind:to="{path: '/delovi/magacin',
                            query: { model:0 }}">
                            Pregled magacina
                        </router-link>
                    </b-dropdown-item> 
                    <b-dropdown-item href="#" v-if="vm[11] == '1'">
                        <router-link v-bind:to="{path: '/delovi/povratnice',
                            query: { group: 1, type : 0 }}">
                            Povratnice delova
                        </router-link>
                    </b-dropdown-item>           
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--ELX-->
            <b-navbar-nav v-if="groups.includes('1')">  
                <b-nav-item-dropdown text="Electrolux">                    
                    <b-dropdown-item href="#" v-for="(entry, index) in elxMenu" :key="index">
                        <router-link v-bind:to="{path: '/pdf',
                            query: { id:entry.id }}">
                            {{pdfNaziv(entry.naziv)}}
                        </router-link>                        
                    </b-dropdown-item>                               
                </b-nav-item-dropdown>
            </b-navbar-nav> 
            <!--SEB-->
            <b-navbar-nav  v-if="groups.includes('2')">  
                <b-nav-item-dropdown text="SEB">                    
                    <b-dropdown-item href="#" v-for="(entry, index) in sebMenu" :key="index">
                        <router-link v-bind:to="{path: '/pdf',
                            query: { id:entry.id}}">
                            {{pdfNaziv(entry.naziv)}}
                        </router-link>                        
                    </b-dropdown-item> 
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--FRANKE-->
            <b-navbar-nav  v-if="groups.includes('3')">  
                <b-nav-item-dropdown text="Franke/Liebherr">                    
                    <b-dropdown-item href="#" v-for="(entry, index) in frankeMenu" :key="index">
                        <router-link v-bind:to="{path: '/pdf',
                            query: { id:entry.id }}">
                            {{pdfNaziv(entry.naziv)}}
                        </router-link>                        
                    </b-dropdown-item>             
                </b-nav-item-dropdown>
            </b-navbar-nav>             
            <!--ADMIN-->
            <b-navbar-nav v-if="alid == '44c2d9d87a62acfee636b95df766ce26' && (vm[12] == '1' || vm[13] == '1' || vm[14] == '1' || vm[15] == '1' || vm[17] == '1' || vm[23] == '1')">  
                <b-nav-item-dropdown text="Admin">    
                    <b-dropdown-item href="#" v-if="vm[12] == '1'">                
                        <router-link v-bind:to="{path: '/radninalog/svi',
                            query: { }}">
                            Svi radni nalozi
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[13] == '1'">                
                        <router-link v-bind:to="{path: '/radninalog/zakljuceni',
                            query: { }}">
                            Radni nalozi - zakljuceni
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[13] == '1'">                
                        <router-link v-bind:to="{path: '/radninalog/verifikovani',
                            query: { }}">
                            Radni nalozi - verifikovani
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[13] == '1'">                
                        <router-link v-bind:to="{path: '/radninalog/obracunati',
                            query: { }}">
                            Radni nalozi - obracunati
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#" v-if="vm[14] == '1'">                
                        <router-link v-bind:to="{path: '/radninalog/online',
                            query: { }}">
                            Otvoreni nalozi online
                        </router-link>     
                    </b-dropdown-item>  
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" v-if="vm[17] == '1'">                
                        <router-link v-bind:to="{path: '/porudzbine/pregled',
                            query: { }}">
                            Pregled svih porudzbina
                        </router-link>     
                    </b-dropdown-item> 
                    <b-dropdown-item href="#" v-if="vm[17] == '1'">                
                        <router-link v-bind:to="{path: '/delovi/poruci-za-partnera',
                            query: { }}">
                            Poruci deo za Partnera
                        </router-link>     
                    </b-dropdown-item> 
                    <b-dropdown-item href="#" v-if="vm[17] == '1'">                
                        <router-link v-bind:to="{path: '/delovi/korpe',
                            query: { }}">
                            Stanje korpi
                        </router-link>     
                    </b-dropdown-item> 
                    <b-dropdown-item href="#" v-if="vm[17] == '1'">                
                        <router-link v-bind:to="{path: '/delovi/magacini',
                            query: { model : 1 }}">
                            Stanje magacina
                        </router-link>     
                    </b-dropdown-item> 
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" v-if="vm[23] == '1'">                
                        <router-link v-bind:to="{path: '/konfiguracija/upload-cenovnik',
                            query: { }}">
                            Obnovi cenovnik
                        </router-link>     
                    </b-dropdown-item>                           
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--CONFIG--> 
            <b-navbar-nav v-if="alid == '44c2d9d87a62acfee636b95df766ce26'">  
                <b-nav-item-dropdown text="Konfiguracija">                    
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/opcije',
                            query: { }}">
                            Opcije
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/aparati',
                            query: { }}">
                            Aparati
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/naloga',
                            query: { }}">
                            Naloga
                        </router-link>     
                    </b-dropdown-item>
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/meni',
                            query: { }}">
                            Meni
                        </router-link>     
                    </b-dropdown-item> 
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/pristupa',
                            query: { }}">
                            Pristupa
                        </router-link>     
                    </b-dropdown-item>  
                    <b-dropdown-item href="#">                
                        <router-link v-bind:to="{path: '/konfiguracija/korisnici',
                            query: { }}">
                            Korisnici
                        </router-link>     
                    </b-dropdown-item>                                                  
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--SERVIS--> 
            <b-navbar-nav v-if="alid == '1b63a771bd7f09244c493450df0c8a72'">  
                <b-nav-item-dropdown text="Servis">                    
                                                  
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav>  
                <b-nav-item href="https://www.soca.rs/pdf/UputstvoDataSocaServisi.pdf" target="blank">Uputstvo</b-nav-item>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <em>User</em>
                    </template>
                    <b-dropdown-item href="#">Profile</b-dropdown-item>
                    <b-dropdown-item href="#" @click="logOut()">Sign Out</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>        
    </div>
</template>
<script>
import WorkingOrderModule from '../store/modules/workingorderdata';
import { mapFields } from 'vuex-map-fields';
export default {
    /**
     * group  1 electrolux 2 tefal 4 opsti 5 van garancije
     * type 0 novi 1 edit
     */
    name:'navbar',

    computed:{
        ...mapFields(['vm', 'alid', 'groups']),
        sebMenu:function(){                       
            return this.$store.getters.getMenu(2);
        },
        elxMenu:function(){                        
            return this.$store.getters.getMenu(1);
        },
        frankeMenu:function(){                        
            return this.$store.getters.getMenu(3);
        },
       
    },
    methods:{
        pdfNaziv:function(pdf){
            return pdf.slice(0, -4);
            //return this.$store.getters.getPdf(id).name;
        },
        refresh:function(){
            this.$store.unregisterModule('radniNalog', WorkingOrderModule);
            this.$store.registerModule('radniNalog', WorkingOrderModule);
        },
        logOut:function(){
            this.$store.commit('updateField', {
                        path: 'uid',
                        value: ''
                    });
            this.$store.commit('updateField', {
                        path: 'alid',
                        value: ''
                    });           
        }
    },
    mounted(){   
             
        this.$http.get('/menu_pdfs/svi').then((response) => { 
                         
            if(response.data.success == true){					
                this.$store.commit('updateField', {
                        path: 'pdfMenu',
                        value: response.data.data
                    });
            }
        }).catch((error) => {
            console.log(error)
        })  
        
    }
    
}
</script>
<style>
    .s-navbar{
        -webkit-box-shadow: 0px -6px 11px 4px #ABABAB; 
        box-shadow: 0px 0px 11px 4px #ABABAB;
        font-weight: bold;
        color:black !important;
        background-color: #a40202;
    }
    .navbar-dark .navbar-nav .nav-link{
      color:white!important
    }
</style>