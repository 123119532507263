<template>
    <basket :type="'user'" />
</template>

<script>
import Basket from './Basket.vue';

export default {
    components:{
        Basket
    }
}
</script>