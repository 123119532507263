import axios from "axios";

export default axios.create({
  baseURL: "https://api.soca.rs/api",
  //baseURL: "http://localhost:8000/api",
  //baseURL: "http://socaapi.loc/api",
  headers: {
    "Content-type": "application/json"
  }
});
