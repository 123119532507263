<template>
    <div>
        <b-container>
            <working-order-table :nalozi="nalozi" @refreshList="refreshList" @showMessage="showMessage"></working-order-table>
        </b-container>
    </div>
</template>

<script>
import WorkingOrderTable from './WorkingOrderTable.vue'
import helpers from '../utils/helpers'
export default {
    components:{
        WorkingOrderTable
    },
    data(){
        return{
            nalozi:[],
            loading:false,
            filter:{
                status:[1]
            }
        }
    },
    methods:{
        getWorkingOrders:function(){
            this.$http.get('/radninalog/all?' + helpers.objectToParams({status : this.filter.status})).then((response) => {                
				if(response.data.success == true){					
					this.nalozi = response.data.data;
					this.loading = false;
				}
			}).catch((error) => {
				console.log(error)
			})
        },
    },
    mounted(){
        this.getWorkingOrders();
    }
}
</script>