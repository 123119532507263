<template>
  <div>
    <b-table striped hover :items="items" :fields="fields" class="text-center">
      <template #cell(naziv)="row">
        <b-col class="text-sm-left">
          <a href="javascript:void(0)" style="margin-right:10px;" @click="getExcel(row.item.id)"><i class="fas fa-file-excel"></i></a>
          <router-link v-bind:to="{path: '/radninalog/obracunati-detalji', query: { obracun: row.item.id, servis:servis , per : per}}">
            {{row.item.obracun}}
          </router-link>
          
        </b-col>          
      </template>
      <template #cell(trosak)="row">
        <b-col class="text-sm-right">
          {{formatNumber(row.item.troskovi)}}
        </b-col>          
      </template>
      <template #cell(deo)="row">
        <b-col class="text-sm-right">
          {{formatNumber(row.item.delovi)}}
        </b-col>          
      </template>
      <template #cell(usluga)="row">
        <b-col class="text-sm-right">
          {{formatNumber(row.item.usluga)}}
        </b-col>          
      </template>
      <template #cell(total)="row">
        <b-col class="text-sm-right">
          {{sumRow(row)}}
        </b-col>          
      </template>
    </b-table>    
  </div>
</template>

<script>
    //Obracunat 	Troskovi 	Delovi 	Usluga 	Ukupno 	
  export default {
    name : 'calculated-working-order-table',
    props:['items', 'servis', 'per'],
    data() {
      return {
            nav:{},
            fields: [
            {
                key: 'naziv',
                label: 'Obracunat',
                sortable: true
            },
            {
                key: 'trosak',
                label: 'Troskovi',
                sortable: true
            },
            {
                key: 'deo',                
                label: 'Delovi',
                sortable: true
            },
            {
                key: 'usluga',
                label: 'Usluga',
                sortable: true
            },
            {
                key: 'total',
                label: 'Ukupno',
                sortable: false
            },
        ],
        
      }
    },
    methods:{
      
      sumRow:function(row){        
        let troskovi = (row.item.troskovi != null) ? row.item.troskovi : 0;
        let delovi = (row.item.delovi != null) ? row.item.delovi : 0;
        let usluga = (row.item.usluga != null) ? row.item.usluga : 0;
        
        let total = troskovi + delovi + usluga;
        return (Math.round(total * 100) / 100).toFixed(2);
      },
      formatNumber:function(number){
        return (Math.round(number * 100) / 100).toFixed(2);
      },
      getExcel:function(id){
        this.$http.get('/radninalog/excel-obracun?id=' + id + '&servis=' + this.servis + '&per=' + this.per, 
          { responseType: 'arraybuffer', headers: {'Accept': 'application/xls'}})
          .then((response) => {                   
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              console.log(fileURL)
              var fileLink = document.createElement('a');
  
              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'obracunati.xlsx');
              document.body.appendChild(fileLink);
   
     fileLink.click();
             
            
        }).catch((error) => {
            console.log(error)
        })
      }
    }
  }
</script>