<template>
    <div>
        <b-modal id="addPart" title="Dodaj deo u nalog" size="xl">
        <add-part @partAdded="partAdded"></add-part>
        <parts-list :items="parts" @deletePart="deletePart" :type="'parts'"></parts-list>
        <template #modal-footer="{}">      
            <b-button size="sm"  @click="closeModal()">
                Otkazi
            </b-button>
            <b-button size="sm" variant="primary" @click="saveParts()">
                Snimi
            </b-button>
        </template>
       
        </b-modal>    
    </div>
</template>
<script>
import AddPart from '../delovi/AddPart.vue';
import PartsList from '../delovi/PartsList.vue';

export default {
  components: { AddPart, PartsList },
    props :['row'],
    name:'add-part-modal',
    data(){
        return{
            parts:[]
        }
    },
    methods:{
        closeModal:function(){
            this.parts = [];
            this.$bvModal.hide('addPart');
            console.log('cancel')
            
        },
        saveParts:function(){
            this.$http.post('/korpa',{ data: this.parts, row : this.row }).then((response) => {                
            if(response.data.success == true){
              //this.parts = [];
              this.$bvModal.hide('addPart');            
              this.$emit('showMessage', {message:'Artikli dodati u korpu', color:'success'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Artikli nisu dodati u korpu', color:'warning'})
          }); 
            
            
        },
        partAdded:function(part){
            this.parts.push(JSON.parse(JSON.stringify(part)))
            console.log('part added', part)
        },
        deletePart:function(index){
            this.parts.splice(index,1);
        }
    },
    mounted(){
        this.parts = [];
    }
}
</script>