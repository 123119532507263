<template>
  <b-container>
    
    <table class="table">
      <thead>
        <tr>
          <th v-for="(col, index) in fields" :key="index">{{col.label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(col, index) in items" :key="index">
          <td>{{col.meni}}</td>
          <td>{{col.naziv}}</td>
          <td>{{col.link}}</td>
          <td><input type="checkbox" v-model="col.soca_serviser" @click="saveSettings(index, 'soca_serviser')"></td>
          <td><input type="checkbox" v-model="col.soca_prijem" @click="saveSettings(index, 'soca_prijem')"></td>
          <td><input type="checkbox" v-model="col.soca_admin" @click="saveSettings(index, 'soca_admin')"></td>
          <td><input type="checkbox" v-model="col.podservis" @click="saveSettings(index, 'podservis')"></td>
          <td><input type="checkbox" v-model="col.podservis_admin" @click="saveSettings(index, 'podservis_admin')"></td>
          <td><input type="checkbox" v-model="col.prodavac" @click="saveSettings(index, 'prodavac')"></td>
          <td><input type="checkbox" v-model="col.prodavac_admin" @click="saveSettings(index, 'prodavac_admin')"></td>
          <td><input type="checkbox" v-model="col.kancelarija" @click="saveSettings(index, 'kancelarija')"></td>
        </tr>
      </tbody>
    </table>
    

  </b-container>
</template>

<script>
  export default {
    name : 'menu-config',
    data() {
      return {          
          row : {},
            fields: [
            {
                key: 'meni',
                label: 'Meni'
            },
            {
                key: 'naziv',
                label: 'Naziv' 
            },
            {
                key: 'link',
                label: 'Link' 
            },
            {
                key: 'soca_serviser',                
                label: 'Soca serviser' 
            },
            {
                key: 'soca_prijem',
                label: 'Soca prijem' 
            },
            {
                key: 'soca_admin',
                label: 'Soca admin',                
            },
            {
                key: 'podservis',
                label: 'Podservis' 
            },
            {
                key: 'podservis_admin',
                label: 'Podservis admin' 
            },           
            {
                key: 'prodavac',
                label: 'Prodavac' 
            },
            {
                key: 'prodavac_admin',
                label: 'Prodavac admin' 
            },
            {
                key: 'kancelarija',
                label: 'Kancelarija' 
            },
            ],
            items:[]
            /*
            items: [
                {meni:'meni', naziv: 'naziv',link:'link',soca_serviser: 1,soca_prijem: 1,	soca_admin: 0, podservis:1,podservis_admin: 0, prodavac:0, prodavac_admin:1, kancelarija:0},            
            ]
            */
      }
    },    
    methods:{
      getMenu:function(){
        this.$http.get('/meni').then((response) => {      
          console.log(response)          
          if(response.data.success == true){
            this.items = response.data.data;
          }
        }).catch((error) => {
          console.log(error)
        })
      },      
      saveSettings:function(index, field){
        this.items[index][field] = !this.items[index][field];
        this.$http.patch('/meni', this.items[index]).then((response) => {                
            if(response.data.success == true){
              
              
              this.$emit('showMessage', {message:'Podesavanja snimljena', color:'success'})
            }
          }).catch((error) => {
              console.log(error);
              this.$emit('showMessage', {message:'Podesavanja nisu snimljena', color:'warning'})
          }); 
      }
    },
    created(){
      this.getMenu();
    }
  }
</script>
<style scoped>
    .settings{
      font-size: 25px;
    }
    .link{
      color: blue;
    }
</style>