<template>
    <div class="setter-menu">
        <b-row>
            <b-col cols="6" class="mb-6">                
            </b-col>
            <b-col cols="2" class="mb-2">
                PORUČIVANJE ZA GARANCIJU 
            </b-col>
            <b-col cols="2" class="mb-2">
                PORUČIVANJE VAN GARANCIJE
            </b-col>            
        </b-row> 
        <b-row>
            <b-col cols="4" class="mb-2">                
                <b-form-input v-model="inputValue" placeholder="unos šifra SOĆA, šifra dobavljača ili naziv" v-on:input="search"></b-form-input>     
                <div v-if="inputValue != '' && !itemFound" class="dropdown-list">
                    <div v-for="(item, index) in items" :key="index" class="dropdown-item" v-on:click="chosen(item)">        
                        {{ item.text }}
                    </div>
                </div>
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-spinbutton id="demo-sb" v-model="deo.kolicina" min="1" max="20"></b-form-spinbutton>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-select v-model="deo.radninalog" :options="nalozi"></b-form-select>                
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-form-input v-model="deo.napomena" placeholder="napomena"></b-form-input>     
            </b-col>
            <b-col cols="2" class="mb-2">
                <b-button variant="primary" v-on:click="addToList()">
                    Stavi u korpu
                </b-button>
            </b-col>
        </b-row> 
    </div>
</template>

<script>

  export default {
    props:{
        nalozi : {
            default: () => []
        },
        id :{ 
            default : null
        }
    },
    name : 'add-part-basket',
    data() {
      return {
        deo:{
            sifradela:'',
            naziv:'',
            kolicina :1,
            cena : '',
            napomena : '',
            radninalog :'',
          },
        errors:[],
        reset:false,                
        inputValue: '',
        itemFound : false,
        items :[]         
      }
    },
    methods:{
        addToList:function(){   
            if(this.canAdd() === true){ 
                if(this.deo.sifradela == ''){
                    this.deo.sifradela = this.inputValue;
                }  
                if(this.deo.naziv == ''){
                    this.deo.naziv = this.deo.sifradela;      
                }
                this.$emit('partAdded', this.deo);
                setTimeout(() => {
                    this.deleteValues();
                }, 300);
            }
            else{
                for(let error of this.errors){
                    this.$emit('showMessage', {message: error, color:'warning'})
                }
                
            }                        
        },
        deleteValues:function(){
            this.deo.iddela     = '';
            this.deo.sifradela  = '';
            this.deo.kolicina   = 1;
            this.deo.cena       = '';
            this.deo.napomena   = '';
            this.deo.naziv      = '';
            if(this.$route.query.id == null){
                this.deo.radninalog = '';
            }
            this.inputValue     = ''
        },
        canAdd:function(){
            console.log(this.deo.sifradela)
            if(this.inputValue != '' && this.deo.kolicina > 0){
                return true;
            }
            else{
                this.errors = [];
                if(this.inputValue == ''){
                    this.errors.push('Morate uneti sifru dela')
                }
                if(this.deo.kol == ''){
                    this.errors.push('Morate uneti kolicinu')
                }
                return false;
            }
        },
        search:function(){  
            this.itemFound = false;      
            this.$http.get('/delovi/nadjideo?term=' + this.inputValue).then((response) => {                
                if(response.data.success == true){
                    this.items = response.data.data;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        chosen:function(item){
            this.deo.sifradela = item.value;
            this.deo.naziv = item.text;
            this.deo.id = item.id;
            this.inputValue = item.text;
            this.itemFound = true;
        }        
    },
    mounted(){        
        if(this.$route.query.id != null){
            this.deo.radninalog = this.$route.query.id;
        }
    }
  }
</script>
<style scoped>
    .dropdown{
        position: relative;
        }
    .dropdown-list{
        position: absolute;
        background-color: white;
        border: 1px solid #e8e8e8;
        border-radius: 3px;
        z-index: 1000;
    }
</style>