<template>
	
	<b-row>                
		<b-col cols="4">
			<label class="btn btn-default">
				<input type="file"  multiple @change="selectFile" />
			</label>

			<div v-if="progressInfos">
				<div class="mb-2"
					v-for="(progressInfo, index) in progressInfos"
					:key="index"
				>
					<span>{{progressInfo.fileName}}</span>
					<div class="progress">
						<div class="progress-bar progress-bar-info"
							role="progressbar"
							:aria-valuenow="progressInfo.percentage"
							aria-valuemin="0"
							aria-valuemax="100"
							:style="{ width: progressInfo.percentage + '%' }"
						>
							{{progressInfo.percentage}}%
						</div>
					</div>
				</div>
			</div>		

			<button class="btn btn-success btn-block"
				:disabled="!selectedFiles"
				@click="uploadFiles"
				>
				Upload
			</button>
			<div v-if="utilPdfs">
				<b-form-select v-model="group" :options="groups" style="margin-top:10px;"></b-form-select>
			</div>
			<div v-if="message" class="alert alert-light" role="alert">
				<ul>
					<li v-for="(ms, i) in message.split('\n')" :key="i">
						{{ ms }}
					</li>
				</ul>
			</div>
		</b-col>
		<b-col>
			<div class="files-container">
				<div v-for="(file, index) in fileInfos" :key="index">
					<div class="image-wrapper">
						<div class="image-preview" @click="showImage(file)">
							<img :src="path(file)" alt="" width="100px" height="100px">
						</div>
						<div class="remove-wrapper">                    
							<b-button size="sm" variant="danger" v-on:click="deleteFile(file)">obrisi</b-button>                    
						</div>    
					</div>
				</div>				                        
			</div> 
		</b-col>
		<show-image-modal :filepath="filepath"/>
	</b-row>
</template>

<script>
import UploadService from "../services/UploadFilesService";
import showImageModal from '../modals/showImageModal.vue';

export default {
	name: "upload-files",
	components:{
		showImageModal
	},
	props:['orderId', 'utilPdfs'],
	data() {
		return {
			selectedFiles: undefined,
			progressInfos: [],
			message: "",
			fileInfos: [],
			group : '',
			groups : [
				{value : 1, text: 'Electrolux'},
				{value : 2, text: 'Seb'},
				{value : 3, text: 'Franke'},
				{value : 4, text: 'Ostalo'}
			],
			showModal : false,
			filepath:''		
		};
	},
	methods: {
		selectFile(event) {
			this.progressInfos = [];
			this.selectedFiles = event.target.files;
		},
		uploadFiles() {
			this.message = "";
			for (let i = 0; i < this.selectedFiles.length; i++) {
				this.upload(i, this.selectedFiles[i]);
			}
		},
		upload(idx, file) {
			console.log('upload',this.orderId)
			this.progressInfos[idx] = { percentage: 0, fileName: file.name };
			UploadService.upload(file, {id : this.orderId, group: this.group}, (event) => {
				this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
			})
			.then((response) => {
				console.log(response.data.name)
				let prevMessage = this.message ? this.message + "\n" : "";
				this.message = prevMessage + response.data.message;				
				return UploadService.getFiles(this.orderId);
			})
			.then((files) => { 
				this.fileInfos = files.data;				
			})
			.catch(() => {
				this.progressInfos[idx].percentage = 0;
				this.message = "Could not upload the file:" + file.name;
			});
		},
		path:function(file){			
			let url = '';
			if(file.naziv.search('.pdf') === -1){
				url =  'https://api.soca.rs/' + file.path + '/' + file.naziv;
				//url =  'http://localhost:8000/' + file.path + '/' + file.naziv;
			}
			else{
				url =  'https://api.soca.rs/images/pdfsmall.png';
				//url =  'http://localhost:8000/images/pdfsmall.png';
			}
			return url;
		},
		deleteFile:function(file){
			console.log(file)
			this.$http.delete('/files',{data:{ id: file.id }}).then((response) => {                
            if(response.data.success == true){
				UploadService.getFiles(this.orderId).then((response) => {
					this.fileInfos = response.data;
				});
				this.$emit('showMessage', {message:'Slika je obrisana', color:'success'})
            }
			}).catch((error) => {
				console.log(error);
				this.$emit('showMessage', {message:'Slika nije obrisana', color:'warning'})
			});
		},
		showImage:function(file){
			if(file.naziv.search('.pdf') === -1){
				this.filepath = this.path(file);
				this.$bvModal.show('showImage')
				console.log(file)
			}
		},
		closeModal:function(modal){
			console.log('modal', modal)
			this.showModal = false;
		},
	},	
	mounted() {		
		setTimeout(()=>{
			console.log('orderoId', this.orderId)
			if(this.orderId != ''){
				UploadService.getFiles(this.orderId).then((response) => {
					this.fileInfos = response.data;
				});
			}
		}, 300);
		
  }
};
</script>

<style scoped>
    .image-preview{        
        width: 100px;
        height : 100px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.8); 
        -webkit-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.33);        
    }

    .image-wrapper{
        float:left;
        padding:5px;
    }

    .remove-wrapper{
        margin-top:10px;
        padding-left: 23px;
    }
    .delete-button{
        margin-left: 25px;
    }
</style>
